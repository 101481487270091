/*
*   File : fetch-aadhaar.js
*   Author : https://evoqins.com
*   Description : Screen to fetch Aadhaar details 
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// import components
import { PrimaryButton } from "../../../Components/Buttons";
import { Icon } from "../../../Components/Icon";
import { StepProgress } from "../../../Components/Other";
import { DigiLockerStatusModal } from "../../../Components/Modal";
import { KYCErrorCard } from "../../../Components/Cards";

// import services
import APIService from "../../../Services/api-service";
import { _getSignupProgress } from "../../../Helper/api";
import { useSelector } from "react-redux";


const FetchAadhaar = (props) => {
    const PROGRESS_DATA = useSelector(state => state.Reducer.SIGNUP_PROGRESS);
    const [showSuccess, setShowSuccess] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [errors, setErrors] = useState([]);



    useEffect(() => {
        if (showSuccess === true) {
            const modal = new Modal(document.getElementById("digi-locker"));
            modal.show();
        }
    }, [showSuccess]);

    useEffect(() => {


        if (PROGRESS_DATA.step_detail[3].error === true) {
            setErrors(PROGRESS_DATA.step_detail[3].message);
        }

    }, [PROGRESS_DATA]);

    // open success modal 
    function _showAadhaarSuccess(status) {
        setShowSuccess(status);
        if (status === false) {
            _getSignupProgress().then((response) => {
                props.handleNavigate()
            });
        }
    }

    // API - get digilocker 
    const _getDigiLocker = () => {
        setApiLoader(true);
        const url = "/kyc/get-aadhaar";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.redirect_url != null) {

                    const fetch_window = window.open(response.data.redirect_url, "", "width=500,height=500,left=400,top=100"); //open window 

                    if (fetch_window === null || typeof fetch_window === 'undefined') {
                        toast.dismiss();
                        toast.error("Please enable pop-up permission");
                        return;
                    };

                    let timer = setInterval(function (event) {
                        if (fetch_window.closed) {
                            clearInterval(timer);
                            _getFetchAadhaarStatus();
                        }
                    }, 1000);


                } else {
                    if (response.data.status === 'SUCCESSFUL') {
                        _showAadhaarSuccess(true);
                    }
                }
            } else {
                toast.dismiss();
                toast.error(response.message);
            };
            setApiLoader(false);
        })
    }

    // API - Getting fetch Aadhaar status
    const _getFetchAadhaarStatus = () => {
        let url = '/kyc/get-aadhaar';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.status === 'SUCCESSFUL') {
                    _showAadhaarSuccess(true);
                } else if (response.data.status === 'PENDING') {
                    toast.error("Complete your Aadhaar fetching process", {
                        type: "error"
                    })
                }
            } else {
                toast.dismiss();
                toast.error(response.message);
            };
            setApiLoader(false);
        });
    };

    return (
        <Fragment>
            <Icon icon="Arrow-Right"
                size={32}
                className="margin-12px-tb rotate-left cursor-pointer"
                onClick={props.navigateBack} />
            <div className="row pt-md-3 pt-0">
                <div className="col-md-7">
                    <StepProgress type={2}
                        step={props.completed}
                        stepNumber={4}
                        maxStep={8} />
                    <h6 className="color-eerie-black e-font-20 e-line-height-32 e-montserrat-semi-bold mb-2 padding-32px-top">
                        Fetch your identity using DigiLocker
                    </h6>
                    <p className="color-gunmental e-font-16 e-line-height-32 e-montserrat-regular mb-0">
                        Securely link your address & other credentials from Aadhaar using Digilocker.
                    </p>

                    {
                        errors.length > 0 &&
                        <KYCErrorCard errors={errors} />
                    }

                    <div className="border-top-1px border-bright-gray margin-32px-top mb-4"></div>
                    <div className="col-xl-3 col-lg-5 col-md-6 d-md-block d-none">
                        <PrimaryButton label="Proceed"
                            disabled={apiLoader}
                            className="w-100 padding-12px-tb"
                            image={require("../../../Assets/Images/KYC/digi-btn.png")}
                            onPress={() => _getDigiLocker()} />
                    </div>
                </div>
                <div className="col-md-5">
                    <img src={require("../../../Assets/Images/KYC/digi-locker.png")}
                        alt="PAN"
                        draggable={false}
                        className="w-100 h-auto" />
                    <div className="col-sm-6 d-md-none d-block pt-3">
                        <PrimaryButton label="Proceed"
                            disabled={apiLoader}
                            className="w-100 padding-12px-tb"
                            image={require("../../../Assets/Images/KYC/digi-btn.png")}
                            onPress={() => _getDigiLocker()} />
                    </div>

                </div>
            </div>

            {
                showSuccess === true && <DigiLockerStatusModal title={"Aadhaar fetched successfully"}
                    type={1}
                    show_button={true}
                    content={"Congratulation your aadhaar has been successfully fetched"}
                    handleNavigate={() => _showAadhaarSuccess(false)}
                    closeModal={() => setShowSuccess(false)} />
            }

        </Fragment >
    )
}

export default FetchAadhaar;