/*
 *   File : kyc-error.js
 *   Author : https://evoqins.com
 *   Description : KYC error card component to show reasons for rejected KYC
 *   Integrations : null
 *   Version : 1
*/

// import packages
import React from "react";

// import components
import { Icon } from "../Icon";

const KYCErrorCard = React.memo((props) => {
    return (
        <div className="row pt-3">
            <div className="col-lg-6">
                <div className="border-radius-12px e-bg-misty-rose p-2 d-flex align-items-start gap-8px">
                    <Icon icon="info-red"
                        size={16} />
                    <ul className="mb-0 ps-3 color-deep-carmine-pink e-montserrat-medium e-font-12 e-line-height-18">
                        {
                            props.errors.map((item, index) => {
                                return (
                                    <li key={index} className={index > 0 ? "pt-1" : ""}>{item}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
});

KYCErrorCard.defaultProps = {
    errors: []
}
export default KYCErrorCard;