/*
 *   File : breadcrumb.js
 *   Author : https://evoqins.com
 *   Description : Breadcrumb component
 *   Version : 1.0.0
*/

// import packages
import { Fragment, memo } from "react"
import { Icon } from "../Icon"
import { useWindowSize } from "../../Helper/helper";

const BreadCrumb = (props) => {

    const { width } = useWindowSize();

    return (
        <Fragment>
            {
                props.type === 1 ?
                    <div className={`${props.className} d-flex align-items-center flex-wrap`}>
                        <span onClick={props.handleNavigate} className="color-gunmetal-gray e-font-14 e-line-height-18 e-montserrat-medium  cursor-pointer">{props.mainPage}</span>

                        <Icon icon="arrow-right"
                            size={16}
                            className="margin-2px-lr" />
                        <span className="color-eerie-black e-font-14 e-line-height-18 e-montserrat-medium " >{props.currentPage}</span>

                    </div>
                    :
                    <div className={`${props.className} d-flex align-items-center flex-wrap`}>
                        <span onClick={props.handleParentNavigate} className="color-gunmetal-gray e-font-14 e-line-height-18 e-montserrat-medium  cursor-pointer">{props.mainPage}</span>

                        <Icon icon="arrow-right"
                            size={16}
                            className="margin-2px-lr" />
                        <span className="color-eerie-black e-font-14 e-line-height-18 e-montserrat-medium  cursor-pointer" onClick={props.handleCurrentNavigate} >{props.currentPage}</span>

                        <Icon icon="arrow-right"
                            size={16}
                            className="margin-2px-lr" />
                        <span className={`color-eerie-black e-font-14 e-line-height-18 e-montserrat-medium text-truncate ${width < 576 ? "max-w-100px" : width < 768 ? "max-w-300px" : ""}`}>{props.childPage}</span>

                    </div>
            }
        </Fragment>
    )
}

BreadCrumb.defaultProps = {
    className: "",
    handleNavigate: () => { },
    handleParentNavigate: () => { },
    handleCurrentNavigate: () => { }

}

export default memo(BreadCrumb)