/*
*   File : calculator.js
*   Author : https://evoqins.com
*   Description : Calculator component
*   Version : 1.0.0
*/

import { useEffect, useState, memo, Fragment } from "react";
import { CustomTabBar } from "../Tab";
import { EditableSlider } from "../FormElements";

const TAB_DATA = [
    {
        id: 1,
        label: "SIP"
    },
    {
        id: 2,
        label: "Lumpsum"
    },
    {
        id: 3,
        label: "SWP"
    }
];

const Calculator = memo((props) => {

    const [returnTab, setReturnTab] = useState(TAB_DATA[0].id);
    const [sipAmount, setSipAmount] = useState(100);
    const [lumpsumAmount, setLumpsumAmount] = useState(5000);

    const [sipTimePeriod, setSipTimePeriod] = useState(1);
    const [lumpsumTimePeriod, setLumpsumTimePeriod] = useState(1);

    const [sipExpectedReturn, setSipExpectedReturn] = useState(10);
    const [lumpsumExpectedReturn, setLumpsumExpectedReturn] = useState(10);

    const [swpAmount, setSwpAmount] = useState(500000);
    const [swpTimePeriod, setSwpTimePeriod] = useState(1);
    const [withdrawalMonthly, setWithdrawalMonthly] = useState(10000);
    const [swpReturns, setSwpReturns] = useState(10);


    // const [totalWithdrawnAmount, setWithdrawnAmount] = useState(0);
    const [remainingBalance, setRemainingBalance] = useState(0);

    const [totalReturns, setTotalReturns] = useState("")
    //eslint-disable-next-line
    const [wealthGained, setWealthGained] = useState("")
    const [graphPercent, setGraphPercent] = useState("");
    const [totalInvested, setTotalInvested] = useState("");

    // initial sip
    useEffect(() => {

        if (props.min_investment) {
            // setSipAmount(props.min_investment.min_sip);
            // setLumpsumAmount(props.min_investment.min_lumpsum);
            const time_period = (Number(props.period)) / 12;
            setSipTimePeriod(time_period);
            setLumpsumTimePeriod(time_period);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.returns_percentage) {
            setSipExpectedReturn(props.returns_percentage);
            setLumpsumExpectedReturn(props.returns_percentage);
            setSwpReturns(props.returns_percentage);
        }
    }, [props.returns_percentage])



    useEffect(() => {
        if (returnTab === 1) {
            const _sipCalculator = () => {
                let future_value = 0;
                let monthly_rate = (sipExpectedReturn / 12) / 100;
                let total_months = sipTimePeriod * 12;
                let inflation_rate = 0;

                for (let month = 1; month <= total_months; month++) {
                    let returned_value = sipAmount * Math.pow(1 + 0 / 100, Math.floor((month - 1) / 12));
                    future_value += returned_value;
                    future_value *= (1 + monthly_rate);
                    future_value /= (1 + inflation_rate / 100);
                }

                let total_invested = 0; // total invested amount
                let step_sip = 0; // step sip percentage
                let sips = parseInt(sipAmount)
                let yearly_invested_sip = 0;
                for (let year = 1; year <= sipTimePeriod; year++) {
                    if (year !== 1) {
                        sips = sips + (sips * (step_sip / 100)); // updated sip value for selected year
                    }
                    yearly_invested_sip = sips * 12;
                    total_invested += yearly_invested_sip;
                }
                const gained_value = future_value - total_invested;
                const total_returns_percentage = (total_invested / future_value) * 100
                setTotalReturns(parseFloat(future_value.toFixed(2)));
                setWealthGained(gained_value);
                setTotalInvested(total_invested);
                setGraphPercent(total_returns_percentage)
            }

            _sipCalculator();
        }
    }, [sipAmount, sipExpectedReturn, sipTimePeriod, returnTab]);

    useEffect(() => {
        if (returnTab === 2) {
            const lumpsumCalculate = () => {
                const monthly_rate = lumpsumExpectedReturn / 100;
                let final_value = lumpsumAmount * (Math.pow(1 + monthly_rate, lumpsumTimePeriod));
                final_value = parseInt(final_value.toFixed(0));
                setLumpsumAmount(lumpsumAmount);
                setTotalReturns(parseFloat(final_value.toFixed(2)));
                const gained_value = final_value - lumpsumAmount;
                const total_returns_percentage = (lumpsumAmount / final_value) * 100
                setWealthGained(gained_value);
                setGraphPercent(total_returns_percentage);
            }
            lumpsumCalculate();
        }
    }, [lumpsumAmount, lumpsumExpectedReturn, lumpsumTimePeriod, returnTab]);

    useEffect(() => {
        if (returnTab === 3) {
            const _swpCalculator = () => {

                let initial_value = 0;
                let total_investment_value = (swpAmount);;
                let monthly_withdrawal_value = (withdrawalMonthly);;
                let expected_returns = swpReturns
                let years = swpTimePeriod * 12;

                let monthly_interest_rate = (expected_returns / 12) / 100;
                let total_interest1 = 0;
                // let interest = 0;
                let interest1 = 0;
                let count = 0;
                let balance = total_investment_value;
                // let tenure_period = swpTimePeriod * 12;


                let totalInvested = [];

                let monthlyGrowthRate = Math.pow(1 + swpReturns / 100, 1 / 12) - 1;
                let month = 0;

                while (month < years) {
                    balance += balance * monthlyGrowthRate;
                    balance -= monthly_withdrawal_value;
                    month++;
                }

                setRemainingBalance(balance)

                for (let i = 1; i <= years; i++) {
                    count = count + 1;

                    interest1 = balance * monthly_interest_rate;
                    total_interest1 = total_interest1 + interest1;
                    balance = balance + interest1;
                    totalInvested.push(Math.round(total_investment_value));

                    // if (interest > initial_value) {
                    //     setWithdrawnAmount(0);
                    // }
                    total_investment_value = initial_value;

                    // setWithdrawnAmount(monthly_withdrawal_value * tenure_period);

                }

            }
            _swpCalculator();
        }
    }, [swpReturns, swpAmount, swpTimePeriod, withdrawalMonthly, returnTab]);

    // handle Returns
    function _handleTabChange(value) {
        setReturnTab(value);
    }

    const _handleSlider = (value, type) => {
        switch (type) {
            case 1:
                if (returnTab === 1) {
                    setSipAmount(value);
                } else if (returnTab === 2) {
                    setLumpsumAmount(value);
                } else {
                    setSwpAmount(value);
                }
                break;
            case 2:
                if (props.getReturns) {
                    props.getReturns(value);
                }
                if (returnTab === 1) {
                    setSipTimePeriod(value);
                } else if (returnTab === 2) {
                    setLumpsumTimePeriod(value);
                } else {
                    setSwpTimePeriod(value);
                }
                break;
            case 3:
                if (returnTab === 1) {
                    setSipTimePeriod(value);
                } else if (returnTab === 2) {
                    setLumpsumTimePeriod(value);
                } else {
                    setWithdrawalMonthly(value);
                }
                break;
            case 4:
                setWithdrawalMonthly(value);
                break;

            default:
                break;
        }
    }

    return (
        <div className="px-sm-4 px-3">
            <CustomTabBar data={TAB_DATA}
                selectedTab={returnTab}
                onSelectTab={(value) => _handleTabChange(value)} />


            {returnTab === 3 ?
                <Fragment>
                    <EditableSlider label="Total investment"
                        edit={true}
                        inputPrefix="₹"
                        value={swpAmount}
                        min={10000}
                        max={5000000}
                        // suffix="₹"
                        className="mb-4"
                        handleChange={(value) => _handleSlider(value, 1)} />

                    <EditableSlider label="Monthly withdrawal"
                        edit={true}
                        value={withdrawalMonthly}
                        min={500}
                        max={50000}
                        inputPrefix="₹"
                        className="mb-4"
                        handleChange={(value) => _handleSlider(value, 4)} />



                    <EditableSlider label="Time period"
                        value={swpTimePeriod}
                        min={1}
                        max={30}
                        suffix={swpTimePeriod === 1 ? "Yr" : "Yrs"}
                        edit={true}
                        singular_suffix="yr"
                        plural_suffix="yrs"
                        className="mb-4"
                        handleChange={(value) => _handleSlider(value, 2)} />
                    <div className="d-flex justify-content-between margin-40px-top mb-sm-3 mb-2">
                        <span className="e-montserrat-medium e-font-16 e-line-height-30 color-outer-space">Invested Amount</span>

                        <span className="e-montserrat-medium e-font-18 e-line-height-30 color-outer-space">₹{totalInvested.toLocaleString('en-IN')}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-sm-3 mt-2 ">
                        <span className="e-montserrat-medium e-font-16 e-line-height-30 color-outer-space">Remaining balance</span>
                        <span className="e-montserrat-semi-bold e-font-20 e-line-height-36 color-rebecca-purple">₹{remainingBalance.toLocaleString('en-IN')}</span>
                    </div>
                </Fragment>
                :
                <Fragment>

                    <EditableSlider label={returnTab === 1 ? "Monthly investment" : "One Time Investment Amount"}
                        className="mt-4"
                        edit={true}
                        inputPrefix="₹"
                        value={returnTab === 1 ? sipAmount : lumpsumAmount}
                        min={returnTab === 1 ? 100 : 5000}
                        max={returnTab === 1 ? 1000000 : 10000000}
                        handleChange={(value) => _handleSlider(value, 1)} />
                    <EditableSlider label={returnTab === 1 ? "Time period" : "Tenure"}
                        className="mt-4"
                        value={returnTab === 1 ? sipTimePeriod : lumpsumTimePeriod}
                        min={1}
                        max={props.min_investment.returns_for_calculation.at(-1).year}
                        edit={true}
                        suffix={returnTab === 1 ? sipTimePeriod === 1 ? "Yr" : "Yrs" : lumpsumTimePeriod === 1 ? "Yr" : "Yrs"}
                        singular_suffix="Yr"
                        plural_suffix="Yrs"
                        handleChange={(value) => _handleSlider(value, 2)} />

                    {
                        props.show_returns === true &&
                        <EditableSlider label="Expected return rate (p.a)"
                            className="mt-4"
                            value={returnTab === 1 ? sipExpectedReturn : lumpsumExpectedReturn}
                            min={1}
                            max={30}
                            suffix="%"
                            singular_postfix_suffix="%"
                            plural_postfix_suffix="%"
                            handleChange={(value) => _handleSlider(value, 3)} />
                    }
                    <div className="d-flex justify-content-between margin-40px-top mb-sm-3 mb-2">
                        <span className="e-montserrat-medium e-font-16 e-line-height-30 color-outer-space">Invested Amount</span>

                        <span className="e-montserrat-medium e-font-18 e-line-height-30 color-outer-space">₹{returnTab === 1 ?
                            totalInvested.toLocaleString("en-IN") :
                            returnTab === 1 ? sipAmount.toLocaleString("en-IN") : lumpsumAmount.toLocaleString("en-IN")}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-sm-3 mt-2 ">
                        <span className="e-montserrat-medium e-font-16 e-line-height-30 color-outer-space">Total Value</span>
                        <span className="e-montserrat-semi-bold e-font-20 e-line-height-36 color-rebecca-purple">₹{totalReturns.toLocaleString('en-IN')} <span className="e-font-14 color-go-green">({graphPercent ? graphPercent.toFixed(2) : 0}%)</span></span>
                    </div>
                </Fragment>}
        </div>
    )
});

Calculator.defaultProps = {
    returns: true
}
export default Calculator;