import { put, takeEvery } from "redux-saga/effects";


function* updateLoginStatus(action) {
    try {
        yield put({
            type: 'LOGIN_STATUS', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        // console.log(error);
    }
}

function* updateRefreshToken(action) {
    try {
        yield put({
            type: 'REFRESH_TOKEN', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        // console.log(error);
    }
}

function* updateProfileStatus(action) {
    try {
        yield put({
            type: 'PROFILE_DATA', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        // console.log(error);
    }
}

function* updateSignupProgress(action) {
    try {
        yield put({
            type: 'SIGNUP_PROGRESS', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        // console.log(error);
    }
}

function* updateGeneralData(action) {
    try {
        yield put({
            type: 'GENERAL_DATA', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        // console.log(error);
    }
}

function* updateNotificationCount(action) {
    try {
        yield put({
            type: 'NOTIFICATION_COUNT', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        // console.log(error);
    }
}

export function* _updateProfileStatus() {
    yield takeEvery('PROFILE_DATA', updateProfileStatus);
}

export function* _updateLoginStatus() {
    yield takeEvery('LOGIN_STATUS', updateLoginStatus);
}

export function* _updateRefreshToken() {
    yield takeEvery('REFRESH_TOKEN', updateRefreshToken);
}

export function* _updateSignupProgress() {
    yield takeEvery('SIGNUP_PROGRESS', updateSignupProgress);
}

export function* _updateGeneralData() {
    yield takeEvery('SIGNUP_PROGRESS', updateGeneralData);
}

export function* _updateNotificationCount() {
    yield takeEvery('NOTIFICATION_COUNT', updateNotificationCount);
}