/*
 *   File : investment.js
 *   Author : https://evoqins.com
 *   Description : Investment card component
 *   Integrations : null
 *   Version : 1
*/

// import packages
import { Fragment } from "react"
import { CustomTabBar } from "../Tab"
import { HorizontalStackedBar } from "../Charts";
import { LinkButton } from "../Buttons";

import style from '../../Styles/Components/investment-card.module.scss';
import { useNavigate } from "react-router-dom";


const InvestmentCard = (props) => {

    const navigate = useNavigate();

    const FUND_ALLOCATION_DATA = props.data.funds !== undefined && props.data.funds.graph_data !== undefined && props.data.funds.graph_data.map((item) => {
        return (
            {
                name: item.asset_class,
                ...item
            }
        )
    })

    const CATEGORY_ALLOCATION_DATA = props.data.categories !== undefined && props.data.categories.graph_data !== undefined && props.data.categories.graph_data.map((item) => {
        return (
            {
                name: item.asset_class,
                ...item
            }
        )
    })

    const _handleClick = () => {
        navigate("/portfolio", {
            state: {
                investment_type: props.selected,
                show: true,
                fund_type: 1
            }
        })
    }


    return (
        <Fragment>
            <div className="position-relative h-100">
                <div className={`${style.e_investment_card} d-flex flex-column padding-12px-tb px-3 border-radius-24px h-100 w-100 ${props.is_invested === false ? 'blur-3px pointer-events-none' : ''}`}>
                    <div className="border-bottom-1px border-onyx">
                        <CustomTabBar data={props.tabs}
                            selectedTab={props.selected}
                            onSelectTab={props.onSelectTab} />
                    </div>

                    {
                        props.selected === 1 ?
                            <Fragment>
                                <div className="d-flex align-items-center justify-content-between border-bottom-1px border-onyx padding-12px-tb">
                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                        <img src={require('../../Assets/Images/Home/total-returns.png')}
                                            alt="total-returns"
                                            width={28}
                                            draggable={false} />
                                        Total returns
                                    </div>
                                    <span className={`${props.data.total_investment.returns_amount > 0 ? 'color-emerald-green' : props.data.total_investment.returns_amount < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'} e-font-16 e-line-height-24 e-montserrat-semi-bold `}>  {props.data.total_investment.returns_amount_text} <span className="e-font-14">({props.data.total_investment.returns_percentage_text})</span></span>
                                </div>

                                <div className="d-flex align-items-center justify-content-between padding-12px-top">
                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                        <img src={require('../../Assets/Images/Home/current.png')}
                                            alt="total-returns"
                                            width={28}
                                            draggable={false} />
                                        Current
                                    </div>
                                    <span className="color-eerie-black e-font-14 e-line-height-24 e-montserrat-semi-bold">{props.data.total_investment.current_amount_text}</span>
                                </div>

                                <div className="d-flex align-items-center justify-content-between  padding-12px-top">
                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                        <img src={require('../../Assets/Images/Home/invested.png')}
                                            alt="total-returns"
                                            width={28}
                                            draggable={false} />
                                        Invested
                                    </div>
                                    <span className="color-eerie-black e-font-14 e-line-height-24 e-montserrat-semi-bold">{props.data.total_investment.invested_amount_text}</span>
                                </div>

                                <div className="d-flex align-items-center justify-content-between  padding-12px-top">
                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                        <img src={require('../../Assets/Images/Home/xirr.png')}
                                            alt="total-returns"
                                            width={28}
                                            draggable={false} />
                                        XIRR
                                    </div>
                                    <span className={`${props.data.total_investment.xirr > 0 ? "color-emerald-green" : props.data.total_investment.xirr < 0 ? "color-deep-carmine-pink" : 'color-eerie-black'} e-font-14 e-line-height-24 e-montserrat-semi-bold d-flex align-items-start `}>{props.data.total_investment.xirr_text}
                                        {props.data.total_investment.xirr !== 0 &&
                                            <img width={16} alt="xirr" src={props.data.total_investment.xirr > 0 ? require("../../Assets/Images/Home/xirr-high.svg").default : require("../../Assets/Images/Home/xirr-low.png")} />
                                        }
                                    </span>
                                </div>

                                <div className="d-flex align-items-center justify-content-between  padding-12px-top pb-4 margin-9px-bottom">
                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                        <img src={require('../../Assets/Images/Home/1-day-return.png')}
                                            alt="total-returns"
                                            width={28}
                                            draggable={false} />
                                        1 day return
                                    </div>
                                    <span className={`${props.data.total_investment.one_day_returns > 0 ? "color-emerald-green" : props.data.total_investment.one_day_returns < 0 ? "color-deep-carmine-pink" : 'color-eerie-black'} e-font-14 e-line-height-24 e-montserrat-semi-bold `}>{props.data.total_investment.one_day_returns_text} <span className={`e-font-12 color-eerie-black ${props.data.total_investment.one_day_returns_percentage > 0 ? "color-emerald-green" : props.data.total_investment.one_day_returns_percentage < 0 ? "color-deep-carmine-pink" : 'color-eerie-black'}`}>({props.data.total_investment.one_day_returns_percentage_text})</span></span>
                                </div>
                            </Fragment>
                            :
                            props.selected === 2 ?
                                Object.keys(props.data.categories).length > 0 ?
                                    <Fragment>

                                        <div className="pt-2">
                                            <HorizontalStackedBar height="h-16px" allocations={CATEGORY_ALLOCATION_DATA} />
                                        </div>
                                        {/* <HorizontalStackedBar height="8px" allocations={}/> */}
                                        <div className="row">
                                            <div className="col-5">
                                                <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Category</span>
                                            </div>
                                            <div className="col">
                                                <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                            </div>
                                            <div className="col text-center">
                                                <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Allocation(%)</span>
                                            </div>
                                        </div>
                                        {
                                            props.data.categories.allocation_data !== undefined && props.data.categories.allocation_data.slice(0, 3).map((item, index) => {
                                                return (
                                                    <div className={`py-2 ${index > 0 ? 'border-top-point5px border-onyx' : ''}`}>
                                                        <div className={`row`}>
                                                            <div className="col-5">
                                                                <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.name}</span>
                                                            </div>
                                                            <div className="col">
                                                                <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.current_amount_text}</span>
                                                            </div>
                                                            <div className="col text-center">
                                                                <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.allocation}%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="mt-auto text-center">
                                            <LinkButton label="View more"
                                                className='e-font-14 e-line-height-20 e-montserrat-bold mt-auto'
                                                onPress={_handleClick} />
                                        </div>
                                    </Fragment>
                                    :
                                    <p className="color-black opacity-6 e-font-14 e-line-height-20 e-montserrat-semi-bold text-center my-auto">No Categories found</p>
                                :
                                Object.keys(props.data.funds).length > 0 ?
                                    <Fragment>
                                        <div className="pt-2">
                                            <HorizontalStackedBar height="h-16px" allocations={FUND_ALLOCATION_DATA} />
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Fund</span>
                                            </div>
                                            <div className="col">
                                                <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                            </div>
                                            <div className="col text-center">
                                                <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Allocation(%)</span>
                                            </div>
                                        </div>
                                        {
                                            props.data.funds.allocation_data !== undefined && props.data.funds.allocation_data.slice(0, 3).map((item, index) => {
                                                return (
                                                    <div className={`py-2 ${index > 0 ? 'border-top-point5px border-onyx' : ''}`}>
                                                        <div className={`row align-items-center`}>
                                                            <div className="col-5">
                                                                <div className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-semi-bold d-flex align-items-center gap-4px ">
                                                                    <span className="truncate-first-line">  {item.mf_name}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.current_amount_text}</span>
                                                            </div>
                                                            <div className="col text-center">
                                                                <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.allocation}%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="mt-auto text-center">
                                            <LinkButton label="View more"
                                                className='e-font-14 e-line-height-20 e-montserrat-bold mt-auto'
                                                onPress={_handleClick} />
                                        </div>
                                    </Fragment>
                                    :
                                    <p className="color-black opacity-6 e-font-14 e-line-height-20 e-montserrat-semi-bold text-center my-auto">No Funds found</p>
                    }
                </div>
                {props.is_invested === false && <div className={`${style.e_pending} position-absolute z-index-2 py-2 text-center e-font-14 e-line-height-24 e-montserrat-medium color-eerie-black px-4 top-0 bottom-0 start-0 end-0 m-auto py-2 w-270px h-max-content`}>
                    You haven't made any investments, so there is no portfolio summary to view.
                </div>}
            </div>
        </Fragment>
    )
}
export default InvestmentCard