/*
*   File : notifications.js
*   Author : https://evoqins.com
*   Description : Container file to list notifications in the application.
*   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// Components
import { useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header";
import { Breadcrumb, EmptyScreen, Loader } from "../../Components/Other";
import { LinkButton } from "../../Components/Buttons";
import { CustomTabBar } from "../../Components/Tab";
import { Icon } from "../../Components/Icon";

import Color from "../../Styles/color.module.scss";

// Service, helper
import { _getNotificationCount } from "../../Helper/api";
import APIService from "../../Services/api-service";


const TAB_DATA = [
    {
        label: "Transactional",
        id: 1
    },
    {
        label: "Others",
        id: 2
    }
]

const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}
const NotificationList = () => {
    const COUNT = useSelector(state => state.Reducer.NOTIFICATION_COUNT);
    const navigate = useNavigate();
    const [pageLoader, setPageLoader] = useState(true);
    const [selectedTab, setSelectedTab] = useState(TAB_DATA[0].id);
    const [notificationList, setNotificationList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(null);

    useEffect(() => {
        _getNotificationList();
        // eslint-disable-next-line
    }, [currentPage, selectedTab])

    function _handleNavigate() {
        navigate("/");
    }

    // handle Returns
    function _handleTabChange(value) {
        setCurrentPage(1);
        setPageLoader(true);
        setSelectedTab(value);
    }

    // Handle page change
    function _handlePageChange(pageNumber) {
        window.scrollTo(0, 0)
        setCurrentPage(pageNumber + 1);
    };

    function _handleNotification(obj, index, key) {
        if (obj.is_read === true) {
            _navigateTarget(obj)
        } else {
            _readNotification(obj, index, key)
        }

    }

    function _navigateTarget(obj) {
        switch (obj.notification_type) {
            case 1:
                if (obj.investment_type === 1) {
                    navigate("/orders/goal-order", {
                        state: {
                            id: Number(obj.extra_data),
                            paymentTypes: [],
                            orderStatus: [],
                            orderTypes: [],
                            startDate: null,
                            endDate: null,
                            type: 1,
                            page: 1
                        }
                    })
                } else if (obj.investment_type === 2) {
                    navigate("/orders/fund-order", {
                        state: {
                            id: Number(obj.extra_data),
                            paymentTypes: [],
                            orderStatus: [],
                            orderTypes: [],
                            startDate: null,
                            endDate: null,
                            type: 2,
                            page: 1
                        }
                    })
                } else if (obj.investment_type === 3) {
                    navigate("/orders/goal-order", {
                        state: {
                            id: Number(obj.extra_data),
                            paymentTypes: [],
                            orderStatus: [],
                            orderTypes: [],
                            startDate: null,
                            endDate: null,
                            type: 3,
                            page: 1
                        }
                    })
                }

                break;
            case 2:
                if (obj.investment_type === 1) {
                    navigate("/orders/goal-order", {
                        state: {
                            id: Number(obj.extra_data),
                            paymentTypes: [],
                            orderStatus: [],
                            orderTypes: [],
                            startDate: null,
                            endDate: null,
                            type: 1,
                            page: 1
                        }
                    })
                } else if (obj.investment_type === 2) {
                    navigate("/orders/fund-order", {
                        state: {
                            id: Number(obj.extra_data),
                            paymentTypes: [],
                            orderStatus: [],
                            orderTypes: [],
                            startDate: null,
                            endDate: null,
                            type: 2,
                            page: 1
                        }
                    })
                } else if (obj.investment_type === 3) {
                    navigate("/orders/goal-order", {
                        state: {
                            id: Number(obj.extra_data),
                            paymentTypes: [],
                            orderStatus: [],
                            orderTypes: [],
                            startDate: null,
                            endDate: null,
                            type: 3,
                            page: 1
                        }
                    })
                }

                break;
            default:
                break;
        }
    }


    // API - list notifications
    const _getNotificationList = () => {
        const url = "/notification/list";

        const request = {
            "page_num": currentPage,
            "page_size": 10,
            "is_transaction": selectedTab === 1 ? true : false

        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setNotificationList(response.data.notifications);
                setTotalCount(response.data.total_page);
            } else {
                setNotificationList([]);
            }
            setPageLoader(false);
        })
    }

    // API - read notification
    const _readNotification = (notification, index, key) => {
        const url = "/notification/read";

        const request = {
            notification_id: notification.notification_id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (notification.notification_id !== null) {
                    const notification_list = [...notificationList]
                    notification_list[index].data[key].is_read = !notification.is_read;
                    setNotificationList(notification_list);
                    _navigateTarget(notification);
                } else {
                    setPageLoader(true);
                    _getNotificationList();
                }
                _getNotificationCount();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }
    return (
        <Fragment>
            {

                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pb-5">
                            <section className="px-4 ">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Notifications"
                                    type={1}
                                    handleNavigate={_handleNavigate} />
                                <h5 className="margin-30px-tb e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green">
                                    All notifications
                                </h5>

                                <div className="row">
                                    <div className="col-xl-8 col-lg-9">
                                        <div className="box-shadow-black border-radius-24px">

                                            <div className="border-bottom-1px border-chinese-white px-4 pt-2">
                                                <CustomTabBar data={TAB_DATA}
                                                    selectedTab={selectedTab}
                                                    onSelectTab={_handleTabChange} />
                                            </div>
                                            {
                                                pageLoader === true ?
                                                    <Loader height="h-80vh" />
                                                    :
                                                    notificationList.length === 0 ?
                                                        <EmptyScreen className="my-5 pb-5"
                                                            title="No notification found"
                                                            type={5}
                                                            description="" />
                                                        :
                                                        <Fragment>

                                                            {
                                                                notificationList.map((item, index) => {
                                                                    return (
                                                                        <Fragment>
                                                                            <div className="position-relative">

                                                                                <div className="py-3 px-4 d-flex align-items-center justify-content-between ">
                                                                                    <span className="e-font-14 e-montserrat-medium e-line-height-24 color-eerie-black">{item.title}</span>
                                                                                    {index === 0 && currentPage === 1 && COUNT > 0 &&
                                                                                        <LinkButton label="Mark all as read" className='e-font-14 e-line-height-24'
                                                                                            onPress={() => _readNotification({
                                                                                                notification_id: null
                                                                                            })} />
                                                                                    }

                                                                                </div>
                                                                                <img src={require('../../Assets/Images/Notification/seperator.png')} alt="" className="w-100 position-absolute bottom-0" />
                                                                            </div>
                                                                            {
                                                                                item.data.map((notification, key) => {
                                                                                    return (
                                                                                        <div className={key > 0 ? "border-top-1px border-gainsboro e-notification-wrapper" : "e-notification-wrapper"} onClick={() => _handleNotification(notification, index, key)}>
                                                                                            <div className={`row pt-4 px-4 pb-4 justify-content-between align-items-start`}>
                                                                                                <div className="col-11 ">
                                                                                                    <div className="d-flex align-items-start gap-8px">
                                                                                                        <div className="position-relative">
                                                                                                            {notification.image && <img src={notification.image}
                                                                                                                alt="notification"
                                                                                                                width={34}
                                                                                                                className="box-shadow-black border-radius-8px" />}
                                                                                                            {notification.is_read === false && <div className="min-w-8px min-h-8px e-bg-rebecca-purple position-absolute bottom-0 end-0 border-radius-100px"></div>}
                                                                                                        </div>
                                                                                                        <div className="d-flex flex-column">
                                                                                                            <span className="e-font-16 e-line-height-28 e-montserrat-semi-bold color-rebecca-purple">{notification.message}</span>
                                                                                                            {
                                                                                                                notification.data.map((detail) => {
                                                                                                                    return (
                                                                                                                        <span className="e-font-14 e-line-height-24 e-montserrat-regular color-eerie-black">{detail.label}: {detail.value}</span>

                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-1 text-end">
                                                                                                    <Icon icon="arrow-right"
                                                                                                        size={20} />
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }

                                                            <ReactPaginate previousLabel={<PrevIcon />}
                                                                nextLabel={<NextIcon />}
                                                                breakLabel={<BreakLabel />}
                                                                pageCount={totalCount}
                                                                pageRangeDisplayed={2}
                                                                marginPagesDisplayed={1}
                                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                                renderOnZeroPageCount={null}
                                                                forcePage={currentPage - 1}
                                                                containerClassName="e-pagination mt-5 pb-5"
                                                                activeClassName="e-pagination-active" />
                                                        </Fragment>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            }

        </Fragment>
    )
}

export default NotificationList