/*
 *   File : fund-category.js
 *   Author : https://evoqins.com
 *   Description : Component to show fund categories.
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { Fragment } from "react"
import style from "../../Styles/Components/fund-category.module.scss"
import { useNavigate } from "react-router-dom"

const FundCategoryCard = (props) => {

    const navigate = useNavigate();

    const _handleNavigate = (id) => {
        navigate("/explore", {
            state: {
                filter_id: id,
                query: ""
            }
        })
    }
    return (
        <Fragment>
            {/* <div className={`border-radius-16px d-flex justify-content-center align-items-center flex-column ${style.e_fund_card}`}
       style={{ background: props.data.background }}> */}
            <img src={props.data.icon} alt={props.data.name}
                className={`${style.e_fund_card} w-100 `}
                draggable={false}
                onClick={() => _handleNavigate(props.data.id)} />
            {props.show_name === true && <p className="e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black mb-0 position-absolute bottom-0 start-0 end-0 mx-auto text-center">{props.data.name}</p>
            }            {/* </div> */}
        </Fragment>
    )
}

export default FundCategoryCard