/*
*   File : bank-details.js
*   Author : https://evoqins.com
*   Description : Screen to fill the bank details of User
*   Version : 1.0.0
*/

// import packages
import { toast } from "react-toastify";
import { Fragment, useState, useEffect } from "react";
import { Modal } from "bootstrap";

// import components
import { StepProgress } from "../../../Components/Other";
import { CustomFileInput, CustomTextInput, RadioGroup } from "../../../Components/FormElements";
import { KYCProgressCard } from "../../../Components/Card";
import { Icon } from "../../../Components/Icon";
import { PrimaryButton } from "../../../Components/Buttons";
import { KYCErrorCard } from "../../../Components/Cards";
import { BankVerificationModal } from "../../../Components/Modal";

// import services
import APIService from "../../../Services/api-service";
import { _getFilePath } from "../../../Helper/api";
import { _uploadDocumentToS3 } from "../../../Services/aws-service";
import { _getFileName, _getOptions } from "../../../Helper/helper";

// import store
import store from "../../../Store";


const BankDetails = (props) => {

    const PROGRESS_DATA = store.getState().Reducer.SIGNUP_PROGRESS;
    const GENERAL_DATA = store.getState().Reducer.GENERAL_DATA;

    const [accountTypes, setAccountTypes] = useState([]);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [accountType, setAccountType] = useState(null);

    const [ifsc, setIfsc] = useState("");
    const [ifscError, setIfscError] = useState("");

    const [bankUrl, setBankUrl] = useState("");
    const [bankUrlError, setBankUrlError] = useState("");
    const [fileName, setFileName] = useState("");

    const [kycUser, setKycUser] = useState(false);

    const [fileUploader, setFileUploader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);

    const [bankStatus, setBankStatus] = useState(false);

    const [errors, setErrors] = useState([]);


    useEffect(() => {
        // account types options
        let account_types = [];
        if (PROGRESS_DATA.kyc_data.is_nri === true) {
            account_types = _getOptions(GENERAL_DATA.nri_bank_account_types, 1);
        }
        else {
            account_types = _getOptions(GENERAL_DATA.ri_bank_account_types, 1);
        }
        setAccountType(account_types[0].id);
        setAccountTypes(account_types);
        //eslint-disable-next-line
    }, [GENERAL_DATA]);

    useEffect(() => {
        if (bankStatus === true) {
            const modal = new Modal(document.getElementById("bank-verification"));
            modal.show();
        }
    }, [bankStatus]);

    useEffect(() => {
        const signup_progress = PROGRESS_DATA
        setKycUser(signup_progress.kyc_data.is_kyc_compliant);
        if (signup_progress.step_detail[5].error === true) {
            setErrors(signup_progress.step_detail[5].message);
        }
        if (Object.keys(signup_progress.kyc_data.bank_details).length > 0) {
            setAccountNumber(signup_progress.kyc_data.bank_details.account_number !== null ? signup_progress.kyc_data.bank_details.account_number : "");
            setName(signup_progress.kyc_data.bank_details.account_holder_name !== null ? signup_progress.kyc_data.bank_details.account_holder_name : "");
            setAccountType(signup_progress.kyc_data.bank_details.account_type_id !== null ? signup_progress.kyc_data.bank_details.account_type_id : 1);
            setIfsc(signup_progress.kyc_data.bank_details.ifsc !== null ? signup_progress.kyc_data.bank_details.ifsc : "");
            if (signup_progress.kyc_data.bank_proof_url !== null) {
                setBankUrl(signup_progress.kyc_data.bank_proof_url);
                setFileName(_getFileName(signup_progress.kyc_data.bank_proof_url));
            }
        }
    }, [PROGRESS_DATA]);

    // handle Acc number
    function _handleAccNumber(input_value) {
        setAccountNumber(input_value);
        setAccountNumberError("");
    }

    // handle Acc holder name
    function _handleName(input_value) {
        setName(input_value);
        setNameError("");
    }

    // handle Acc type
    function _handleAccType(id) {
        setAccountType(id);
    }

    // handle IFSC
    function _handleIFSC(input_value) {
        setIfsc(input_value);
        setIfscError("");
    }

    // handle esign modal
    function _verifyBank(status) {
        setBankStatus(status);
    }


    // function handle PAN
    function _handleFileUpload(file) {
        if (file.size > 5242880) {
            setBankUrlError("Upload Statement below 5MB");
        } else {
            _uploadBankStatement(file);
            setBankUrlError("");
        }
    }



    // handle form validtion 
    function _submitBankDetails() {
        let valid = true;
        const ifsc_value = ifsc.trim()
        const name_value = name.trim()
        if (accountNumber === "") {
            setAccountNumberError("Account number cannot be empty");
            valid = false
        }
        if (name_value === "") {
            setNameError("Account holder name cannot be empty");
            valid = false
        }
        if (ifsc_value === "") {
            setIfscError("IFSC code cannot be empty");
            valid = false
        }
        if (kycUser === false && bankUrl === "") {
            setBankUrlError("Bank statement cannot be empty");
            valid = false
        }

        if (valid === true) {
            _saveBank();
        }

    }

    // API - Save bank details
    const _saveBank = () => {
        setApiLoader(true);
        const url = "/kyc/save-bank";

        const request = {
            "account_number": accountNumber,
            "account_holder_name": name,
            "account_type": accountType,
            "ifsc": ifsc.toLocaleUpperCase(),
            "bank_proof_url": kycUser ? null : bankUrl
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Bank details added", {
                    type: "success"
                });
                _verifyBank(true);
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - get file path
    const _uploadBankStatement = async (file) => {
        setFileUploader(true);
        const request = {
            type: "image",
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            _uploadDocumentToS3(response.data, file).then((result) => {
                setFileName(file.name);
                setBankUrl(response.data.view_info);
                setFileUploader(false);
            }).catch((error) => {
                setFileUploader(false);
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }

    return (
        <Fragment>
            <Icon icon="Arrow-Right"
                size={32}
                className="margin-12px-tb rotate-left cursor-pointer"
                onClick={props.navigateBack} />
            <div className="row pt-lg-3 pt-0">
                <div className="col-lg-7">
                    <StepProgress type={2}
                        step={props.completed}
                        stepNumber={kycUser ? 5 : 6}
                        maxStep={kycUser ? 6 : 8} />
                    <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-0 mt-4">Bank details</h6>
                    {
                        errors.length > 0 &&
                        <KYCErrorCard errors={errors} />
                    }
                    <div className="row padding-20px-top">
                        <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                            <CustomTextInput label="Account number"
                                postfix="*"
                                type="number"
                                value={accountNumber}
                                error={accountNumberError}
                                inputClass="e-kyc-input"
                                placeholder="Enter account number"
                                handleChange={_handleAccNumber} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                            <CustomTextInput label="Account holder name"
                                postfix="*"
                                value={name}
                                error={nameError}
                                inputClass="e-kyc-input"
                                placeholder="Account holder name"
                                handleChange={_handleName} />
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-6 pb-3">
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Account type
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={accountTypes}
                                selected={accountType}
                                handleSelect={_handleAccType} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                            <CustomTextInput label="IFSC code"
                                postfix="*"
                                value={ifsc}
                                error={ifscError}
                                inputClass="text-transform-upper e-kyc-input"
                                placeholder="Eg:8899000"
                                handleChange={_handleIFSC} />
                        </div>
                        {kycUser === false && <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                            <CustomFileInput label="Upload Bank Statement"
                                postfix="*"
                                file={fileName}
                                loader={fileUploader}
                                error={bankUrlError}
                                onFileChange={_handleFileUpload}
                                clear={() => {
                                    setFileName("");
                                    setBankUrl("");
                                }} />
                        </div>}

                        <div className="border-top-1px border-bright-gray my-lg-4 my-3" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-5 d-lg-block d-none">
                                <PrimaryButton label="Save & Continue"
                                    className="padding-12px-tb w-100"
                                    disabled={apiLoader}
                                    onPress={_submitBankDetails} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <KYCProgressCard steps={props.investment_steps}
                        completedStep={props.completedStep} />
                    <div className="col-md-6 col-sm-6 d-lg-none d-flex justify-content-center pt-3">
                        <PrimaryButton label="Save & Continue"
                            className="padding-12px-tb padding-54px-lr"
                            disabled={apiLoader}
                            onPress={_submitBankDetails} />
                    </div>
                </div>
            </div>
            {
                bankStatus === true &&
                <BankVerificationModal
                    closeModal={() => _verifyBank(false)}
                    submitInvestment={() => props.handleNavigate()}
                    reSubmit={() => _verifyBank(false)}
                />
            }
        </Fragment>
    )
}

export default BankDetails