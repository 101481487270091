
/*
 *   File :custom-navigation.js
 *   Author URI : https://evoqins.com
 *   Description :Custom Navigation modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";



const CustomNavigationModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("custom-navigation");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="custom-navigation"
                tabIndex="-1"
                aria-labelledby="custom-navigation"
                aria-hidden="true"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content text-center p-4 ${style.e_content} `}>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer position-absolute right-16px top-16px d-none' />

                        <Fragment>

                            <img src={require("../../Assets/Images/Modal/success.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 mt-3">
                                Would you like to tag this to any milestone?
                            </p>
                            <div className="row pt-3">
                                <div className="col-6">
                                    <SecondaryButton label="No"
                                        cancel="modal"
                                        className="w-100 padding-12px-tb mt-4"
                                        onPress={props.explore} />
                                </div>
                                <div className="col-6">
                                    <PrimaryButton label={"Yes"}
                                        cancel="modal"
                                        className="w-100 padding-12px-tb mt-4"
                                        onPress={props.goal} />
                                </div>
                            </div>

                        </Fragment>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

CustomNavigationModal.defaultProps = {
    closeModal: () => { },
    explore: () => { },
    goal: () => { }
}

export default memo(CustomNavigationModal)