
/*
 *   File : risk-assessment-review.js
 *   Author URI : https://evoqins.com
 *   Description :Risk Assessment Modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/risk.module.scss";



const RiskAssessmentReview = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        var my_modal = document.getElementById("risk-assessment");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_risk_modal}`}
                id="risk-assessment"
                tabIndex="-1"
                aria-labelledby="risk-assessment"
                aria-hidden="true"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4  text-center px-3 ${style.e_content} `}>
                        {/* <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer position-absolute right-16px top-16px' /> */}

                        <Fragment>
                            <img src={require("../../Assets/Images/risk-assessment/review.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2">
                                Risk Assessment Review
                            </h5>

                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 ">
                                It is recommended to update your risk assessment once or twice a year. Updating more or less frequently can affect your financial goals.
                            </p>
                            <div className="row">
                                <div className="col-6">
                                    <SecondaryButton label="No, Cancel"
                                        cancel="modal"
                                        className="w-100 padding-12px-tb mt-4 bg-white" />
                                </div>

                                <div className="col-6">
                                    <PrimaryButton label={"Continue"}
                                        className="w-100 padding-12px-tb mt-4"
                                        cancel="modal"
                                        onPress={() => navigate("/risk-profile")} />
                                </div>
                            </div>

                        </Fragment>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

RiskAssessmentReview.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default memo(RiskAssessmentReview)