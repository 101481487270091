/*
 *   File : header.js
 *   Author : https://evoqins.com
 *   Description : Header component
 *   Version : 1.0.0
*/

// import packages
import { memo, Fragment } from "react";

// import components
import { useLocation, useNavigate } from "react-router-dom/dist";

// import styles
import styles from '../../Styles/Components/left-navigation.module.scss';
import { Icon } from "../Icon";


const MENU_ITEMS = [
    {
        name: "Home",
        link: "/",
        child_paths: ["/financial-freedom", "/financial-summary", "/orders", "/calculators", "/systematic-transactions", "/risk-profile", "/manage-bank", "/nominee", "/manage-kyc", "/reports", "/refer-earn", "/about-us", "/faq"],
        inactive: require("../../Assets/Images/Sidebar/home.webp"),
        active: require("../../Assets/Images/Sidebar/home-active.webp"),
    },
    {
        name: "Portfolio",
        link: "/portfolio",
        inactive: require("../../Assets/Images/Sidebar/portfolio.webp"),
        active: require("../../Assets/Images/Sidebar/potfolio-active.webp"),
    },
    {
        name: "Milestones",
        link: "/milestone",
        inactive: require("../../Assets/Images/Sidebar/lifegoals.webp"),
        active: require("../../Assets/Images/Sidebar/lifegoals-active.webp"),
    },
    {
        name: "Explore",
        link: "/explore",
        inactive: require("../../Assets/Images/Sidebar/explore.webp"),
        active: require("../../Assets/Images/Sidebar/explore-active.webp"),
    },
    {
        name: "Educate",
        link: "/resource",
        inactive: require("../../Assets/Images/Sidebar/educate.webp"),
        active: require("../../Assets/Images/Sidebar/educate-active.webp"),
    }
]


const LeftNavigation = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Fragment>
            <div
                data-bs-backdrop="static"
                data-bs-scroll="false"
                tabIndex="0"
                id="left-navigation-offcanvas"
                aria-labelledby="offcanvasWithBothOptionsLabel"
                className={`${props.type ? "offcanvas offcanvas-end px-3 w-auto pt-3 align-items-center outline-none" : "position-sticky top-0 padding-32px-top d-flex flex-column align-items-center outline-none"} `}>

                {/* Logo */}
                <img src={require("../../Assets/Images/Global/logo.svg").default}
                    alt="logo"
                    height={42}
                    width={68}
                    draggable={false}
                    className="e-logo mb-4 px-2 w-100"
                    data-bs-dismiss={props.type && "offcanvas"}
                    onClick={() => navigate("/")} />
                {/* Menu list */}
                {
                    MENU_ITEMS.map((item, key) => {
                        const route_nodes = location.pathname.split("/");

                        // if array length more than 2 means detail page taking the 1st node from url 
                        const index_page = (route_nodes.length >= 2) ? `/${route_nodes[1]}` : location.pathname;
                        return (
                            // eslint-disable-next-line
                            <div key={key} data-bs-dismiss={props.type && "offcanvas"} className={`w-64px h-64px margin-12px-bottom text-center padding-10px-top padding-6px-bottom border-radius-10px ${index_page === item.link || item.child_paths && item.child_paths.includes(index_page) ? 'e-bg-lavender-blush' : styles.e_navigation_item}`}
                                onClick={() => {
                                    if (item.link !== index_page) {
                                        navigate(item.link)
                                    }
                                }}>
                                {/* eslint-disable-next-line */}
                                <img src={index_page === item.link || item.child_paths && item.child_paths.includes(index_page) ? item.active : item.inactive}
                                    alt={item.name}
                                    width={24}
                                    height={24}
                                    className="mb-1 d-md-flex d-none mx-auto" />
                                {/* eslint-disable-next-line */}
                                <img src={index_page === item.link || item.child_paths && item.child_paths.includes(index_page) ? item.active : item.inactive}
                                    alt={item.name}
                                    width={20}
                                    height={20}
                                    className="mb-1 d-md-none d-flex mx-auto" />
                                <p className="e-font-12 e-line-height-20 color-rebecca-purple e-montserrat-medium mb-0">{item.name}</p>
                            </div>
                        )
                    })
                }
                <Icon icon="close"
                    size={24}
                    id="offcanvas-close"
                    className="mx-auto mb-2 d-lg-none d-block"
                    data-bs-dismiss="offcanvas"
                    onClick={() => document.body.removeAttribute("style")} />


            </div>

        </Fragment>
    )
}
export default memo(LeftNavigation)