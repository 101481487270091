
/*
 *   File : pause-stp.js
 *   Author URI : https://evoqins.com
 *   Description : Pause STP Modal
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Icon } from "../Icon";

// Styles
import style from "../../Styles/Components/pause-sip.module.scss";

// Services
import APIService from "../../Services/api-service";

const PauseSTPModal = (props) => {

    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("pause-stp");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, []);

    // API - Pause STP
    const _pauseSTP = () => {
        setButtonLoader(true);

        const url = "/stp/pause";

        const request = {
            "stp_id": props.id,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                toast.success("STP Paused", {
                    type: 'success'
                });
                props.confirm();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setButtonLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_pause_sip}`}
            id="pause-stp"
            tabIndex="-1"
            aria-labelledby="pause-stp"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-3 text-center position-relative  ${style.e_content} `}>
                    <Icon icon="close-btn"
                        size={24}
                        id="close-modal"
                        data-bs-dismiss="modal"
                        className='cursor-pointer position-absolute right-16px top-16px' />
                    <img src={require("../../Assets/Images/Modal/failed.png")}
                        alt="success"
                        draggable={false}
                        width={144}
                        height={144}
                        className="mx-auto" />
                    <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0 mt-2 text-center">
                        Do yo want to pause this STP?
                    </h5>
                    <div className="row margin-32px-top">
                        <div className="col-6 pe-1">
                            <SecondaryButton label="No, Cancel"
                                cancel="modal"
                                className="padding-10px-tb w-100 e-bg-magnolia"
                                onPress={props.close} />
                        </div>
                        <div className="col-6 ps-1">
                            <PrimaryButton label="Yes, I want"
                                className="w-100 padding-10px-tb "
                                disabled={buttonLoader}
                                showLoader={buttonLoader}
                                onPress={_pauseSTP} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PauseSTPModal;