
/*
 *   File : delete-account.js
 *   Author URI : https://evoqins.com
 *   Description :Modal component to show confirmation to remove customer details from our database. 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/delete-account.module.scss";



const DeleteAccountModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("delete-profile");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_delete_account}`}
                id="delete-profile"
                tabIndex="-1"
                aria-labelledby="delete-profile"
                aria-hidden="true"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4  text-center px-3 ${style.e_content} `}>
                        <Fragment>
                            <img src={require("../../Assets/Images/Modal/delete.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2">
                                Account Deletion
                            </h5>

                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 ">
                                Please confirm the deletion of your
                                account by clicking the “Delete” button
                            </p>
                            <div className="row mt-4">
                                <div className="col-6">
                                    <SecondaryButton label="No, Cancel"
                                        cancel="modal"
                                        className="w-100 padding-12px-tb" />
                                </div>
                                <div className="col-6">
                                    <PrimaryButton label={"Delete Account"}
                                        className="w-100 padding-12px-tb"
                                        cancel="modal" />
                                </div>
                            </div>

                        </Fragment>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

DeleteAccountModal.defaultProps = {
    closeModal: () => { },
}

export default memo(DeleteAccountModal)