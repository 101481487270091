/*
 *   File : calender.js
 *   Author : https://evoqins.com
 *   Description : Calender component
 *   Integrations : react-day-picker
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';

// import components
import { Icon } from '../Icon';
import { useClickOutside } from '../../Helper/helper';

// const pastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const CustomDatePicker = React.memo((props) => {
    // state when single date has to be selected
    const [selected, setSelected] = useState(props.start === "DD-MM-YYYY" ? null : new Date(parseDate(props.start)));
    // state when date is a range
    const [range, setRange] = useState(props.start === "DD-MM-YYYY" ?
        { from: null, to: null }
        :
        {
            from: new Date(parseDate(props.start)),
            to: new Date(parseDate(props.end))
        });
    // drop down open
    const [show, setShow] = useState(false);
    const calendarRef = useRef(null);



    useClickOutside(calendarRef, () => {
        setShow(false);
    });

    useEffect(() => {
        props.menuOpen(show);

        //eslint-disable-next-line
    }, [show])

    useEffect(() => {
        if (props.mode === 'single' && selected) {
            setShow(false);
            props.selectDate(selected); // Passing the selected single date directly
        } else if (range && range.from && range.to) {
            setShow(false);
            props.selectDate(range);
        }
        //eslint-disable-next-line
    }, [selected, range]);

    function handleCalendar() {
        setShow(true);
    }

    // default date function when date is not passed
    function parseDate(dateString) {
        const parts = dateString.split('-');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    return (
        <Fragment>
            {props.label && <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                {props.label}
                {props.postfix && <span className="color-deep-carmine-pink">*</span>}
            </label>}
            <div className='position-relative' ref={calendarRef} >
                {
                    props.mode === "single" ?
                        <div className={`${props.className} ${props.start !== "DD-MM-YYYY" ? "color-charleston-green e-montserrat-medium e-font-14" : "color-medium-gray e-font-14 e-montserrat-regular"} d-flex align-items-center justify-content-between e-line-height-20 bg-white border-1px border-silver-sand padding-12px-tb px-3 border-radius-12px cursor-pointer mb-0`}
                            onClick={handleCalendar}>
                            {props.start}
                            <Icon icon="dob" size={16} />
                        </div>
                        :
                        <div className={`${props.className} d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer color-gray e-font-14 e-montserrat-regular mb-0`}
                            onClick={handleCalendar}>
                            {props.start} - {props.end}
                            <Icon icon="dob" size={16} />
                        </div>
                }

                {show && (
                    <div className={`position-absolute z-index-99 top-40px ${props.popupClass}`}>
                        <DayPicker
                            id="test"
                            disabled={props.disabledDays}
                            mode={props.mode}
                            defaultMonth={props.defaultMonth}
                            captionLayout="dropdown"
                            fromYear={1947}
                            toYear={props.toYear}
                            // defaultMonth={pastMonth}
                            selected={props.mode === 'single' ? new Date(parseDate(props.start)) : range}
                            onSelect={props.mode === 'single' ? setSelected : setRange}
                        />
                    </div>
                )}

                {
                    props.error &&
                    <p className='position-absolute color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0  margin-6px-top'>
                        {props.error}
                    </p>
                }
            </div>
        </Fragment>
    );
});

CustomDatePicker.defaultProps = {
    start: "DD-MM-YYYY",
    end: "DD-MM-YYYY",
    menuOpen: () => { },
    toYear: new Date().getFullYear()
};

export default CustomDatePicker;
