/*
*   File : portfolio.js
*   Author : https://evoqins.com
*   Description : Container file to show the user's investment summary which includes Basket, Goal and Funds
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, EmptyScreen, Loader } from "../../Components/Other";
import { LinkButton, PrimaryButton, SecondaryButton } from "../../Components/Buttons";
import { CustomTabBar } from "../../Components/Tab";
import { DoughnutChart, HorizontalStackedBar } from "../../Components/Charts";
import { ImportExternalFundsModal, FundInvestModal, InvestMoreModal, GoalRedemptionModal, FundRedemptionModal } from "../../Components/Modal";
import { Icon } from "../../Components/Icon";
import { CustomSelectBox } from "../../Components/FormElements";

// import services
import APIService from "../../Services/api-service";
import { toast } from "react-toastify";
import { _findNearestDate, _forceNavigate, _formatSip, _getTomorrowDate } from "../../Helper/helper";


const INVESTMENT_TYPES = [
    {
        label: "Overview",
        id: 1
    },
    {
        label: "Categories",
        id: 2
    },
    {
        label: "Funds",
        id: 3
    },
];

const MOBILE_INVESTMENT_TYPES = [
    {
        label: "Overview",
        value: 1
    },
    {
        label: "Categories",
        value: 2
    },
    {
        label: "Funds",
        value: 3
    },
];

const MOBILE_HOLDINGS_TYPES = [
    {
        label: "Milestone",
        value: 1,
        icon: require("../../Assets/Images/Portfolio/milestone.svg").default,
        active: require("../../Assets/Images/Portfolio/milestone-active.svg").default
    },

    {
        label: "Funds",
        value: 2,
        icon: require("../../Assets/Images/Portfolio/funds.svg").default,
        active: require("../../Assets/Images/Portfolio/funds-active.svg").default

    },
    {
        label: "Baskets",
        value: 3,
        icon: require("../../Assets/Images/Portfolio/basket.svg").default,
        active: require("../../Assets/Images/Portfolio/basket-active.svg").default

    },
];

const FUND_TYPES = [
    {
        label: "MINTIT",
        id: 1
    },
    {
        label: "External",
        id: 2
    },
];

const MOBILE_FUND_TYPES = [
    {
        label: "Internal",
        value: 1
    },
    {
        label: "External",
        value: 2
    },
];

const Portfolio = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [investmentType, setInvestmentType] = useState(INVESTMENT_TYPES[0].id);
    const [selectedInvestmentType, setSelectedInvestmentType] = useState(MOBILE_INVESTMENT_TYPES[0]);

    const [externalInvestmentType, setExternalInvestmentType] = useState(INVESTMENT_TYPES[0].id);
    const [selectedExternalInvestmentType, setSelectedExternalInvestmentType] = useState(MOBILE_INVESTMENT_TYPES[0]);

    const [fundType, setFundType] = useState(FUND_TYPES[0].id);
    const [selectedFundType, setSelectedFundType] = useState(MOBILE_FUND_TYPES[0]);

    const [holdingType, setHoldingType] = useState(null);
    const [selectedHoldingType, setSelectedHoldingType] = useState(null);

    const [filterLoader, setFilterLoader] = useState(true);
    const [overviewOptions, setOverviewOptions] = useState([]);
    const [mobileOverviewOptions, setMobileOverviewOptions] = useState([]);

    const [holdingOptions, setHoldingOptions] = useState([]);

    const [importFunds, setImportFunds] = useState(false);

    const [showFunds, setShowFunds] = useState(false);


    const [mfSummary, setMfSummary] = useState({});

    const [selected, setSelected] = useState(null);

    const [pageLoader, setPageLoader] = useState(true);
    const [overviewLoader, setOverviewLoader] = useState(true);

    const [portfolioData, setPortfolioData] = useState({});

    const [holdingsList, setHoldingList] = useState([]);
    const [holdingLoader, setHoldingLoader] = useState(false);
    const [holdingsOverview, setHoldingsOverview] = useState({});

    const [graphData, setGraphData] = useState({});
    const [showHoldings, setShowHoldings] = useState(null);
    const [showAllHoldings, setShowAllHoldings] = useState(false);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [showAllFunds, setShowAllFunds] = useState(false);

    const [showAllCategoriesExternal, setShowAllCategoriesExternal] = useState(false);
    const [showAllFundsExternal, setShowAllFundsExternal] = useState(false);


    const [fundInvestMore, setFundInvestMore] = useState(null);

    const [selectedDate, setSelectedDate] = useState(_getTomorrowDate());
    const [selectedDateString, setSelectedDateString] = useState(_formatSip(_getTomorrowDate()));

    const [processingAmountStatus, setProcessingAmountStatus] = useState(JSON.parse(sessionStorage.getItem("is_processing_amt_removed")) || false)

    const [fundAllocationData, setFundAllocationData] = useState([]);
    const [categoryAllocationData, setCategoryAllocationData] = useState([]);

    const [portfolioStatus, setPortfolioStatus] = useState(null);
    const [refreshHoldings, setRefreshHoldings] = useState(true);
    const [externalPortfolioData, setExternalPortfolioData] = useState({});
    const [investmentSuggestion, setInvestmentSuggestion] = useState({});
    const [goalInvestMore, setGoalInvestMore] = useState(null);
    const [mfRedeemData, setMfRedeemData] = useState(null);

    // const [updateInvestMore, setUpdateInvestMore] = useState(null);
    // const [updateInvestLoader, setUpdateInvestLoader] = useState(false);

    const [goalRedeemData, setGoalRedeemData] = useState(null);

    _forceNavigate(() => {
        //eslint-disable-next-line
        if (location.pathname == "/portfolio") {
            navigate(-2)
        }
    });


    useEffect(() => {
        _getFilterData();
        if (location.state === null) {
            _getPortfolioDetails(1, selectedFundType);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (importFunds === true) {
            const modal = new Modal(document.getElementById("import-funds"))
            modal.show();
        }
    }, [importFunds]);


    useEffect(() => {
        if (fundInvestMore !== null) {
            const modal = new Modal(document.getElementById("fund-invest"))
            modal.show();
        }
    }, [fundInvestMore]);

    useEffect(() => {
        if (goalRedeemData !== null) {
            const modal = new Modal(document.getElementById("goal-redemption"))
            modal.show();
        }
        // eslint-disable-next-line
    }, [goalRedeemData]);

    useEffect(() => {
        if (goalInvestMore !== null) {
            const modal = new Modal(document.getElementById("invest-more"))
            modal.show();
        }
    }, [goalInvestMore]);

    useEffect(() => {
        if (mfRedeemData !== null) {
            const modal = new Modal(document.getElementById("fund-redeem"))
            modal.show();
        }
    }, [mfRedeemData]);

    // useEffect(() => {
    //     if (updateInvestMore !== null) {
    //         const modal = new Modal(document.getElementById("update-investment"))
    //         modal.show();
    //     }
    // }, [updateInvestMore]);


    useEffect(() => {
        let data = fundType === 1 ? portfolioData : externalPortfolioData
        if (Object.keys(data).length > 0) {
            const FUND_ALLOCATION_DATA = data.funds.graph_data !== undefined && data.funds.graph_data.map((item) => {
                return (
                    {
                        name: item.asset_class,
                        ...item
                    }
                )
            })

            const CATEGORY_ALLOCATION_DATA = data.categories.graph_data !== undefined && data.categories.graph_data.map((item) => {
                return (
                    {
                        name: item.asset_class,
                        ...item
                    }
                )
            })
            setFundAllocationData(FUND_ALLOCATION_DATA);
            setCategoryAllocationData(CATEGORY_ALLOCATION_DATA)
        }
    }, [portfolioData, externalPortfolioData, fundType]);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.holding_type) {
                setHoldingType(location.state.holding_type);
            }
            if (location.state.selected) {
                setSelected(location.state.selected);
            }
            setInvestmentType(location.state.investment_type);
            if (location.state.external_investment_type) {
                setExternalInvestmentType(location.state.external_investment_type);
                setSelectedExternalInvestmentType(MOBILE_INVESTMENT_TYPES[location.state.external_investment_type - 1])
            }
            if (location.state.fund_type) {

                _getPortfolioDetails(location.state.fund_type, MOBILE_FUND_TYPES[location.state.fund_type - 1]);
            }
            if (location.state.show) {
                if (location.state.investment_type === 2) {
                    setShowAllCategories(true)
                } else if (location.state.investment_type === 3) {
                    setShowAllFunds(true)
                }
            }

            setSelectedInvestmentType(MOBILE_INVESTMENT_TYPES[location.state.investment_type - 1])
            setSelectedHoldingType(MOBILE_HOLDINGS_TYPES[location.state.holding_type - 1])

        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (selected !== null) {
            _getHoldings(selected.value);
        }
        // eslint-disable-next-line
    }, [selected, fundType]);

    useEffect(() => {
        if (holdingType !== null) {
            _getHoldingsOverview(holdingType);
        }
        // eslint-disable-next-line
    }, [holdingType, fundType])

    function _handleNavigate() {
        navigate("/");
    }


    function _handleInvestmentType(tab_index) {
        if (fundType === 1) {
            setInvestmentType(tab_index);
            setSelectedInvestmentType(MOBILE_INVESTMENT_TYPES[tab_index - 1]);
        } else {
            setExternalInvestmentType(tab_index);
            setSelectedExternalInvestmentType(MOBILE_INVESTMENT_TYPES[tab_index - 1]);
        }
    }

    function _handleProcessingAmount() {
        sessionStorage.setItem("is_processing_amt_removed", true);
        setProcessingAmountStatus(true)
    }

    function _handleHoldings(tab_index) {
        setHoldingType(tab_index);
    }

    function _handleImport(status) {
        setImportFunds(status);
    }

    function _handleSuccess() {
        _getPortfolioDetails(fundType, selectedFundType);
        setShowFunds(true);
    }


    function _handleHoldingsTypes(select_value) {
        setSelected(select_value)
        setShowHoldings(null)
    }

    function _handleToggleHoldings(index) {
        setShowHoldings(index)
    }

    // investment_type
    // 1 goal
    // 2 fund
    // 3 basket
    function _handleRedeem(data) {
        if (data.investment_type === 1) {
            _showGoalRedeemModal(data);
        } else if (data.investment_type === 3) {
            _showGoalRedeemModal(data);
        } else {
            _mfRedeem(data);
        }
    }

    function _showGoalRedeemModal(data) {
        setGoalRedeemData(data);
    }


    function _investMore(data) {
        if (data.investment_type === 1) {
            _getInvestmentSuggestions(data)
        } else if (data.investment_type === 3) {
            _getInvestmentSuggestions(data);
        } else {
            _getMFSummary(data);
        }
    }

    function _handleNavigateDetail(type, id) {
        navigate("/portfolio", {
            state: {
                holding_type: holdingType,
                selected: selected,
                investment_type: investmentType,
                fund_type: fundType,
                external_investment_type: externalInvestmentType
            }
        })
        setTimeout(() => {
            navigate("detail", {
                state: {
                    investment_type: type,
                    id: id,
                }
            })
        }, 0)
    }

    function _handleNavigateToFund(id) {
        navigate("/portfolio", {
            state: {
                holding_type: holdingType,
                selected: selected,
                investment_type: investmentType,
                fund_type: fundType,
                external_investment_type: externalInvestmentType
            }
        })
        setTimeout(() => {
            navigate("/explore/detail", {
                state: {
                    data: {
                        mf_id: id,
                    }
                }
            })
        }, 0);
    }

    // API - get portfolio data
    const _getPortfolioDetails = (type, selected) => {
        setPageLoader(true);
        setFundType(type);
        setSelectedFundType(selected);
        const url = "/portfolio/get";

        const request = {
            is_external: type === 1 ? false : true
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setPortfolioStatus(response.data.external_portfolio_import_status)
                setShowFunds(response.data.external_portfolio_imported);
                setRefreshHoldings(response.data.is_external_refresh_allowed);
                if (JSON.parse(sessionStorage.getItem("is_processing_amt_removed"))) {
                    setProcessingAmountStatus(JSON.parse(sessionStorage.getItem("is_processing_amt_removed")));
                } else {
                    if (response.data.total_investment.processing_amount > 0 || response.data.total_investment.processing_amount !== null) {
                        setProcessingAmountStatus(false)
                    } else {
                        setProcessingAmountStatus(true)
                    }
                }
                if (type === 1) {
                    setPortfolioData(response.data);
                } else {
                    setExternalPortfolioData(response.data);
                }
            } else {
                setPortfolioData({});
                setExternalPortfolioData({});
            }
            setPageLoader(false);
        })
    }


    // API - holding list
    const _getHoldings = (type) => {
        setHoldingLoader(true);
        const url = "/portfolio/list";

        const request = {
            investment_type: type,
            is_external: fundType === 1 ? false : true
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setHoldingList(response.data);
            } else {
                setHoldingList([]);
            }
            setHoldingLoader(false);
        })
    }

    // API - get holdings overview
    const _getHoldingsOverview = (type) => {
        setOverviewLoader(true);
        const url = "/portfolio/summary";
        const request = {
            investment_type: type,
            is_external: fundType === 1 ? false : true
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setHoldingsOverview(response.data);
                const labels = response.data.allocation_data.map((item) => item.name)
                const datasets = response.data.allocation_data.map((item) => item.allocation)
                const colors = response.data.allocation_data.map((item) => item.color)
                setGraphData({ labels, datasets, colors })
            } else {
                setHoldingsOverview({});
            }
            setOverviewLoader(false);
        })
    }

    // API- handle resync
    const _handleResync = () => {
        setShowFunds(true);
        const url = "/mf-services/initiate";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _handleImport(true);
            } else {
                toast.dismiss()
                toast.error(response.message, {
                    type: 'error'
                })
            }
        })
    }

    // API- get filter
    const _getFilterData = () => {
        const url = "/portfolio/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                if (response.data.summary_investment_types !== null) {
                    const overview_options = response.data.summary_investment_types.map((item) => {
                        return ({
                            label: item.label,
                            value: item.id
                        })
                    });
                    setMobileOverviewOptions(overview_options);
                    setOverviewOptions(response.data.summary_investment_types);

                    setHoldingType(response.data.summary_investment_types[0].id);
                    setSelectedHoldingType(overview_options[0])
                }
                if (response.data.holdings_investment_types !== null) {
                    let holding_options = []
                    response.data.holdings_investment_types.map((item) => {
                        return (
                            holding_options.push({
                                label: item.label,
                                value: item.id
                            })
                        )
                    });

                    setHoldingOptions(holding_options);
                    if (location.state === null || selected == null) {
                        setSelected(holding_options[0]);
                    }
                }
            }
            setFilterLoader(false);
        });
    }

    // API - mf summary
    const _getMFSummary = (data, is_mandate) => {
        const url = "/mf/summary";

        const request = {
            mf_id: data.mf_id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setFundInvestMore(data);
                setMfSummary(response.data);

                if (response.data.sip_dates !== null) {
                    setSelectedDate(_findNearestDate(response.data.sip_dates, selectedDate));
                    const date_string = _formatSip(_findNearestDate(response.data.sip_dates, _findNearestDate(response.data.sip_dates, selectedDate)));
                    setSelectedDateString(date_string);
                }

                if (is_mandate) {
                    if (response.data.mandate_status === true) {
                        toast.success("Mandate created successfully", {
                            type: "success",
                        });
                    } else {
                        toast.error("Mandate creation failed", {
                            type: "error",
                        });
                    }
                }
            }

        });
    }

    // API - Get Investment Suggestions
    const _getInvestmentSuggestions = (data, mandate_check) => {
        const url = "/goal/reinvestment-suggestion";
        const request = {
            goal_id: data.goal_id,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setGoalInvestMore(data);
                setInvestmentSuggestion(response.data);
                setSelectedDate(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate));

                const date_string = _formatSip(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate))
                setSelectedDateString(date_string);
                if (mandate_check === true) {
                    if (response.data.mandate_status === true) {
                        toast.success("Mandate created successfully", {
                            type: "success",
                        });
                    }
                    else {
                        toast.error("Mandate creation failed", {
                            type: "error",
                        });
                    }
                }
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // API - redeem information
    const _mfRedeem = (row) => {
        const url = "/mf/redeem-overview";
        const request = {
            mf_id: row.mf_id,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setMfRedeemData(response.data);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // API -portfolio detail 
    // const _getPortfolioDetailsForInvestMore = (data) => {
    //     const url = "/portfolio/detail";

    //     const request = {
    //         investment_type: data.investment_type,
    //         id: data.goal_id
    //     }

    //     APIService(true, url, request).then((response) => {
    //         if (response.status_code === 200) {
    //             setUpdateInvestMore(response.data);
    //         } else {
    //             toast.dismiss();
    //             toast.error(response.message, {
    //                 type: "error",
    //             });
    //         }
    //         setUpdateInvestLoader(false);

    //     })
    // }
    const _handleCloseInvestMoreModal = () => {
        setFundInvestMore(null);
        setGoalInvestMore(null)
    }

    const _handleCloseMfRedeemModal = () => {
        setMfRedeemData(null);
    }

    // const _handleCloseUpdateModal = () => {
    //     setUpdateInvestMore(null);
    // }


    const PortfolioDetails = (props) => {
        console.log(props)
        return (

            props.investmentType === 1 ?
                <Fragment>
                    <div className="d-flex align-items-center justify-content-between border-bottom-1px border-chinese-white py-sm-3 py-2">
                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                            <img src={require('../../Assets/Images/Home/total-returns.png')}
                                alt="total-returns"
                                width={28}
                                draggable={false} />
                            Total returns
                        </div>
                        <span className={`${props.portfolioData.total_investment.returns_amount > 0 ? 'color-emerald-green' : props.portfolioData.total_investment.returns_amount < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'} e-font-20 e-line-height-24 e-montserrat-semi-bold`}>
                            {props.portfolioData.total_investment.returns_amount_text}
                            <span className="e-font-16 letter-spacing-02em">({props.portfolioData.total_investment.returns_percentage_text})</span>
                        </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between  border-bottom-1px border-chinese-white py-sm-3 py-2">
                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                            <img src={require('../../Assets/Images/Home/current.png')}
                                alt="total-returns"
                                width={28}
                                draggable={false} />
                            Current
                        </div>
                        <span className="color-eerie-black e-font-18 e-line-height-24 e-montserrat-semi-bold letter-spacing-02em">{props.portfolioData.total_investment.current_amount_text}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between   border-bottom-1px border-chinese-white py-sm-3 py-2">
                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                            <img src={require('../../Assets/Images/Home/invested.png')}
                                alt="total-returns"
                                width={28}
                                draggable={false} />
                            Invested
                        </div>
                        <span className="color-eerie-black e-font-18 letter-spacing-02em e-line-height-24 e-montserrat-semi-bold">{props.portfolioData.total_investment.invested_amount_text}</span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between   border-bottom-1px border-chinese-white py-sm-3 py-2">
                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                            <img src={require('../../Assets/Images/Home/xirr.png')}
                                alt="total-returns"
                                width={28}
                                draggable={false} />
                            XIRR
                        </div>
                        <span className={`${props.portfolioData.total_investment.xirr > 0 ? "color-emerald-green" : props.portfolioData.total_investment.xirr < 0 ? "color-deep-carmine-pink" : "color-eerie-black"} e-font-16 letter-spacing-02em e-line-height-24 e-montserrat-semi-bold d-flex align-items-start`}>
                            {props.portfolioData.total_investment.xirr_text}
                            {props.portfolioData.total_investment.xirr !== 0 &&
                                <img width={16} alt="xirr" src={props.portfolioData.total_investment.xirr > 0 ? require("../../Assets/Images/Home/xirr-high.svg").default : require("../../Assets/Images/Home/xirr-low.png")} />
                            }
                        </span>
                    </div>

                    <div className="d-flex align-items-center justify-content-between py-sm-3 py-2">
                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                            <img src={require('../../Assets/Images/Home/1-day-return.png')}
                                alt="total-returns"
                                width={28}
                                draggable={false} />
                            1 day return
                        </div>
                        <span className={`${props.portfolioData.total_investment.one_day_returns > 0 ? "color-emerald-green" : props.portfolioData.total_investment.one_day_returns < 0 ? "color-deep-carmine-pink" : "color-eerie-black"} e-font-18 letter-spacing-02em e-line-height-24 e-montserrat-semi-bold `}>{props.portfolioData.total_investment.one_day_returns_text ? props.portfolioData.total_investment.one_day_returns_text : 0}
                            <span className="e-font-14">({props.portfolioData.total_investment.one_day_returns_percentage_text ? props.portfolioData.total_investment.one_day_returns_percentage_text : 0})</span>
                        </span>
                    </div>
                </Fragment>
                :
                props.investmentType === 2 ?
                    Object.keys(props.portfolioData.categories).length > 0 ?
                        <div className="pb-2">

                            <div className="py-2 border-bottom-1px border-chinese-white mb-sm-3 mb-1">
                                <HorizontalStackedBar height="h-28px" allocations={categoryAllocationData} />
                            </div>

                            <div className="row">
                                <div className="col-5">
                                    <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Category</span>
                                </div>
                                <div className="col">
                                    <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                </div>
                                <div className="col text-sm-center text-end">
                                    <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Allocation</span>
                                </div>
                            </div>
                            {
                                props.portfolioData.categories.allocation_data !== undefined && props.portfolioData.categories.allocation_data.slice(0, props.showAllCategories ? undefined : 5).map((item, index) => {
                                    const dot_color = props.portfolioData.categories.graph_data.find(graph => graph.asset_class === item.asset_class);
                                    return (
                                        <div className={`py-sm-3 py-2 ${index > 0 ? 'border-top-point5px border-onyx' : ''}`}>
                                            <div className={`row align-items-center row-gap-12px`}>
                                                <div className="col-5 d-flex align-items-center gap-4px">
                                                    <span style={{ background: dot_color?.color }}
                                                        className="d-block rounded-circle w-10px h-10px "></span>
                                                    <p className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-medium mb-0">{item.name}</p>
                                                </div>
                                                <div className="col">
                                                    <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.current_amount_text}</span>
                                                </div>
                                                <div className="col text-sm-center text-end">
                                                    <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.allocation}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {props.portfolioData.categories.allocation_data.length > 5 && <div className="col-12 text-center"> <LinkButton label={props.showAllCategories ? "Show less" : "View more"}
                                className="e-font-14 e-line-height-18 "
                                onPress={() => props.setShowAllCategories()} />
                            </div>}

                        </div>
                        :
                        <EmptyScreen className="mt-5 pb-5"
                            title={"No Categories Found"}
                            image={require("../../Assets/Images/Global/empty-funds.png")}
                            width={120}
                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                    :
                    Object.keys(props.portfolioData.categories).length > 0 ?
                        <div className="pb-2">
                            <div className="py-2 border-bottom-1px border-chinese-white mb-sm-3 mb-1">
                                <HorizontalStackedBar height="h-28px" allocations={fundAllocationData} />
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Fund name</span>
                                </div>
                                <div className="col text-center">
                                    <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                </div>
                                <div className="col text-sm-center text-end">
                                    <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Invested</span>
                                </div>
                            </div>
                            {
                                props.portfolioData.funds.allocation_data !== undefined && props.portfolioData.funds.allocation_data.slice(0, props.showAllFunds ? undefined : 5).map((item, index) => {

                                    return (
                                        <div className={`py-sm-3 py-2 cursor-pointer ${index > 0 ? 'border-top-point5px border-onyx' : ''}`} onClick={() => {
                                            _handleNavigateToFund(item.mf_id)
                                        }}>
                                            <div className={`row row-gap-12px align-items-center`}>
                                                <div className="col-5 d-flex align-items-center gap-4px">
                                                    <div className="color-eerie-black e-font-14 e-line-height-18 e-montserrat-bold d-flex align-items-center gap-4px ">
                                                        {item.mf_name}
                                                    </div>
                                                </div>
                                                <div className="col text-center">
                                                    <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.current_amount_text}</span>
                                                </div>
                                                <div className="col text-sm-center text-end">
                                                    <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.current_amount_text}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {props.portfolioData.funds.allocation_data.length > 5 && <div className="col-12 text-center"> <LinkButton label={props.showAllFunds ? "Show less" : "View more"}
                                className="e-font-14 e-line-height-18 "
                                onPress={() => props.setShowAllFunds()} />
                            </div>}

                        </div>
                        :
                        <EmptyScreen className="mt-5 pb-5"
                            title={"No Funds Found"}
                            image={require("../../Assets/Images/Global/empty-funds.png")}
                            width={120}
                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />

        )
    }

    return (
        filterLoader === true ?
            <Loader height="h-100vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    {/* Header */}
                    <Header type={2} />

                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">
                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Portfolio"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <h5 className="margin-30px-tb e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green">
                                    Portfolio
                                </h5>
                                {

                                    <div className="row">
                                        <div className="col-xl-8 col-lg-8">
                                            <div className="box-shadow-black border-radius-24px">
                                                <div className="d-flex flex-wrap row-gap-8px align-items-center justify-content-between px-sm-4 px-3 py-sm-3 py-2 border-bottom-1px border-gainsboro">
                                                    <h6 className="color-eerie-black e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">My Portfolio</h6>
                                                    <div className="d-flex e-bg-lavender-frost border-radius-30px p-1">
                                                        {
                                                            FUND_TYPES.map((item, index) => {
                                                                return (
                                                                    <span key={index}
                                                                        className={`padding-6px-tb px-3 e-font-14 e-montserrat-medium e-line-height-20 color-eerie-black cursor-pointer ${item.id === fundType ? "box-shadow-36 border-radius-28px bg-white" : ""}`}
                                                                        onClick={() => _getPortfolioDetails(item.id)}>
                                                                        {item.label}
                                                                    </span>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                                {
                                                    pageLoader === true ?
                                                        <Loader height="h-60vh" />
                                                        :
                                                        fundType === 1 ?
                                                            Object.keys(portfolioData).length === 0 ?
                                                                <EmptyScreen className="mt-5 pb-5"
                                                                    title="No portfolio"
                                                                    width={150}
                                                                    image={require("../../Assets/Images/Portfolio/internal.png")}
                                                                    description="You haven't made any investments, so there is no portfolio summary to view." />
                                                                :
                                                                <div className="pt-3 px-sm-4 px-3">
                                                                    <div className="border-bottom-1px border-chinese-white d-sm-block d-none">
                                                                        <CustomTabBar data={INVESTMENT_TYPES}
                                                                            selectedTab={investmentType}
                                                                            onSelectTab={_handleInvestmentType} />
                                                                    </div>

                                                                    <div className="d-sm-none d-flex justify-content-end">
                                                                        <CustomSelectBox options={MOBILE_INVESTMENT_TYPES}
                                                                            value={selectedInvestmentType}
                                                                            type={2}

                                                                            isSearchable={false}

                                                                            onSelectChange={(select_value) => {
                                                                                setSelectedInvestmentType(select_value);
                                                                                setInvestmentType(select_value.value);
                                                                            }} />
                                                                    </div>

                                                                    <PortfolioDetails investmentType={investmentType}
                                                                        portfolioData={portfolioData}
                                                                        showAllFunds={showAllFunds}
                                                                        showAllCategories={showAllCategories}
                                                                        setShowAllFunds={() => setShowAllFunds(!showAllFunds)}
                                                                        setShowAllCategories={() => setShowAllCategories(!showAllCategories)} />

                                                                </div>
                                                            :
                                                            Object.keys(externalPortfolioData).length === 0 ?
                                                                <EmptyScreen className="mt-5 pb-5"
                                                                    title="No portfolio"
                                                                    width={150}
                                                                    image={require("../../Assets/Images/Portfolio/internal.png")}
                                                                    description="You haven't made any investments, so there is no portfolio summary to view." />
                                                                :
                                                                <Fragment>
                                                                    {

                                                                        showFunds === true ?
                                                                            <div className="px-sm-4 px-3 pb-3">
                                                                                <div className="pt-3">
                                                                                    <div className="border-bottom-1px border-chinese-white d-sm-block d-none">
                                                                                        <CustomTabBar data={INVESTMENT_TYPES}
                                                                                            selectedTab={externalInvestmentType}
                                                                                            onSelectTab={_handleInvestmentType} />
                                                                                    </div>

                                                                                    <div className="d-sm-none d-flex justify-content-end">
                                                                                        <CustomSelectBox options={MOBILE_INVESTMENT_TYPES}
                                                                                            value={selectedExternalInvestmentType}
                                                                                            type={2}

                                                                                            isSearchable={false}

                                                                                            onSelectChange={(select_value) => {
                                                                                                setSelectedExternalInvestmentType(select_value);
                                                                                                setExternalInvestmentType(select_value.value);
                                                                                            }} />
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    Object.keys(externalPortfolioData.funds).length > 0 &&
                                                                                    <PortfolioDetails investmentType={externalInvestmentType}
                                                                                        portfolioData={externalPortfolioData}
                                                                                        showAllFunds={showAllFundsExternal}
                                                                                        showAllCategories={showAllCategoriesExternal}
                                                                                        setShowAllFunds={() => setShowAllFundsExternal(!showAllFundsExternal)}
                                                                                        setShowAllCategories={() => setShowAllCategoriesExternal(!showAllCategoriesExternal)} />
                                                                                }
                                                                                {
                                                                                    Object.keys(externalPortfolioData.funds).length > 0 ?
                                                                                        refreshHoldings === true ?
                                                                                            <Fragment>
                                                                                                <button type="submit" className="e-customize-btn e-font-14 e-line-height-16 e-montserrat-semi-bold padding-10px-tb padding-12px-lr d-flex gap-8px align-items-center mx-auto mt-4 "
                                                                                                    onClick={() => _handleResync()}>
                                                                                                    <img src={require("../../Assets/Images/Portfolio/resync.svg").default}
                                                                                                        draggable={false}
                                                                                                        alt="sync" />
                                                                                                    Refresh Holdings
                                                                                                </button>
                                                                                                <p className="color-black e-font-14 e-line-height-16 e-montserrat-regular text-center mb-0 mt-2">Last update : {externalPortfolioData.external_portfolio_imported_on}</p>

                                                                                            </Fragment>
                                                                                            :
                                                                                            null
                                                                                        :
                                                                                        <div className={`color-vivid-orange e-bg-ivory-bridge d-flex align-items-start padding-12px-all border-radius-12px e-font-14 e-line-height-20 gap-8px e-montserrat-regular`}>
                                                                                            <Icon icon="info"
                                                                                                size={20} />
                                                                                            Your external funds are being imported. Funds will appear  within 10-15 mins.
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            (portfolioStatus === "PENDING" || showFunds === true) ?
                                                                                <div className="padding-60px-tb px-4 d-flex justify-content-center text-center flex-column">
                                                                                    <div className="row d-flex justify-content-center">
                                                                                        <div className="col-lg-6">
                                                                                            <img src={require("../../Assets/Images/Portfolio/impot-funds.png")}
                                                                                                alt="Maximum growth potential"
                                                                                                draggable={false}
                                                                                                width={104}
                                                                                                className="img-fluid" />


                                                                                            <p className="e-montserrat-medium e-font-14 e-line-height-24 color-davy-gray mb-3">
                                                                                                Your external funds are being imported. Funds will appear  within 10-15 mins.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="padding-60px-tb px-4 d-flex justify-content-center text-center flex-column">
                                                                                    <div className="row d-flex justify-content-center">
                                                                                        <div className="col-lg-6">
                                                                                            <img src={require("../../Assets/Images/Portfolio/impot-funds.png")}
                                                                                                alt="Maximum growth potential"
                                                                                                draggable={false}
                                                                                                width={104}
                                                                                                className="img-fluid" />

                                                                                            <p className="e-montserrat-semi-bold e-font-18 e-line-height-24 mb-2 mt-4 color-eerie-black">
                                                                                                Import external funds
                                                                                            </p>
                                                                                            <p className="e-montserrat-medium e-font-14 e-line-height-24 color-davy-gray mb-3">
                                                                                                Manage and analyze all your mutual fund
                                                                                                investment using a single dashboard.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>


                                                                                    <PrimaryButton label="Import Now"
                                                                                        className="padding-10px-tb padding-34px-lr w-max-content mx-auto"
                                                                                        onPress={() => _handleImport(true)} />
                                                                                </div>
                                                                    }
                                                                </Fragment>
                                                }
                                            </div>



                                            {
                                                holdingOptions.length !== 0 && overviewOptions.length !== 0 &&
                                                <Fragment>
                                                    {processingAmountStatus === false && Object.keys(portfolioData).length > 0 && <div className="mt-sm-4 mt-3 box-shadow-black border-radius-24px p-3 position-relative">
                                                        <div className="d-flex align-items-center gap-4px p-sm-2 pe-sm-2 pe-2 p-1 e-bg-old-lace border-1px border-light-orange e-font-14 e-line-height-18 e-montserrat-medium color-gamboge  border-radius-40px w-max-content">
                                                            <Icon icon="processing"
                                                                size={20}
                                                                className='d-sm-block d-none ' />
                                                            <Icon icon="processing"
                                                                size={18}
                                                                className="d-sm-none d-block" />
                                                            Processing Amount
                                                        </div>
                                                        <p className={`color-emerald-green e-font-20 letter-spacing-02em e-line-height-24 e-montserrat-semi-bold pt-3 mb-0`}>
                                                            {portfolioData.total_investment.processing_amount} <span className="e-font-14 color-davy-gray font-style-italic e-montserrat-regular">(Will get invested in T+3 days)</span>
                                                        </p>
                                                        <Icon icon="close" className="position-absolute top-16px right-16px cursor-pointer d-sm-block d-none"
                                                            size={32} onClick={_handleProcessingAmount} />
                                                        <Icon icon="close" className="position-absolute top-16px right-16px cursor-pointer d-sm-none d-block"
                                                            size={24} onClick={_handleProcessingAmount} />
                                                    </div>
                                                    }
                                                    <div className="mt-sm-4 mt-3 box-shadow-black border-radius-24px py-sm-4 py-3">
                                                        <div className="px-sm-4 px-3 pb-sm-4 pb-3 border-bottom-1px border-chinese-white d-flex justify-content-between align-items-center">
                                                            <h6 className="color-eerie-black e-font-16 e-line-height-20 e-montserrat-semi-bold margin-2px-tb">Holding Overview</h6>
                                                            {fundType === 1 && <div className="d-sm-none d-flex justify-content-end">
                                                                <CustomSelectBox options={mobileOverviewOptions}
                                                                    value={selectedHoldingType}
                                                                    type={2}
                                                                    isSearchable={false}
                                                                    onSelectChange={(select_value) => {
                                                                        setSelectedHoldingType(select_value);
                                                                        setHoldingType(select_value.value);
                                                                    }} />
                                                            </div>}
                                                        </div>
                                                        <div className="px-sm-4 px-3">
                                                            {fundType === 1 && <div className="border-bottom-1px border-chinese-white pt-2 d-sm-block d-none">
                                                                <CustomTabBar data={overviewOptions}
                                                                    selectedTab={holdingType}
                                                                    onSelectTab={_handleHoldings}
                                                                />
                                                            </div>}


                                                            {
                                                                overviewLoader === true ?
                                                                    <Loader height="h-60vh" />
                                                                    :
                                                                    Object.keys(holdingsOverview).length > 0 ?

                                                                        <div className="pt-md-4 pt-3">
                                                                            <div className="row row-gap-8px align-items-start">
                                                                                <div className="col-xl-6 order-xl-1 order-2">
                                                                                    {
                                                                                        holdingType === 1 && fundType === 1 &&
                                                                                        <div className="d-sm-block d-none">
                                                                                            <h6 className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0">Achieve</h6>
                                                                                            <p className={`color-emerald-green e-font-24  e-line-height-40 e-montserrat-semi-bold pt-md-2 pt-0 mb-md-3 mb-0`}>
                                                                                                {holdingsOverview.achieve_amount ? holdingsOverview.achieve_amount : 0} <span className="e-font-14 color-eerie-black e-montserrat-semi-bold">by {holdingsOverview.tenure}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        holdingsOverview.allocation_data.slice(0, showAllHoldings ? holdingsOverview.allocation_data.length : 4).map((item, index) => {
                                                                                            return (
                                                                                                <div key={index}
                                                                                                    className={`d-flex align-items-center e-font-14 e-line-height-22 e-montserrat-medium color-eerie-black mb-3 gap-8px ${fundType === 1 ? "cursor-pointer" : ""}`}
                                                                                                    onClick={() => {
                                                                                                        if (fundType === 1) {
                                                                                                            _handleNavigateDetail(item.investment_type, item.id)
                                                                                                        }
                                                                                                    }}>
                                                                                                    <div className="min-w-10px min-h-10px border-radius-100px" style={{ background: item.color }}></div>
                                                                                                    <div>
                                                                                                        {item.investment_type === 1 ? `Milestone : ${item.name}` : item.name} - <span className="e-montserrat-semi-bold">{item.allocation}%</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })

                                                                                    }
                                                                                    {holdingsOverview.allocation_data.length > 4 &&
                                                                                        <div className="col-12 text-center"> <LinkButton label={showAllHoldings ? "Show less" : "View more"}
                                                                                            className="e-font-14 e-line-height-18 "
                                                                                            onPress={() => setShowAllHoldings(!showAllHoldings)} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-xl-6 order-xl-2 order-1 d-flex justify-content-xl-start justify-content-lg-center justify-content-center flex-column ">
                                                                                    {
                                                                                        holdingType === 1 &&
                                                                                        <div className="d-sm-none d-block">
                                                                                            <h6 className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0">Achieve</h6>
                                                                                            <p className={`color-emerald-green e-font-24  e-line-height-40 e-montserrat-semi-bold pt-md-3 pt-0 mb-md-3 mb-0`}>
                                                                                                {holdingsOverview.achieve_amount ? holdingsOverview.achieve_amount : 0} <span className="e-font-14 color-eerie-black e-montserrat-semi-bold">by {holdingsOverview.tenure}</span>
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                    <DoughnutChart data={graphData}
                                                                                        total={holdingsOverview.invested_amount} />
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                        :
                                                                        <EmptyScreen className="mt-5 pb-5"
                                                                            title={
                                                                                holdingType === 1 ?
                                                                                    "No Investment Milestones" :
                                                                                    holdingType === 2 ?
                                                                                        "No Investment Funds" :
                                                                                        "No Investment Baskets"}
                                                                            image={holdingType === 1 ? require("../../Assets/Images/Portfolio/empty-milestone.png") :
                                                                                holdingType === 2 ? require("../../Assets/Images/Portfolio/empty-funds.png") :
                                                                                    require("../../Assets/Images/Portfolio/empty-basket.png")}
                                                                            width={120}
                                                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className={`row row-gap-8px d-flex align-items-center justify-content-between cursor-pointer mt-sm-4 mt-3`}>

                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Holdings</p>

                                                            {fundType === 1 && <Fragment>
                                                                <CustomSelectBox placeholder=""
                                                                    value={selected}
                                                                    options={holdingOptions}
                                                                    className="d-sm-block d-none"
                                                                    isSearchable={false}
                                                                    width={true}
                                                                    onSelectChange={_handleHoldingsTypes} />
                                                                <div className=" d-sm-none d-flex justify-content-end">

                                                                    <CustomSelectBox placeholder=""
                                                                        value={selected}
                                                                        options={holdingOptions}
                                                                        type={2}
                                                                        className=""
                                                                        isSearchable={false}
                                                                        onSelectChange={_handleHoldingsTypes} />
                                                                </div>
                                                            </Fragment>}
                                                        </div>

                                                    </div>
                                                    <div className="box-shadow-black border-radius-24px mt-3 overflow-hidden d-sm-block d-none">

                                                        {
                                                            holdingLoader === true ?
                                                                <Loader height="h-60vh" />
                                                                :
                                                                (selected != null && holdingsList.length === 0) ?
                                                                    <EmptyScreen className="mt-5 pb-5"
                                                                        title={selected.value === 1 ? "No Holdings found for Milestones" :
                                                                            selected.value === 2 ? "No Holdings found for Funds" :
                                                                                selected.value === 3 ? "No Holdings found for Baskets" : "No Holdings found"
                                                                        }
                                                                        width={150}
                                                                        image={selected.value === 1 ? require("../../Assets/Images/Portfolio/empty-milestone.png") :
                                                                            selected.value === 2 ? require("../../Assets/Images/Portfolio/empty-funds.png") :
                                                                                selected.value === 3 ? require("../../Assets/Images/Portfolio/empty-basket.png")
                                                                                    : require("../../Assets/Images/Global/empty-funds.png")}
                                                                        description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                                    :
                                                                    holdingsList.map((row, index) => {
                                                                        return (
                                                                            <Fragment>
                                                                                <div className={`${index === showHoldings ? "e-bg-purple-white pb-sm-4 pb-3" : "bg-white cursor-pointer"} ${index > 0 ? "border-top-1px border-chinese-white" : ""} px-sm-4 px-3 py-sm-4 py-3  d-sm-block d-none`}
                                                                                    onClick={() => _handleToggleHoldings(index)}>
                                                                                    <div className="row">
                                                                                        <div className="col-xl-5 col-12">
                                                                                            <div className="d-flex align-items-center gap-8px">
                                                                                                <img src={row.icon}
                                                                                                    alt={row.name}
                                                                                                    width={48}
                                                                                                    height={48}
                                                                                                    draggable={false}
                                                                                                    className="border-radius-12px object-fit-contain border-1px border-silver-sand d-sm-block d-none" />
                                                                                                <img src={row.icon}
                                                                                                    alt={row.name}
                                                                                                    width={38}
                                                                                                    height={38}
                                                                                                    draggable={false}
                                                                                                    className="border-radius-12px object-fit-contain border-1px border-silver-sand d-sm-none d-block" />
                                                                                                <p className="mb-0 padding-2px-top e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black">{row.investment_type === 1 ? `Milestone : ${row.name}` : row.name}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 pt-xl-0 pt-3">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Current</p>
                                                                                                <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{row.current_amount_text}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 pt-xl-0 pt-3">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Monthly SIP</p>
                                                                                                <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{row.monthly_sip ? row.monthly_sip : "-"}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6 pt-xl-0 pt-3 d-sm-block d-none">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Returns</p>
                                                                                                <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${row.returns_percentage > 0 ? 'color-green' : row.returns_percentage < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'}`}>{row.returns_amount_text}({row.returns_percentage_text})</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        index === showHoldings ?
                                                                                            <div className="row row-gap-8px pt-md-4 pt-2">
                                                                                                <div className="col-xl-5 col-lg-4 col-12"></div>
                                                                                                <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 d-sm-none d-block">
                                                                                                    <div className="d-block">
                                                                                                        <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Returns</p>
                                                                                                        <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20  mb-0 ${row.returns_percentage > 0 ? 'color-green' : row.returns_percentage < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'}`}>{row.returns_amount_text}({row.returns_percentage_text})</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6">
                                                                                                    <div className="d-block">
                                                                                                        <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Invested</p>
                                                                                                        <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{row.invested_amount_text}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                                                                                                    <div className="d-block">
                                                                                                        <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">XIRR</p>
                                                                                                        <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${row.xirr > 0 ? "color-emerald-green" : row.xirr < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}>{row.xirr_text}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>
                                                                                                {fundType === 1 && <div className="d-flex align-items-center justify-content-end border-top-1px border-onyx gap-16px pt-4 mt-4">
                                                                                                    <LinkButton label="View details"
                                                                                                        className="e-font-14"
                                                                                                        onPress={() => {
                                                                                                            _handleNavigateDetail(row.investment_type, row.id)
                                                                                                        }} />

                                                                                                    <SecondaryButton label="Redeem" className="padding-12px-tb padding-34px-lr"
                                                                                                        onPress={() => _handleRedeem(row)} />
                                                                                                    <div className="col--3">
                                                                                                        <PrimaryButton label="Invest more" className={"padding-12px-tb padding-32px-lr w-100"}
                                                                                                            // disabled={updateInvestLoader}
                                                                                                            onPress={() => _investMore(row)} />
                                                                                                    </div>
                                                                                                </div>}
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                        }
                                                    </div>

                                                    <div className="d-sm-none d-block  mt-3">

                                                        {
                                                            holdingLoader === true ?
                                                                <Loader height="h-60vh" />
                                                                :
                                                                (selected != null && holdingsList.length === 0) ?
                                                                    <div className="box-shadow-black border-radius-24px mt-3 overflow-hidden">
                                                                        <EmptyScreen className="mt-5 pb-5"
                                                                            title={selected.value === 1 ? "No Holdings found for Milestones" :
                                                                                selected.value === 2 ? "No Holdings found for Funds" :
                                                                                    selected.value === 3 ? "No Holdings found for Baskets" : "No Holdings found"
                                                                            }
                                                                            width={100}
                                                                            image={selected.value === 1 ? require("../../Assets/Images/Portfolio/empty-milestone.png") :
                                                                                selected.value === 2 ? require("../../Assets/Images/Portfolio/empty-funds.png") :
                                                                                    selected.value === 3 ? require("../../Assets/Images/Portfolio/empty-basket.png")
                                                                                        : require("../../Assets/Images/Global/empty-funds.png")}
                                                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                                    </div>
                                                                    :
                                                                    holdingsList.map((row, index) => {
                                                                        return (
                                                                            <Fragment>
                                                                                <div className={`border-1px border-chinese-white border-radius-24px mb-3 px-3 py-3 cursor-pointer d-sm-none d-block`}
                                                                                    onClick={() => _handleToggleHoldings(index)}>
                                                                                    <div className="row row-gap-4px">
                                                                                        <div className="col-12 mb-2">
                                                                                            <div className="d-flex align-items-center gap-8px">
                                                                                                <img src={row.icon}
                                                                                                    alt={row.name}
                                                                                                    width={48}
                                                                                                    height={48}
                                                                                                    draggable={false}
                                                                                                    className="border-radius-12px object-fit-contain border-1px border-silver-sand d-sm-block d-none" />
                                                                                                <img src={row.icon}
                                                                                                    alt={row.name}
                                                                                                    width={38}
                                                                                                    height={38}
                                                                                                    draggable={false}
                                                                                                    className="border-radius-12px object-fit-contain border-1px border-silver-sand d-sm-none d-block" />
                                                                                                <p className="mb-0 padding-2px-top e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black">{row.investment_type === 1 ? `Milestone : ${row.name}` : row.name}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Current</p>
                                                                                                <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{row.current_amount_text}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Monthly SIP</p>
                                                                                                <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{row.monthly_sip ? row.monthly_sip : "-"}</p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-4">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Returns</p>
                                                                                                <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20 color-green mb-0 ${row.returns_percentage > 0 ? 'color-green' : row.returns_percentage < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'}`}>{row.returns_amount_text} ({row.returns_percentage_text})</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Invested</p>
                                                                                                <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{row.invested_amount_text}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <div className="d-block">
                                                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">XIRR</p>
                                                                                                <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${row.xirr > 0 ? "color-emerald-green" : row.xirr < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}>{row.xirr_text}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        {fundType === 1 && <div className="d-flex align-items-center justify-content-end border-top-1px border-onyx gap-16px pt-3 mt-3">
                                                                                            <LinkButton label="View Details"
                                                                                                className="e-font-14"
                                                                                                onPress={() => {
                                                                                                    _handleNavigateDetail(row.investment_type, row.id)
                                                                                                }} />
                                                                                            <SecondaryButton label="Redeem" className="padding-12px-tb padding-24px-lr"
                                                                                                onPress={() => _handleRedeem(row)} />
                                                                                            <div className="col-4">
                                                                                                <PrimaryButton label="Invest more" className={"padding-12px-tb w-100"}
                                                                                                    // showLoader={updateInvestLoader === row.id ? true : false}
                                                                                                    // disabled={updateInvestLoader === row.id ? true : false}
                                                                                                    onPress={() => _investMore(row)} />
                                                                                            </div>
                                                                                        </div>}
                                                                                    </div>

                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                        }
                                                    </div>
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    fundInvestMore !== null &&
                    <FundInvestModal isReinvest={true}
                        selectedDate={selectedDate}
                        selectedDateString={selectedDateString}
                        isFirstInstallment={1}
                        summary={mfSummary}
                        show_info={true}
                        closeModal={_handleCloseInvestMoreModal}
                        checkStatus={() => _getMFSummary(fundInvestMore.mf_id, true)}
                        invested={() => _getHoldings(selected.value)} />
                }

                {
                    goalInvestMore !== null &&
                    <InvestMoreModal closeModal={_handleCloseInvestMoreModal}
                        isReinvest={true}
                        selectedDate={selectedDate}
                        selectedDateString={selectedDateString}
                        isFirstInstallment={2}
                        show_info={true}
                        summary={investmentSuggestion}
                        invested={() => _getHoldings(selected.value)}
                        checkStatus={() => _getInvestmentSuggestions(goalInvestMore.goal_id, true)} />
                }

                {
                    importFunds === true &&
                    <ImportExternalFundsModal
                        showFunds={showFunds}
                        show_button={true}
                        label="View Portfolio"
                        content={"Funds will appear on your dashboard in 10-15 mins. We will notify you once it is done."}
                        closeModal={() => {
                            _handleImport(false)
                        }}
                        success={_handleSuccess} /> // Simran needs to check this to "onSuccessfulImport"
                }

                {
                    mfRedeemData !== null &&
                    <FundRedemptionModal data={mfRedeemData}
                        closeModal={_handleCloseMfRedeemModal} />
                }

                {/* {
                    updateInvestMore !== null &&
                    <UpdateInvestmentModal data={updateInvestMore}
                        success={(data) => { }}
                        closeModal={_handleCloseUpdateModal} />
                } */}

                {
                    goalRedeemData !== null &&
                    <GoalRedemptionModal data={goalRedeemData}
                        type={goalRedeemData.investment_type}
                        closeModal={() => _showGoalRedeemModal(null)}
                        redeemSuccess={() => _getHoldings(selected.value)} />
                }
            </Fragment>
    )
}

export default Portfolio;