/*
 *   File : loader.js
 *   Author : https://evoqins.com
 *   Description : Page loader component
 *   Version : 1.0.0
*/

// import packages
const Loader = (props) => {
    return (
        <div className={`d-flex align-items-center justify-content-center ${props.height}`}>
            <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                alt={"loader"}
                width={props.size}
                height={props.size} />
        </div>
    )
}

Loader.defaultProps = {
    size: 60
}

export default Loader