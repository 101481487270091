/*
 *   File : nominee.js
 *   Author : https://evoqins.com
 *   Description : Nominee card component
 *   Integrations : null
 *   Version : 1
*/

import { Fragment } from "react";
import { differenceInYears } from "date-fns";

// import components
import { CustomDropdown } from "../Dropdown";
import { CustomDatePicker, CustomTextInput } from "../FormElements";
import { Icon } from "../Icon";



const NomineeCard = (props) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Define the disabled days rule
    const disabledDays = { after: yesterday };

    const IS_MINOR = differenceInYears(new Date(), new Date(props.dob)) < 18;

    return (
        <Fragment>
            <div className="row pt-4 position-relative">
                <div className="col-sm-5 pb-4">
                    <CustomTextInput label="Nominee holder name"
                        postfix="*"
                        value={props.name}
                        error={props.error[0]}
                        placeholder="Nominee name"
                        handleChange={(input_value) => props.handleNominee(input_value, 'name', 0)} />
                </div>
                <div className="col-sm-5 pb-4">
                    <CustomDropdown label="Nominee relationship"
                        postfix="*"
                        options={props.nominee_options}
                        value={props.relation}
                        message="No nominee found"
                        error={props.error[1]}
                        placeholder="Relation with nominee"
                        onSelectChange={(select_value) => props.handleNominee(select_value, 'relation', 1)} />
                </div>

            </div>
            <div className="row align-items-center">
                <div className="col-sm-5 pb-4">
                    <CustomDatePicker error={props.error[2]}
                        disabledDays={disabledDays}
                        label="Date of birth"
                        postfix={true}
                        start={props.dob}
                        mode="single"
                        selectDate={(date) => {
                            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

                            const start_date = new Date(date);

                            const formatted_start_date = start_date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
                            props.handleNominee(formatted_start_date, 'dob', 2)
                        }} />
                </div>
                <div className="col-sm-5 col-11 pb-4">
                    <CustomTextInput label="Allocation"
                        postfix="*"
                        value={props.allocation}
                        error={props.error[3]}
                        type="number"
                        placeholder="Allocation %"
                        handleChange={(input_value) => {
                            if (input_value <= 100) {
                                props.handleNominee(input_value, 'allocation', 3)
                            }
                        }} />
                </div>
                {IS_MINOR && <Fragment>
                    <div className="col-sm-5 pb-4">
                        <CustomTextInput label="Guardian name"
                            postfix="*"
                            value={props.guardian_name}
                            error={props.error[4]}
                            placeholder="Name"
                            handleChange={(input_value) => {
                                props.handleNominee(input_value, 'guardian_name', 4)
                            }} />
                    </div>
                    <div className="col-sm-5 col-11 pb-4">
                        <CustomTextInput label="Guardian PAN Number"
                            postfix="*"
                            value={props.guardian_pan}
                            error={props.error[5]}
                            placeholder="PAN"
                            inputClass="text-transform-upper"
                            handleChange={(input_value) => props.handleNominee(input_value, 'guardian_pan', 5)} />
                    </div>
                    <div className="col-sm-5 pb-4">
                        <CustomDropdown label="Guardian relationship"
                            postfix="*"
                            options={props.guardian_options}
                            value={props.guardian_relation}
                            error={props.error[6]}
                            message="No guardian found"
                            placeholder="Guardian relationship"
                            onSelectChange={(select_value) => props.handleNominee(select_value, 'guardian_relation', 6)} />
                    </div>
                </Fragment>}
                {props.total > 1 && <div className="col-sm-1 col-1">
                    <Icon icon="close"
                        size={20}
                        className='cursor-pointer'
                        onClick={props.removeNominee} />
                </div>}

            </div>
        </Fragment>
    )
}

export default NomineeCard