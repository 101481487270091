import axios from "axios";
import { _logout } from "../Helper/helper";

export default async function APIService(is_post, url, data, is_auth) {
    let API_URL = process.env.REACT_APP_API_URL + url;
    const authToken = is_auth ? is_auth : sessionStorage.getItem('mintit_access_token');

    if (is_post === true) {
        return await axios
            .post(API_URL, data, {
                headers: {
                    method: "post",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        _logout();
                    }
                    return error.response.data;
                } else if (error.request) {
                    console.log("error.request", error.request.url);
                }
            });
    } else {
        return await axios
            .get(API_URL, {
                headers: {
                    method: "get",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        _logout();
                    }
                    return error.response.data;
                }
            });
    }
}
