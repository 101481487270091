/*
 *   File : auth.js
 *   Author : https://evoqins.com
 *   Description : Slider component
 *   Version : 1.0.0
*/

// import packages
import { Fragment, memo, useEffect, useRef, useState } from "react";
import Slider from "react-slick";


const SLIDER_DATA = [
    {
        banner: require("../../Assets/Images/Slider/slider-img-1.webp"),
        title: "Welcome to MINTIT",
        description: "Better your Investment journey. Simplified, Personalised and Collaborated."
    },
    {
        banner: require("../../Assets/Images/Slider/slider-img-2.webp"),
        title: " Build Your Financial Pyramid",
        description: "Achieve your milestones with a solid foundation and smart investments."
    },
    {
        banner: require("../../Assets/Images/Slider/slider-img-3.webp"),
        title: "Explore & Collaborate",
        description: "Discover smart investment options, track your progress and collaborate portfolios within family."
    },
    {
        banner: require("../../Assets/Images/Slider/slider-img-4.webp"),
        title: "Learn & Grow",
        description: "Expand your financial knowledge and make informed decisions with our educational resources."
    }
]


const AuthSlider = () => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);

    return (
        <Fragment>
            <Slider slidesToShow={1}
                slidesToScroll={1}
                autoplay={true}
                fade={true}
                autoplaySpeed={3000}
                dots={true}
                arrows={false}
                className="e-auth-slider"
                asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
                {
                    SLIDER_DATA.map((item, key) => {
                        return (
                            <img key={key} src={item.banner}
                                alt="banner"
                                draggable={false}
                                className="e-slider-image" />
                        )
                    })
                }

            </Slider>
            <Slider slidesToShow={1}
                slidesToScroll={1}
                asNavFor={nav1}
                autoplaySpeed={3000}
                fade={true}
                autoplay={true}
                arrows={false}
                ref={slider => (sliderRef2 = slider)}>
                {
                    SLIDER_DATA.map((item) => {
                        return (
                            <Fragment>
                                <h6 className="text-center color-eerie-black e-montserrat-bold e-font-18 e-line-height-24 mb-2">{item.title}</h6>
                                <p className="e-font-14 e-line-height-24 tex-center color-dark-gray e-montserrat-medium text-center">{item.description}</p>
                            </Fragment>
                        )
                    })
                }

            </Slider>

        </Fragment>
    )
}

export default memo(AuthSlider)