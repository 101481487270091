/*
 *   File : configure-goal.js
 *   Author : https://evoqins.com
 *   Description : Modal component configured to show configure milestone immediately after completing the "RiskScore" step.
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect } from "react";
import { Icon } from "../Icon";

// import components
import { PrimaryButton, TertiaryButton } from "../Buttons";

// import styles
import style from "../../Styles/Components/select-career.module.scss"

const ConfigureGoalModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("configure-goal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    const _handleNavigate = () => {
        props.closeModal();
    }

    const _navigateHome = () => {
        props.home();
    }

    return (
        <div className={`modal fade ${style.e_select_career_modal}`}
            id="configure-goal"
            tabIndex="-1"
            aria-labelledby="configure-goal"
            aria-hidden="true"
            data-bs-backdrop="true">

            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content ${style.e_content} `}>

                    <Fragment>
                        <div className="px-4 position-relative">
                            <div className="d-flex justify-content-center">
                                <img src={require("../../Assets/Images/risk-assessment/configure-goal.png")}
                                    alt="Select-type"
                                    width={114}
                                    draggable={false} />
                            </div>
                            <p className="e-montserrat-semi-bold e-font-18 e-line-height-28 color-eerie-black mb-2 text-center">Align your Milestones!</p>
                            <p className="e-montserrat-medium e-font-14 e-line-height-20 color-quartz mb-sm-3 mb-2 text-center">Setting dream-oriented investment goals</p>
                            <ul className="margin-14px-bottom d-flex flex-column mb-sm-3 mb-2 padding-24px-left">
                                <li className="e-montserrat-regular e-font-14 e-line-height-22 color-black-olive"><span className="e-montserrat-bold dark-charcoal">Milestone Alignment:</span> Aligning investments with financial goals.</li>
                                <li className="e-montserrat-regular e-font-14 e-line-height-22 color-black-olive"><span className="e-montserrat-bold dark-charcoal">Long-Term Planning:</span>  Enhances planning, shaping strategy for achieving financial goals.</li>
                            </ul>
                            <Icon icon="close"
                                data-bs-dismiss="modal"
                                size={24}
                                className="position-absolute right-24px top-24px cursor-pointer"
                                onClick={_handleNavigate} />
                        </div>
                        <div className="e-bg-lavender-web pe-4 mb-4 padding-10px-tb d-flex align-items-center gap-4px">
                            <img src={require("../../Assets/Images/risk-assessment/configure-goal.gif")}
                                alt="Flower gif"
                                width={64}
                                height={64}
                                className={style.e_flower}
                                draggable={false} />
                            <p className="e-montserrat-medium e-font-14 e-line-height-22 mb-0 color-regalia">Let's create a purpose for your investments.</p>
                        </div>
                        <div className="d-flex gap-12px mx-4 mb-4">
                            <TertiaryButton className="padding-12px-tb padding-52px-lr border-radius-12px"
                                label="Skip"
                                cancel="modal"
                                onPress={_navigateHome} />
                            <PrimaryButton className="padding-12px-tb w-100 border-radius-12px"
                                label="Create Milestone"
                                cancel="modal"
                                onPress={_handleNavigate} />
                        </div>
                    </Fragment>

                </div>
            </div>
        </div>
    )
}

export default ConfigureGoalModal