/*
*   File : summary.js
*   Author : https://evoqins.com
*   Description : Screen to show steps and documents Involved in KYC flow 
*   Version : 1.0.0
*/

// import packages
import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { Link, useNavigate } from "react-router-dom";

// import components
import { KYCProgressCard } from "../../../Components/Card";
import { PrimaryButton } from "../../../Components/Buttons";
import { EsignCreateModal, KYCConfirmationModal } from "../../../Components/Modal";
import { Icon } from "../../../Components/Icon";


const Summary = (props) => {
    const navigate = useNavigate();
    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);
    const PROFILE_DATA = useSelector(store => store.Reducer.PROFILE_DETAILS);

    const [esignModal, setEsignModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [kycStatus, setKycStatus] = useState("");


    useEffect(() => {
        setKycStatus(PROGRESS_DATA.kyc_data.status);
    }, [PROGRESS_DATA]);

    useEffect(() => {
        if (showSuccess === true) {
            const modal = new Modal(document.getElementById("confirm"));
            modal.show();
        }
    }, [showSuccess]);

    useEffect(() => {
        if (esignModal === true) {
            const modal = new Modal(document.getElementById("create-esign"));
            modal.show();
        }
    }, [esignModal]);

    function _handleNavigation() {
        if (PROGRESS_DATA.kyc_data.status === "KYC_ESIGN_REQUIRED") {
            _initiateEsign(true);
        } else {
            props.handleNavigate();
        }
    }

    // handle confirm investment
    function _confirmInvestment(status) {
        setShowSuccess(status);
    }

    // handle esign modal
    function _initiateEsign(status) {
        setEsignModal(status);
    }
    return (
        <Fragment>

            {
                props.errors.length > 0 && kycStatus === "KYC_REJECTED" &&
                <Fragment>
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="color-eerie-black e-font-16 e-line-height-24 e-montserrat-semi-bold">
                                KYC application rejection
                            </h6>
                            <h6 className="color-eerie-black e-font-14 e-line-height-24 e-montserrat-semi-bold">
                                Sorry, we can not proceed with your
                                application
                            </h6>
                            <div className="border-1px border-light-salmon-pink border-radius-16px e-bg-error-red p-3">
                                <div className="d-flex align-items-center gap-8px color-eerie-black e-montserrat-semi-bold e-font-12 e-line-height-20">
                                    <Icon icon="info-red"
                                        size={16} />
                                    Why was my application not approved?
                                </div>

                            </div>
                            <div className="d-flex align-items-start padding-12px-all mt-2 e-bg-light-blue border-radius-12px mt-4 color-ocean-blue e-font-14 e-line-height-20 gap-8px e-montserrat-medium">
                                You still need help? Call us: <Link to="tel:+918956915040" className="color-rebecca-purple">+91-8956915040</Link> or write us <Link to="mailto:helpdesk@mintit.in" className="color-rebecca-purple">helpdesk@mintit.in</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </Fragment>
            }
            {
                kycStatus !== "KYC_REJECTED" &&
                <div className="row pb-md-0 pb-3">
                    <div className="col-md-6">
                        <div className="d-flex align-items-start py-2 px-3 mt-2 e-bg-mint-cream border-1px border-eucalyptus border-radius-16px color-ebony e-font-14 e-line-height-24 letter-spacing-02em gap-4px e-montserrat-medium">
                            <img src={PROGRESS_DATA.kyc_user === true ? require("../../../Assets/Images/KYC/kyc.svg").default : require("../../../Assets/Images/KYC/non-kyc.svg").default}
                                alt="Kyc-user" />
                            <div className="d-flex flex-column">
                                <span className="e-montserrat-semi-bold">You are a {PROGRESS_DATA.kyc_user === true ? "KYC" : "non-KYC"} user</span>
                                <span className="e-font-12">Current KYC status: {PROFILE_DATA.kyc?.status_text}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="row pt-md-3 pt-0">

                <div className="col-lg-6">
                    <KYCProgressCard steps={props.investment_steps}
                        completedStep={props.completedStep} />
                    <div className="my-md-4 my-3 border-top-1px border-bright-gray"></div>
                    <div className="d-sm-block d-flex justify-content-center">
                        <PrimaryButton label={kycStatus === "KYC_REJECTED" ? "Update & Re-submit" : "Continue"}
                            className="padding-12px-tb padding-52px-lr "
                            onPress={_handleNavigation} />
                    </div>
                </div>
            </div>
            {
                showSuccess &&
                <KYCConfirmationModal type={1}
                    label="Dashboard"
                    show_button={true}
                    title="Congratulations"
                    content="The account creation process has been initiated. We will notify over mail or notification once it is completed"
                    closeModal={() => _confirmInvestment(false)}
                    submitInvestment={() => _confirmInvestment(true)}
                    handleNavigate={() => navigate("/")} />
            }

            {
                esignModal === true &&
                <EsignCreateModal
                    closeModal={() => _initiateEsign(false)}
                    submitInvestment={() => _confirmInvestment(true)}
                    reSubmit={() => _initiateEsign(false)}
                />
            }
        </Fragment>
    )
}

export default Summary
