/*
*   File : verify-email.js
*   Author : https://evoqins.com
*   Description : Screen to verify Email 
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// import components
import { LinkButton, PrimaryButton } from "../../../Components/Buttons";
import { Icon } from "../../../Components/Icon";
import { EmailConfirmationModal, VerifyEmailModal } from "../../../Components/Modal";
import { StepProgress } from "../../../Components/Other";
import { KYCErrorCard } from "../../../Components/Cards";

// import services
import APIService from "../../../Services/api-service";
import { _getSignupProgress } from "../../../Helper/api";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const VerifyEmail = (props) => {

    const PROGRESS_DATA = useSelector(state => state.Reducer.SIGNUP_PROGRESS);

    const [showEmail, setShowEmail] = useState(false);

    const [emailStatus, setEmailStatus] = useState(false);

    const [apiLoader, setApiLoader] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [email, setEmail] = useState(false);
    const [kycUser, setKycUser] = useState(false);

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const signup_progress = PROGRESS_DATA;
        setIsEmailVerified(signup_progress.kyc_data.is_email_verified);
        setEmail(signup_progress.kyc_data.email);
        setKycUser(signup_progress.kyc_data.is_kyc_compliant);

        if (signup_progress.step_detail[1].error === true) {
            setErrors(signup_progress.step_detail[1].message);
        }
    }, [PROGRESS_DATA]);

    // callback top open email modal
    useEffect(() => {
        if (showEmail === true) {
            const modal = new Modal(document.getElementById("verify-email"));
            modal.show();
        }
    }, [showEmail]);

    // callback success of verify email
    useEffect(() => {
        if (emailStatus === true) {
            const modal = new Modal(document.getElementById("email-confirmation"));
            modal.show();
        }
    }, [emailStatus]);

    // verify email modal
    function _handleEmailModal(status) {
        setShowEmail(status)
    }

    // open success modal 
    function _handleConfirm(status) {
        setEmailStatus(status);
        if (status === false) {
            _getSignupProgress().then((response) => {
                props.handleNavigate()
            })

        }
    }

    // API - get Email OTP 
    const _getEmailOtp = async (email, type) => {
        if (type === null) {
            setApiLoader(true);
        }
        let status = false;

        const url = "/kyc/send-otp";

        const request = {
            email: email,
            type: type
        }

        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                if (type === null) {
                    toast.success("OTP sent successfully", {
                        type: "success"
                    })
                } else {
                    toast.success("OTP Resent successfully", {
                        type: "success"
                    })
                }
                status = true;
            } else {
                toast.error(response.message, {
                    type: "error"
                })
                status = false;
            }
            if (type === null) {
                setApiLoader(false);
            }

        })
        return status

    }

    // API - verify Email OTP 
    const _verifyOtp = async (otp) => {
        setApiLoader(true)
        let status = false;

        const url = "/kyc/verify-email-otp";

        const request = {
            email_otp: otp
        }

        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("OTP verified", {
                    type: "success"
                })
                status = true;
            } else {
                toast.error(response.message, {
                    type: "error"
                })
                status = false;
            }
            setApiLoader(false);

        })
        return status;
    }

    const _handleSuccess = async (response) => {
        try {
            // Ensure the response contains the authorization code
            const code = response.code;
            if (!code) {
                throw new Error('Authorization code not received');
            }

            // Exchange the authorization code for tokens
            const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    code: code,
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
                    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
                    grant_type: 'authorization_code',
                }),
            });

            const tokenData = await tokenResponse.json();
            if (tokenData.error) {
                throw new Error(tokenData.error_description);
            }

            const { id_token } = tokenData;
            if (typeof id_token !== 'string') {
                throw new Error('Invalid ID token');
            }

            // Decode the ID token
            const decodedToken = jwtDecode(id_token);

            // Format user data
            const formattedData = {
                email: decodedToken.email,
                id: decodedToken.sub,
            };

            // Call the success callback with the token and formatted data
            _verifyGoogle(id_token, formattedData);
        } catch (error) {
            console.error("Error during Google login:", error);
            props.failed(error);
        }
    };

    const _handleFailure = (error) => {
        props.failed(error);
    };

    const login = useGoogleLogin({
        onSuccess: _handleSuccess,
        onError: _handleFailure,
        flow: 'auth-code',
    });

    // API - verify email google
    const _verifyGoogle = (id_token, formattedData) => {
        const url = "/kyc/verify-email-google";

        const request = {
            "idToken": id_token,
            "user": {
                "id": formattedData.id
            }
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setEmail(formattedData.email);
                _handleEmailModal(true)
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }

    return (
        <Fragment>
            <Icon icon="Arrow-Right"
                size={32}
                className="margin-12px-tb rotate-left cursor-pointer"
                onClick={props.navigateBack} />
            <div className="row pt-md-3 pt-0">

                <div className="col-md-6">
                    <StepProgress type={2}
                        step={props.completed}
                        stepNumber={2}
                        maxStep={kycUser ? 6 : 8} />
                    {
                        isEmailVerified === true ?
                            <Fragment>
                                <h6 className="color-eerie-black e-font-20 e-line-height-32 e-montserrat-semi-bold mb-2 mt-4">
                                    Your Email has already verified!
                                </h6>
                                <p className="color-gunmental e-font-16 e-line-height-32 e-montserrat-regular mb-0">
                                    Your email has already been verified. You can proceed with the next steps of your investment process.
                                </p>
                                {
                                    errors.length > 0 &&
                                    <KYCErrorCard errors={errors} />
                                }
                                <div className="e-font-14 color-dark-charcoal e-montserrat-medium e-line-height-22 border-point5px e-bg-cultured border-silver-sand border-radius-8px p-3 mb-2 mt-3 d-flex justify-content-between box-shadow36">
                                    {email}
                                    <img src={require('../../../Assets/Images/KYC/verified.png')}
                                        alt="verified"
                                        draggable={false}
                                        width={20}
                                        height={20} />
                                </div>
                                <div className="d-flex align-items-center justify-content-end gap-4px" onClick={() => setIsEmailVerified(false)}>
                                    <Icon icon="edit" size={20} className="cursor-pointer"
                                        onClick={() => { }} />
                                    <LinkButton label="Edit email"
                                        className="e-font-14 e-line-height-30"
                                    />
                                </div>

                                <div className="border-top-1px border-bright-gray margin-32px-top mb-4"></div>
                                <div className="col-xl-3 col-lg-5 col-md-5 d-md-block d-none">
                                    <PrimaryButton label="Proceed"
                                        className="px-3 padding-12px-tb w-100"
                                        width={20}
                                        height={20}
                                        onPress={props.handleNavigate} />

                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <h6 className="color-eerie-black e-font-20 e-line-height-32 e-montserrat-semi-bold mb-2 mt-4">Verify your mail</h6>
                                <p className="color-gunmental e-font-16 e-line-height-32 e-montserrat-regular margin-32px-bottom">
                                    Link your email address with your account to receive all your transactional statements and latest updates.
                                </p>

                                <div className="border-top-1px border-bright-gray margin-32px-top mb-4"></div>
                                <div className="d-md-flex d-none align-items-center flex-wrap gap-16px">
                                    <PrimaryButton label="Verify with google"
                                        className="px-3 padding-12px-tb"
                                        image={require("../../../Assets/Images/KYC/google-logo.png")}
                                        width={20}
                                        height={20}
                                        onPress={login} />
                                    <LinkButton label="Verify email manually"
                                        className="e-font-14 e-line-height-30"
                                        onPress={() => _handleEmailModal(true)} />
                                </div>
                            </Fragment>
                    }
                </div>
                <div className="col-md-5 offset-md-1 position-relative">
                    <img src={require("../../../Assets/Images/KYC/mail.gif")}
                        alt="PAN"
                        draggable={false}
                        className="w-100 h-auto border-radius-16px box-shadow-36 border-1px border-light-purple" />

                    {isEmailVerified ?
                        <div className="col-sm-12 d-md-none d-flex justify-content-center mt-4">
                            <PrimaryButton label="Proceed"
                                className="padding-12px-tb padding-54px-lr"
                                width={20}
                                height={20}
                                onPress={props.handleNavigate} />

                        </div>
                        :
                        <div className="d-md-none d-flex justify-content-center flex-column align-items-center flex-wrap gap-16px mt-3">
                            <PrimaryButton label="Sign in with google"
                                className="px-3 padding-12px-tb"
                                image={require("../../../Assets/Images/KYC/google-logo.png")}
                                width={20}
                                height={20}
                            />
                            <LinkButton label="Verify email manually"
                                className="e-font-14 e-line-height-30"
                                onPress={() => _handleEmailModal(true)} />
                        </div>}
                </div>
            </div>
            {
                showEmail === true &&
                <VerifyEmailModal loader={apiLoader}
                    confirm={_getEmailOtp}
                    verify={_verifyOtp}
                    email={email}
                    closeModal={() => _handleEmailModal(false)}
                    emailVerified={() => _handleConfirm(true)} />
            }
            {
                emailStatus === true &&
                <EmailConfirmationModal
                    type={1}
                    show_button={true}
                    title="Email Verified Successfully"
                    content="Congratulations! Your email has been successfully verified"
                    closeModal={() => _handleConfirm(false)}
                    handleNavigate={() => _handleConfirm(false)}
                />
            }
        </Fragment >
    )
}

export default VerifyEmail;