
/*
 *   File : nri-kyc-status.js
 *   Author URI : https://evoqins.com
 *   Description : Nri kyc status modal to handle account opening restrictions
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Custom components
import { PrimaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";


const NRIKYCStatusModal = React.memo((props) => {

    const navigate = useNavigate();

    useEffect(() => {
        var my_modal = document.getElementById("nri-kyc-status");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="nri-kyc-status"
                tabIndex="-1"
                aria-labelledby="nri-kyc-status"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-36px-bottom  text-center padding-12px-lr ${style.e_content} `}>

                        <Fragment>
                            <img src={require("../../Assets/Images/Modal/failed.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2">
                                Account opening restricted!
                            </h5>

                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-2 ">
                                Unfortunately, you can’t open an investment account with us since the regular has not started accepting NRI non-KYC users online.
                            </p>
                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 ">
                                For further information, contact us at  <a href="mailto:helpdesk@mintit.com" className="color-rebecca-purple e-link"
                                    rel="noreferrer">helpdesk@mintit.com</a>
                            </p>

                            <div className="row d-flex justify-content-center">
                                <div className="col-6">
                                    <PrimaryButton label="View Home"
                                        className=" padding-12px-tb padding-54px-lr mt-4"
                                        cancel="modal"
                                        onPress={() => navigate("/")}
                                    />
                                </div>
                            </div>

                        </Fragment>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

NRIKYCStatusModal.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default NRIKYCStatusModal