/*
 *   File : gauge.js 
 *   Author : https://evoqins.com
 *   Description : Chart component to show the calculated risk score once after finishing the risk calculation
 *   Integrations : react-guage-component
 *   Version : 1.0.0
*/

// Packages
import { memo } from "react";
import GaugeComponent from "react-gauge-component";

import { SEGMENT_COLORS, RISK_SEGMENT_COLORS } from "../../Helper/helper";

const GaugeChart = memo((props) => {

    return (
        <div className="position-relative">
            <GaugeComponent
                type="semicircle"
                className="mx-auto "
                style={{
                    width: "100%",
                    height: "100%"
                }}
                arc={{
                    colorArray: props.risk_score === true ? RISK_SEGMENT_COLORS : SEGMENT_COLORS,
                    padding: 0.04,
                    cornerRadius: 100,
                    radial: 0.8,
                    width: 0.20,
                    subArcs:
                        props.risk_score === true ? [{ limit: 30 }, { limit: 60 }, { limit: 80 }, { limit: 100 }] : [
                            { limit: 16.66667 },
                            { limit: 33.33333 },
                            { limit: 50 },
                            { limit: 66.6666667 },
                            { limit: 83.3333333 },
                            { limit: 100 },
                        ]
                }}
                pointer={{
                    type: "blob",
                    animationDelay: 0,
                    color: "#FE625F",
                    width: 16.66667
                }}
                labels={{
                    valueLabel: {
                        hide: true,
                    },
                    tickLabels: {
                        hideMinMax: true,
                    }
                }}
                value={props.value}

            />
            {
                props.show_labels === true && <svg style={{ position: 'absolute', top: 0, left: 0, width: "100%" }}>


                    <text x="28" y="105" textAnchor="middle" style={{ fontSize: '12px', lineHeight: "16px", fontFamily: "Montserrat-Medium", fill: '#464746', }}>
                        Low
                    </text>
                    <text x="38" y="34" textAnchor="middle" style={{ fontSize: '12px', lineHeight: "16px", fontFamily: "Montserrat-Medium", fill: '#464746', }}>
                        <tspan x="54" dy="12">Moderately</tspan>
                        <tspan x="54" dy="12">Low</tspan>
                    </text>
                    <text x="115" y="20" textAnchor="middle" style={{ fontSize: '12px', lineHeight: "16px", fontFamily: "Montserrat-Medium", fill: '#464746', }}>
                        Moderate
                    </text>
                    <text x="182" y="0" textAnchor="middle" style={{ fontSize: '12px', lineHeight: "16px", fontFamily: "Montserrat-Medium", fill: '#464746', }}>
                        <tspan x="192" dy="14">Moderately</tspan>
                        <tspan x="192" dy="14">High</tspan>
                    </text>
                    <text x="245" y="55" textAnchor="middle" style={{ fontSize: '12px', lineHeight: "16px", fontFamily: "Montserrat-Medium", fill: '#464746', }}>
                        High
                    </text>
                    <text x="280" y="88" textAnchor="middle" style={{ fontSize: '12px', lineHeight: "16px", fontFamily: "Montserrat-Medium", fill: '#464746', }}>

                        <tspan x="275" dy="14">Very</tspan>
                        <tspan x="275" dy="14">High</tspan>
                    </text>
                </svg>
            }

        </div>
    )
})

export default GaugeChart;