import React, { useState, useEffect } from "react";
import { Icon } from "../Icon";

const CheckBoxGroup = React.memo((props) => {
    const [selectedList, setSelectedList] = useState(props.selectedList);

    useEffect(() => {
        setSelectedList(props.selectedList);
    }, [props.selectedList]);

    // helper function for checkbox items
    const _handleClick = (clickItem) => {
        let new_selected_list = [...selectedList];

        if (clickItem.id === null) {
            // Toggle selection of all items (including null)
            const is_all_selected = selectedList.length === props.data.filter(item => item.id !== null).length + 1; // Including null
            new_selected_list = is_all_selected ? [] : props.data.map(item => item.id); // Select all including null
        } else {
            // Individual item selection
            const is_selected = selectedList.includes(clickItem.id);
            if (is_selected) {
                new_selected_list = new_selected_list.filter(id => id !== clickItem.id);
            } else {
                new_selected_list.push(clickItem.id);
            }

            // Ensure null is included if all other items are selected
            const all_non_null_items_selected = props.data.filter(item => item.id !== null).every(item => new_selected_list.includes(item.id));
            if (all_non_null_items_selected && !new_selected_list.includes(null)) {
                new_selected_list.push(null);
            } else if (!all_non_null_items_selected && new_selected_list.includes(null)) {
                new_selected_list = new_selected_list.filter(id => id !== null);
            }
        }

        setSelectedList(new_selected_list);
        props.checkBoxHandler(new_selected_list);
    };




    return (
        <div className={`${props.className} d-flex flex-wrap gap-8px`}>
            {props.data.map((item, key) => {
                const is_selected = selectedList.includes(item.id) || (item.id === null && selectedList.length === props.data.filter(i => i.id !== null).length);
                return (
                    <div key={key}
                        className={`d-flex gap-8px align-items-center cursor-pointer ${props.checkBoxItemClassName}`}
                        onClick={() => _handleClick(item)}>
                        <Icon
                            icon={is_selected ? 'checked' : 'unchecked'}
                            size={props.size ? props.size : 18}
                            className="d-flex" />
                        <span className={`${props.fontSize ? props.fontSize : "e-font-14"}
                            e-montserrat-medium e-line-height-20px color-black`}>
                            {item.label}
                        </span>
                    </div>
                );
            })}
        </div>
    );
});

CheckBoxGroup.defaultProps = {
    selectedList: []
};

export default CheckBoxGroup;
