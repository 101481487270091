import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const GmailLogin = (props) => {

    const _handleSuccess = async (response) => {
        try {

            // Ensure the response contains the authorization code
            const code = response.code;
            if (!code) {
                throw new Error('Authorization code not received');
            }

            // Exchange the authorization code for tokens
            const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    code: code,
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
                    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
                    grant_type: 'authorization_code',
                }),
            });

            const tokenData = await tokenResponse.json();
            if (tokenData.error) {
                throw new Error(tokenData.error_description);
            }

            const { id_token } = tokenData;
            if (typeof id_token !== 'string') {
                throw new Error('Invalid ID token');
            }

            // Decode the ID token
            const decodedToken = jwtDecode(id_token);

            // Format user data
            const formattedData = {
                email: decodedToken.email,
                familyName: decodedToken.family_name,
                givenName: decodedToken.given_name,
                id: decodedToken.sub,
                name: decodedToken.name,
                photo: decodedToken.picture,
            };

            // Call the success callback with the token and formatted data
            props.success(id_token, formattedData);
        } catch (error) {
            console.error("Error during Google login:", error);
            props.failed(error);
        }
    };

    const _handleFailure = (error) => {
        props.failed(error);
    };

    const login = useGoogleLogin({
        onSuccess: _handleSuccess,
        onError: _handleFailure,
        flow: 'auth-code',
    });

    return (
        <img
            src={require("../../Assets/Images/Global/google.png")}
            alt="Google"
            draggable={false}
            onClick={() => login()}
            className='w-100 cursor-pointer'
        />
    );
};

export default GmailLogin;
