import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from 'chart.js';
import Color from "../../Styles/color.module.scss";



// Register the components
ChartJS.register(LineElement, CategoryScale, LinearScale, Tooltip, Legend);

const LineChart = (props) => {
    const chartRef = useRef(null);
    const chartTooltipRef = useRef(null);

    // Custom plugin to draw a line on mouse move
    const mouseLinePlugin = {
        id: 'mouseLine',
        afterDatasetsDraw: (chart) => {
            const ctx = chart.ctx;
            if (chart.tooltip._active && chart.tooltip._active.length) {
                const activePoints = chart.tooltip._active;
                chartTooltipRef.current = activePoints
                const x = activePoints[0].element.x;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;

                // Draw vertical dotted line
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, bottomY);
                ctx.lineTo(x, topY);
                ctx.lineWidth = 0.5;
                ctx.strokeStyle = Color.rebecca_purple; // Color for vertical line
                ctx.stroke();
                ctx.restore();

                activePoints.forEach((point, index) => {
                    const { element } = point;
                    const pointX = element.x;
                    const pointY = element.y;

                    // Draw the pointer (circle) at the active point
                    ctx.save();
                    ctx.beginPath();
                    ctx.arc(pointX, pointY, 4, 0, 2 * Math.PI); // Pointer size
                    ctx.fillStyle = index === 0 ? props.borderColor1 : props.borderColor2; // Different color for each dataset
                    ctx.fill();
                    ctx.restore();
                });
            }
        },
        beforeRender: (chart) => {
            // Get the first dataset
            const { tooltip, data } = chart;

            // If there's no data, exit
            if (!data.datasets.length) {
                return;
            }

            // Show tooltip at the first point if not visible
            if (!tooltip._active || !tooltip._active.length) {
                setTimeout(() => {
                    if (chartTooltipRef.current) {
                        chart.tooltip.setActiveElements(
                            chartTooltipRef.current
                        );
                        chart.update(); // Update the chart to show the tooltip
                    }
                }, 0);
            }
        },
        afterRender: (chart) => {
            // Get the first dataset
            const { tooltip, data } = chart;

            // If there's no data, exit
            if (!data.datasets.length) {
                return;
            }

            // Show tooltip at the first point if not visible
            if ((!tooltip._active || !tooltip._active.length) && chartTooltipRef.current) {

                chart.tooltip.setActiveElements(
                    chartTooltipRef.current
                );
                chart.update(); // Update the chart to show the tooltip

            }
        },
    };

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            // Set the initial position of the line to the last item in the dataset
            const lastElementIndex = props.labels.length - 1;
            const lastElement = chart.scales.x.getPixelForValue(lastElementIndex);
            chart.options.plugins.mouseLine.x = lastElement;
            chart.options.plugins.mouseLine.initialIndex = lastElementIndex;
            chart.options.plugins.mouseLine.color = `${Color.rebecca_purple}`; // Ensure this color is used

            // Trigger tooltip for the last element initially
            chart.tooltip.setActiveElements([
                {
                    datasetIndex: 0, // assuming first dataset, change if needed
                    index: lastElementIndex,
                },
                {
                    datasetIndex: 1, // assuming second dataset, change if needed
                    index: lastElementIndex,
                }
            ]);


        }
    }, [props.labels]);


    const data = {
        labels: props.labels,
        datasets: [
            {
                label: 'Active basket',
                data: props.dataset1,
                borderColor: props.borderColor1,
                pointBackgroundColor: props.borderColor1,
                pointRadius: 0,
                backgroundColor: 'transparent',
                borderWidth: 1.8,
                fill: true,
                lineTension: 0.5,
            },
            {
                label: 'Passive basket',
                data: props.dataset2,
                borderColor: props.borderColor2,
                pointBackgroundColor: props.borderColor2,
                pointRadius: 0,
                backgroundColor: 'transparent',
                borderWidth: 1.8,
                fill: true,
                lineTension: 0.5,
            },
        ].filter(Boolean),
    };


    // const minValue = Math.min(...props.dataset1, ...props.dataset2);
    const options = {
        layout: {
            padding: {
                top: 20,
                bottom: 20,
                left: 20,
                right: 20
            }
        },
        plugins: {

            tooltip: {
                enabled: true,
                // external: externalTooltipHandler,
                backgroundColor: Color.white,
                borderColor: Color.bright_gray,
                borderWidth: 1,
                titleFont: {
                    size: 0,
                },
                bodyFont: {
                    family: "Montserrat-SemiBold",
                    size: 12,
                },
                boxWidth: 8,
                footerFont: {
                    family: "Montserrat-Regular",
                    size: 10,
                },
                footerColor: Color.dark_silver,
                bodyColor: Color.black,
                callbacks: {
                    title: () => '',
                    label: (context) => {
                        const value = context.raw;
                        return `₹${value}`;
                    },
                    footer: (context) => {
                        const dateLabel = context[0].label;
                        return `as on ${dateLabel}`;
                    },
                },
                displayColor: true,
                usePointStyle: true,
                caretSize: 6,
                cornerRadius: 4,
                padding: 12,
                boxPadding: 4,
                caretPadding: 10,
            },
            legend: {
                display: false,
            },
            mouseLine: {
                x: NaN,
                color: 'rgba(0, 0, 0, 0.5)',
            },
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                beginAtZero: true,
                offset: false,
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                grid: {
                    display: false
                },
                // min: minValue 
            },
            y: {
                beginAtZero: false,
                offset: false,
                ticks: {
                    display: false,
                    maxTicksLimit: 5,
                    stepSize: 2,
                },
                border: {
                    display: false,
                },
                grid: {
                    borderColor: Color.anti_flash_white,
                },
            },
        },
    };

    return (
        <div className='position-relative'>

            <Line ref={chartRef} data={data} options={options} plugins={[mouseLinePlugin]}
                className='position-relative' />
        </div>
    );
};

export default LineChart;
