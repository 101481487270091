
/*
*   File : fund-order.js
*   Author : https://evoqins.com
*   Description : Fund order detail
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader } from "../../Components/Other";
import { Icon } from "../../Components/Icon";

// import services
import APIService from "../../Services/api-service";
import { _getOrderStatus, _getTransactionStatus } from "../../Helper/helper";

//  transaction status data
const TRANSACTION_STATUS_DATA = ['Order Initiated', 'Payment Received from', 'Order Captured', 'Units Allocated'];
const STEP_DATA = ['order_initiated', 'payment_bank', 'order_captured', 'units_allocated'];
const TRANSACTION_STATUS = ['order_initiated_status', 'payment_received_status', 'order_captured_status', 'units_allocated_status'];

// withdraw status data
const WITHDRAW_STEP_DATA = ['order_initiated', 'order_captured', 'payment_received'];
const WITHDRAW_TRANSACTION_STATUS = ['order_initiated_status', 'order_captured_status', 'payment_received_status'];
const WITHDRAW_STATUS_DATA = ['Order Initiated', 'Order Captured', 'Amount Sent To Bank'];

// switch status data
const SWITCH_STATUS = ['order_initiated_status', 'order_captured_status', 'units_allocated_status'];
const SWITCH_STATUS_DATA = ['Order Initiated', 'Order Captured', 'Units Allocated'];
const SWITCH_STEP_DATA = ['order_initiated', 'order_captured', 'units_allocated'];


const FundOrders = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [screenLoader, setScreenLoader] = useState(true);
    const [fund, setFund] = useState([]);
    const [orderDetail, setOrderDetail] = useState({});


    useEffect(() => {
        if (location.state !== null) {
            _getOrderDetails(location.state.id);
        }
        // eslint-disable-next-line
    }, [location])

    useEffect(() => {
        // Add the event listener
        window.addEventListener('popstate', _handleCurrentNavigate);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', _handleCurrentNavigate);
        };
        //eslint-disable-next-line
    }, []);

    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/orders", {
            state: {
                type: location.state.type,
                paymentTypes: location.state.paymentTypes,
                orderTypes: location.state.orderTypes,
                orderStatus: location.state.orderStatus,
                startDate: location.state.startDate,
                endDate: location.state.endDate,
                page: location.state.page
            }
        });
    }

    // API - order details
    const _getOrderDetails = (id) => {
        const url = "/order/detail";
        const request = {
            order_id: id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setOrderDetail(response.data.summary);
                setFund(response.data.funds);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
                navigate("/orders")
            }
            setScreenLoader(false);
        });
    }

    return (
        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 padding-44px-bottom">
                        <section className="px-sm-4 px-3">
                            <Breadcrumb mainPage="Home"
                                currentPage="Orders"
                                type={2}
                                childPage={orderDetail.name}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate} />

                            <Icon icon="Arrow-Right"
                                size={32}
                                className="mb-3 margin-32px-top e-transform-left-arrow cursor-pointer"
                                onClick={_handleCurrentNavigate} />
                            <div className="row row-gap-16px">
                                <div className="col-xl-8 col-lg-10 order-lg-1 order-2">
                                    <div className="box-shadow-black border-radius-24px pt-sm-4 pt-3">
                                        <div className="d-flex align-items-start gap-8px pb-sm-4 pb-3 px-sm-4 px-3 border-bottom-1px border-chinese-white">
                                            <img src={orderDetail.icon}
                                                width={48}
                                                height={48}
                                                alt={orderDetail.scheme_name}
                                                draggable={false}
                                                className="border-radius-12px e-fund-detail-chart object-fit-contain d-sm-block d-none" />
                                            <img src={orderDetail.icon}
                                                width={38}
                                                height={38}
                                                alt={orderDetail.scheme_name}
                                                draggable={false}
                                                className="border-radius-12px e-fund-detail-chart object-fit-contain d-sm-none d-block" />
                                            <div className="d-flex gap-8px flex-column">
                                                <p className="color-eerie-black e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{orderDetail.name}</p>
                                                <div className="d-flex align-items-center color-outer-space e-font-12 e-line-height-16 e-montserrat-regular">
                                                    <span className="e-bg-lavender-frost padding-6px-tb px-2 color-rebecca-purple e-font-12 e-line-height-14 e-montserrat-medium border-radius-30px me-3">{orderDetail.order_no}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="pt-sm-3 pt-2 pb-sm-4 pb-2 mx-sm-4 mx-3 border-bottom-1px border-chinese-white">
                                            <h6 className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black mb-md-3 mb-2">Order details</h6>
                                            {
                                                (orderDetail.type === "Switch" || orderDetail.type === "STP") ?
                                                    <Fragment>
                                                        <div className="row">
                                                            <div className="col-12 pb-md-4 pb-2">
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Switch out</p>
                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.switch_out.name}</p>
                                                            </div>
                                                            <div className="col-sm-3 col-4 pb-md-4 pb-2">
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Units</p>
                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.switch_out.units}</p>
                                                            </div>
                                                            <div className="col-sm-3 col-4 pb-md-4 pb-2">
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">NAV</p>
                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.switch_out.nav}</p>
                                                            </div>

                                                        </div>
                                                        <img src={require("../../Assets/Images/Orders/switch.svg").default}
                                                            alt="switch"
                                                            className=" mb-3" />
                                                        <div className="row">
                                                            <div className="col-12 pb-md-4 pb-2">
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Switch in</p>
                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.switch_in.name}</p>
                                                            </div>
                                                            <div className="col-sm-3 col-4 pb-md-4 pb-2">
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Units</p>
                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.switch_in.units}</p>
                                                            </div>
                                                            <div className="col-sm-3 col-4 pb-md-4 pb-2">
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">NAV</p>
                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.switch_in.nav}</p>
                                                            </div>


                                                        </div>
                                                    </Fragment>
                                                    :
                                                    <div className="row">
                                                        <div className="col-sm-4 col-6 pb-md-4 pb-2">
                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Date</p>
                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{orderDetail.order_date}</p>
                                                        </div>
                                                        <div className="col-sm-4 col-6 pb-md-4 pb-2">
                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Amount</p>
                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{orderDetail.amount_text}</p>
                                                        </div>

                                                        <div className="col-sm-4 col-6 pb-md-4 pb-2">
                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Order type</p>
                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{orderDetail.type}</p>
                                                        </div>

                                                        <div className="col-sm-4 col-6 pb-0 mb-1">
                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Payment Status</p>
                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${_getOrderStatus(orderDetail.payment_status).color}`}>
                                                                <img src={_getOrderStatus(orderDetail.payment_status).icon}
                                                                    alt="Success"
                                                                    draggable={false}
                                                                    className="me-1" />
                                                                {orderDetail.payment_status}
                                                            </p>
                                                        </div>
                                                        <div className="col-sm-4 col-6 ">
                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Order Status</p>
                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${_getOrderStatus(orderDetail.order_status).color}`}>
                                                                <img src={_getOrderStatus(orderDetail.order_status).icon}
                                                                    alt="Success"
                                                                    draggable={false}
                                                                    className="me-1" />
                                                                {orderDetail.order_status}</p>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className="pb-sm-3 pb-2 mx-sm-4 mx-3">
                                            {
                                                (orderDetail.type !== "Switch" && orderDetail.type !== "STP") &&
                                                <div className="row">

                                                    <div className="col-sm-4 col-6 pt-sm-3 pt-2">
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">Units</p>
                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.units ? fund.units : 0}</p>
                                                    </div>
                                                    <div className="col-sm-4 col-6 pt-sm-3 pt-2">
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">Transaction ID</p>
                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.transaction_id}</p>
                                                    </div>
                                                    <div className="col-sm-4 col-6 pt-sm-3 pt-2">
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">NAV</p>
                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.nav ? fund.nav : "-"}</p>
                                                    </div>
                                                    <div className="col-sm-4 col-6 pt-sm-4 pt-2">
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">{orderDetail.type === "Withdraw" || orderDetail.type === "SWP" ? "Traded Date" : "Allocated Date"}</p>
                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{fund.units_allocated ? fund.units_allocated : "-"}</p>
                                                    </div>
                                                </div>
                                            }

                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 mb-3 color-eerie-black pt-4">Transaction status</p>

                                            <div className="d-md-flex d-none">

                                                {
                                                    (orderDetail.type === "Switch" || orderDetail.type === "STP") ?
                                                        SWITCH_STATUS_DATA.map((item, index) => {
                                                            let transaction_status_param = SWITCH_STATUS[index]; // key for the status
                                                            let status = fund[transaction_status_param];
                                                            let step_data = _getTransactionStatus(status, index);
                                                            return (
                                                                <div className={`e-border gap-12px position-relative w-35 z-index-3 bg-white pe-1`}>
                                                                    <div className={`e-progress-image d-flex align-items-center justify-content-start position-relative`}>
                                                                        <img src={step_data.icon} alt={"progress"}
                                                                            draggable={false}
                                                                        />
                                                                        {
                                                                            index !== SWITCH_STATUS_DATA.length - 1 && <div
                                                                                className="e-progress-line"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    width: '100%',
                                                                                    height: '1px',
                                                                                    left: '1.25rem',
                                                                                    zIndex: 1,
                                                                                    background: step_data.bg_color
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    {
                                                                        SWITCH_STATUS_DATA.length - 1 === index ?
                                                                            <div className="d-block padding-12px-top">
                                                                                <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                    {orderDetail.type === "STP" ? "Order transferred" : "Order Switched"}
                                                                                </p>
                                                                                <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[SWITCH_STEP_DATA[index]] ? fund[SWITCH_STEP_DATA[index]] : "-"}</p>
                                                                            </div>
                                                                            :
                                                                            <div className="d-block padding-12px-top">
                                                                                <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                    {item}
                                                                                </p>
                                                                                <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[SWITCH_STEP_DATA[index]] ? fund[SWITCH_STEP_DATA[index]] : "-"}</p>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        (orderDetail.type !== 'Withdraw' && orderDetail.type !== 'SWP') ?
                                                            TRANSACTION_STATUS_DATA.map((item, index) => {
                                                                let transaction_status_param = TRANSACTION_STATUS[index]; // key for the status
                                                                let status = fund[transaction_status_param];
                                                                let step_data = _getTransactionStatus(status, index);
                                                                return (
                                                                    <div className={`e-border gap-12px position-relative w-35 z-index-3 bg-white pe-1`}>
                                                                        <div className={`e-progress-image d-flex align-items-center justify-content-start position-relative`}>
                                                                            <img src={step_data.icon} alt={"progress"}
                                                                                draggable={false}
                                                                            />
                                                                            {
                                                                                index !== TRANSACTION_STATUS_DATA.length - 1 && <div
                                                                                    className="e-progress-line"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        width: '100%',
                                                                                        height: '1px',
                                                                                        left: '1.25rem',
                                                                                        zIndex: 1,
                                                                                        background: step_data.bg_color
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        {
                                                                            index === 1 ?
                                                                                <div className="d-block padding-12px-top pe-4">
                                                                                    <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                        Payment Received from  {fund.payment_bank}
                                                                                    </p>
                                                                                    <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[STEP_DATA[index]] ? fund[STEP_DATA[index]] : "-"}</p>
                                                                                </div>
                                                                                :
                                                                                <div className="d-block padding-12px-top">
                                                                                    <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                        {item}
                                                                                    </p>
                                                                                    <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[STEP_DATA[index]] ? fund[STEP_DATA[index]] : "-"}</p>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                            :

                                                            WITHDRAW_STATUS_DATA.map((item, index) => {
                                                                let goal_data = fund;
                                                                let transaction_status_param = WITHDRAW_TRANSACTION_STATUS[index]; // key for the status
                                                                let status = goal_data[transaction_status_param];
                                                                let step_data = _getTransactionStatus(status, index);
                                                                return (
                                                                    <div className={`e-border gap-12px position-relative w-35 z-index-3 bg-white pe-1`}>
                                                                        <div className={`e-progress-image d-flex align-items-center justify-content-start position-relative`}>
                                                                            <img src={step_data.icon} alt={"progress"}
                                                                                draggable={false}
                                                                            />
                                                                            {
                                                                                index !== WITHDRAW_STATUS_DATA.length - 1 && <div
                                                                                    className="e-progress-line"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        width: '100%',
                                                                                        height: '1px',
                                                                                        left: '1.25rem',
                                                                                        zIndex: 1,
                                                                                        background: step_data.bg_color
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        {

                                                                            <div className="d-block padding-12px-top">
                                                                                <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                    {item}
                                                                                </p>
                                                                                <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[WITHDRAW_STEP_DATA[index]] ? fund[WITHDRAW_STEP_DATA[index]] : "-"}</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                }

                                            </div>

                                            <div className="d-md-none d-block">

                                                {
                                                    (orderDetail.type === "Switch" || orderDetail.type === "STP") ?
                                                        SWITCH_STATUS_DATA.map((item, index) => {
                                                            let transaction_status_param = SWITCH_STATUS[index]; // key for the status
                                                            let status = fund[transaction_status_param];
                                                            let step_data = _getTransactionStatus(status, index);
                                                            return (
                                                                <div className={`e-vertical-border gap-12px  d-flex align-items-start z-index-3 bg-white ${index > 0 ? 'pt-5' : ''}`}>
                                                                    <div className={`e-progress-image d-flex align-items-start justify-content-start position-relative`}>
                                                                        <img src={step_data.icon} alt={"progress"}
                                                                            draggable={false}
                                                                            className="bg-white z-index-2"
                                                                        />
                                                                        {
                                                                            index !== SWITCH_STATUS_DATA.length - 1 && <div
                                                                                className="e-progress-line"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    height: '15vh',
                                                                                    width: '1px',
                                                                                    left: '0.625rem',
                                                                                    top: "18px",
                                                                                    zIndex: 1,
                                                                                    background: step_data.bg_color
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    {
                                                                        SWITCH_STATUS_DATA.length - 1 === index ?
                                                                            <div className="d-block">
                                                                                <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                    {orderDetail.type === "STP" ? "Order transferred" : "Order Switched"}
                                                                                </p>
                                                                                <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[SWITCH_STEP_DATA[index]] ? fund[SWITCH_STEP_DATA[index]] : "-"}</p>
                                                                            </div>
                                                                            :
                                                                            <div className="d-block">
                                                                                <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                    {item}
                                                                                </p>
                                                                                <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[SWITCH_STEP_DATA[index]] ? fund[SWITCH_STEP_DATA[index]] : "-"}</p>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        (orderDetail.type !== 'Withdraw' && orderDetail.type !== 'SWP') ?
                                                            TRANSACTION_STATUS_DATA.map((item, index) => {
                                                                let transaction_status_param = TRANSACTION_STATUS[index]; // key for the status
                                                                let status = fund[transaction_status_param];
                                                                let step_data = _getTransactionStatus(status, index);
                                                                return (
                                                                    <div className={`e-vertical-border gap-12px  d-flex align-items-start z-index-3 bg-white ${index > 0 ? 'pt-5' : ''}`}>
                                                                        <div className={`e-progress-image d-flex align-items-start justify-content-start position-relative`}>
                                                                            <img src={step_data.icon} alt={"progress"}
                                                                                draggable={false}
                                                                                className="bg-white z-index-2"
                                                                            />
                                                                            {
                                                                                index !== TRANSACTION_STATUS_DATA.length - 1 && <div
                                                                                    className="e-progress-line"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        height: '15vh',
                                                                                        width: '1px',
                                                                                        left: '0.625rem',
                                                                                        top: "18px",
                                                                                        zIndex: 1,
                                                                                        background: step_data.bg_color
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        {
                                                                            index === 1 ?
                                                                                <div className="d-block">
                                                                                    <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                        Payment Received from  {fund.payment_bank}
                                                                                    </p>
                                                                                    <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[STEP_DATA[index]] ? fund[STEP_DATA[index]] : "-"}</p>
                                                                                </div>
                                                                                :
                                                                                <div className="d-block">
                                                                                    <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                        {item}
                                                                                    </p>
                                                                                    <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[STEP_DATA[index]] ? fund[STEP_DATA[index]] : "-"}</p>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            WITHDRAW_STATUS_DATA.map((item, index) => {
                                                                let transaction_status_param = WITHDRAW_TRANSACTION_STATUS[index]; // key for the status
                                                                let status = fund[transaction_status_param];
                                                                let step_data = _getTransactionStatus(status, index);
                                                                return (
                                                                    <div className={`e-vertical-border gap-12px  d-flex align-items-start z-index-3 bg-white ${index > 0 ? 'pt-5' : ''}`}>
                                                                        <div className={`e-progress-image d-flex align-items-start justify-content-start position-relative`}>
                                                                            <img src={step_data.icon} alt={"progress"}
                                                                                draggable={false}
                                                                                className="bg-white z-index-2"
                                                                            />
                                                                            {
                                                                                index !== WITHDRAW_STATUS_DATA.length - 1 && <div
                                                                                    className="e-progress-line"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        height: '15vh',
                                                                                        width: '1px',
                                                                                        left: '0.625rem',
                                                                                        top: "18px",
                                                                                        zIndex: 1,
                                                                                        background: step_data.bg_color
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div className="d-block">
                                                                            <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                {item}
                                                                            </p>
                                                                            <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{fund[WITHDRAW_STEP_DATA[index]] ? fund[WITHDRAW_STEP_DATA[index]] : "-"}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                }




                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </section>
                    </div>

                </div >
            </div >
    )
}
export default FundOrders