/*
*   File : kyc-status.js
*   Author : https://evoqins.com
*   Description : Screen to show kyc status of user
*   Version : 1.0.0
*/

import { Fragment, useEffect, useState } from "react"
import { Header } from "../../Components/Header"
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Loader } from "../../Components/Other";
import { PrimaryButton } from "../../Components/Buttons";
import { useSelector } from "react-redux";

const KycStatus = () => {
    const navigate = useNavigate();
    const PROFILE_DATA = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const [pageLoader, setPageLoader] = useState(true);

    useEffect(() => {
        if (Object.keys(PROFILE_DATA).length > 0) {
            setPageLoader(false);
        }
    }, [PROFILE_DATA])


    function _handleNavigate() {
        navigate("/");
    }

    const _getImage = (status) => {
        let image_url = require("../../Assets/Images/KycStatus/start-kyc.png")
        if (PROFILE_DATA.kyc.status === true) {
            image_url = require("../../Assets/Images/KycStatus/verified.png")
        } else {
            switch (status) {
                case "Unavailable":
                    image_url = require("../../Assets/Images/KycStatus/unavailable.png")
                    break;
                case "Onhold":
                    image_url = require("../../Assets/Images/KycStatus/on-hold.png")
                    break;
                case "Deactivated":
                    image_url = require("../../Assets/Images/KycStatus/deactivated.png")
                    break;
                case "Rejected":
                    image_url = require("../../Assets/Images/KycStatus/rejected.png")
                    break;
                case "Legacy":
                    image_url = require("../../Assets/Images/KycStatus/leagacy.png")
                    break;
                case "Underprocess":
                    image_url = require("../../Assets/Images/KycStatus/processing.png")
                    break;
                case "Incomplete":
                    image_url = require("../../Assets/Images/KycStatus/incomplete.png")
                    break;
                case "Unknown":
                    image_url = require("../../Assets/Images/KycStatus/new-kyc.png")
                    break;
                default:
                    break;
            }
        }
        return image_url

    }

    function _handleKycNavigate() {
        let action = PROFILE_DATA.kyc.action;
        if (PROFILE_DATA.kyc.status === false && PROFILE_DATA.kyc.reason === null) {
            navigate('/kyc');
        } else {
            if (action === "Create" || action === "Resume" || action === "Rejected" || action === "Pending") {
                navigate('/kyc');
            } else if (action === "Modify") {
                window.openURL(PROFILE_DATA.kyc.validation_url, "_blank");
            }
        }
    }
    return (
        pageLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">
                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Manage KYC"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-38 color-dark-jungle-green margin-12px-bottom margin-30px-top">Manage KYC</p>
                                <div className="row row-gap-24px">
                                    <div className="col-lg-8">
                                        <div className="border-radius-24px e-profile-border padding-50px-tb text-center px-4">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6 ">
                                                    <img src={_getImage(PROFILE_DATA.kyc.reason)}
                                                        alt="Validated"
                                                        width={125}
                                                        height={140}
                                                        className="mx-auto" />


                                                    <h6 className="color-jet e-font-18 e-line-height-24 e-montserrat-semi-bold  mb-2">{PROFILE_DATA.kyc.reason ? PROFILE_DATA.kyc.reason : PROFILE_DATA.kyc.status_text}</h6>
                                                    <p className="color-jet e-font-16 e-line-height-24 e-montserrat-medium  mb-2">
                                                        {PROFILE_DATA.kyc.remarks}
                                                    </p>

                                                    {(PROFILE_DATA.kyc.status === false) &&
                                                        <div className="row justify-content-center">
                                                            <div className="col-6">
                                                                <PrimaryButton label={(PROFILE_DATA.kyc.status === false && PROFILE_DATA.kyc.reason === null) ? "Create an Account" : 'Validate'}
                                                                    type={1}
                                                                    onPress={() => _handleKycNavigate()} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <p className="e-montserrat-regular e-font-16 e-line-height-30 color-davy-gray mb-0 mt-3">
                                            Need help:<br />
                                            If you are facing any difficulty in completing the KYC, please contact MINTIT team in the contact  <br /><a href="tel:+918956915040" rel="noreferrer" className="color-rebecca-purple e-link e-montserrat-semi-bold">
                                                +91 8956915040
                                            </a> or <a href="mailto:helpdesk@mintit.com" className="color-rebecca-purple e-link e-montserrat-semi-bold"
                                                rel="noreferrer">helpdesk@mintit.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default KycStatus