
/*
 *   File : video-preview.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to play video
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import React, { Fragment, useEffect } from "react";

// Custom components

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";

// services
import { Icon } from "../Icon";


const VideoPreviewModal = React.memo((props) => {

    useEffect(() => {
        var my_modal = document.getElementById("video-preview");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);




    return (
        <Fragment>
            <div className={`modal fade ${style.e_preview_modal}`}
                id="video-preview"
                tabIndex="-1"
                aria-labelledby="video-preview"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog} `}>
                    <div className={`modal-content text-center padding-12px-lr  ${style.e_content}`}>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer position-absolute right-16px top-16px' />
                        <Fragment>
                            <video src={"https://mintit-prod-static-assets.s3.ap-south-1.amazonaws.com/videos/ACTIVE_V_S_PASSIVE.mp4"}
                                autoPlay
                                alt="success"
                                controls
                                muted
                                draggable={false}
                                width={300}
                                className="mx-auto py-5" />
                        </Fragment>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

VideoPreviewModal.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default VideoPreviewModal