/*
 *   File : link.js
 *   Author URI : https://evoqins.com
 *   Description : Link button for site
 *   Integrations : null
 *   Version : 1
 */

const LinkButton = (props) => {
    return (
        <span className={`e-montserrat-semi-bold color-eminence cursor-pointer e-link ${props.className} `} onClick={props.onPress}>{props.label}</span>
    )
}

export default LinkButton;

LinkButton.defaultProps = {
    label: "",
    className: "",
    onPress: () => { }
}