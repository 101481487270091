/*
 *   File : sip-dates.js
 *   Author : https://evoqins.com
 *   Description : SIP date modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from "react";

// Component
import { PrimaryButton } from "../Buttons";
import { Icon } from "../Icon";
import { _formatSip } from "../../Helper/helper";
import Color from "../../Styles/color.module.scss";
import style from "../../Styles/Components/sip-dates.module.scss"

const NORMALIZED_SIP_DATES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

const SIPDateModal = React.memo((props) => {

    const [selectedDate, setSelectedDate] = useState(props.selectedSIPDate);
    const [sipDateString, setSipDateString] = useState(props.selectedSIPDate);


    useEffect(() => {
        var my_modal = document.getElementById("sip-date");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.handleCloseDateModal();
        });
        //eslint-disable-next-line
    }, [props.handleCloseDateModal]);

    useEffect(() => {

        setSelectedDate(props.selectedSIPDate);

        let selected_date = props.selectedSIPDate;
        _selectDate(selected_date, props.selectedSIPDate)
        //eslint-disable-next-line
    }, [props.selectedSIPDate]);

    const _handleOnSelect = (date) => {
        setSelectedDate(date);
        _selectDate(date, date)
    };

    const _selectDate = (selected_date, value) => {
        const date_string = _formatSip(selected_date);
        setSipDateString(date_string);
    }

    const _handleConfirmSIPDate = () => {
        if (selectedDate !== "") {
            props.handleSelect(parseFloat(selectedDate), sipDateString);
            props.handleCloseDateModal();
        };
    };

    const _handleCloseModal = () => {
        document.getElementById("close-sip").dispatchEvent(new Event("click"));
        if (props.backdrop) {
            document.getElementById("sip-date").classList.remove("show");
            const backdrop = document.querySelector(".modal-backdrop.fade.show");
            if (backdrop) {
                backdrop.parentNode.removeChild(backdrop);
            }
            document.body.removeAttribute('style')
            props.handleCloseDateModal();
        }
        else {
            props.handleCloseDateModal();
        }

    };
    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="sip-date"
            tabIndex="-1"
            aria-labelledby="sip-date"
            aria-hidden="true"
            data-bs-backdrop={props.backdrop}
            data-bs-keyboard="false">
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content ${style.e_content} padding-22px-bottom border-radius-16px pb-4`}>

                    <div className="border-bottom-1px border-ash-gray px-sm-3 px-2">
                        <div className="d-flex align-items-center justify-content-between px-2 py-3">
                            <h6 className='e-font-14 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-20'>
                                Select {props.title} date
                            </h6>
                            <Icon icon='close-btn'
                                size={24}
                                id="close-sip"
                                color={Color.eerie_black}
                                // data-bs-dismiss="modal"
                                className="cursor-pointer"
                                onClick={_handleCloseModal} />
                        </div>
                    </div>
                    <p className="mt-3 margin-12px-bottom e-montserrat-semi-bold color-eerie-black e-font-14 e-line-height-24 text-center">{sipDateString} of every month</p>

                    <div className="px-sm-3 px-2 d-flex gap-8px flex-wrap e-border-radius-12 text-center">
                        {NORMALIZED_SIP_DATES.map((date, key) => (
                            <div className={`d-flex align-items-center justify-content-center w-40px h-40px e-montserrat-regular e-line-height-24 cursor-pointer e-font-14 
                                ${selectedDate === date ?
                                    "e-bg-rebecca-purple color-white border-radius-100px" : "e-sip-date color-eerie-black"} 
      ${props.apiDates.length !== 0 && !props.apiDates.includes(date.toString()) ? " disabled opacity-5" : ""}`}
                                key={key}
                                onClick={() => _handleOnSelect(date)} >
                                {date}
                            </div>
                        ))}

                    </div>
                    <div className="d-flex align-items-start p-2 mt-2 mx-sm-3 mx-2 e-bg-light-blue border-radius-12px mt-4 color-ocean-blue e-font-12 e-line-height-16 gap-4px e-montserrat-medium">
                        <span>
                            <Icon icon="info-circle"
                                size={16} />
                        </span>
                        <span className="padding-2px-top">{props.title} calendars have only 28 days so that no month gets missed.</span>

                    </div>

                    <div className='mt-4 col-6 mx-auto'>
                        <PrimaryButton label="Confirm"
                            cancel="modal"
                            className="padding-10px-tb w-100"
                            onPress={_handleConfirmSIPDate} />
                    </div>

                </div>
            </div>
        </div>

    )
})

export default SIPDateModal;

SIPDateModal.defaultProps = {
    apiDates: [],
    backdrop: true,
    title: "SIP"
}