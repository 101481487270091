/*
 *   File : doughnut.js 
 *   Author : https://evoqins.com
 *   Description : Chart component to show the calculated risk score once after finishing the risk calculation
 *   Integrations :chartjs
 *   Version : 1.0.0
*/


import React, { useEffect } from 'react';
import { Chart } from 'chart.js';

function PieChart(props) {
    useEffect(() => {
        if (props.data.labels !== undefined) {
            // Chart configuration
            var newChart = document.getElementById('doughnut').getContext('2d');
            newChart.canvas.style.zIndex = 1;
            newChart = new Chart(newChart, {
                type: 'doughnut',
                data: { //chart area configuration
                    datasets: [{
                        data: props.data && props.data.datasets.length > 0 ? props.data.datasets : [],
                        order: 1,
                        spacing: 0,
                        borderWidth: 0,
                        backgroundColor: props.data.colors,
                    }]
                },
                options: {
                    indexAxis: 'x',
                    scales: { // grid lines
                        x: {
                            beginAtZero: false,
                            offset: false,
                            border: {
                                display: false,
                            },
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false
                            },
                        },

                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            position: 'nearest',
                            usePointStyle: true,
                            caretSize: 5,
                            cornerRadius: 4,
                            padding: 10,
                            boxPadding: 4,
                            caretPadding: 4,
                            backgroundColor: "#fff",
                            bodyColor: "#000",
                            bodyFont: {
                                family: "Montserrat-SemiBold",
                                size: 12,
                            },
                            callbacks: {
                                title: () => '',
                                label: (context) => {
                                    const value = context.raw;
                                    return `${value} %`;
                                },
                            },
                        },
                    }
                }
            });
            return () => {
                newChart.destroy();
            };
        }
    }, [props.data]);

    return (
        <div className='w-240px xs-w-180px position-relative mx-auto'>
            <canvas id="doughnut"
                className="position-relative "
                style={{ width: "100%" }}
            ></canvas>

            {/* <div className='position-absolute top-0 bottom-0 start-0 end-0 m-auto w-max-content h-max-content text-center'>
                <span className='color-go-green e-font-20 e-line-height-28 e-montserrat-medium'>{props.achieved}%</span>
                <p className='e-font-14 e-line-height-16 e-montserrat-semi-bold color-blue-gray'>Completed</p>
            </div> */}
        </div>
    );
}

export default PieChart;