
import Cookies from "js-cookie";
const initialState = {
    PROFILE_DETAILS: {},
    SIGNUP_PROGRESS: {},
    GENERAL_DATA: {},
    IS_LOGGED_IN: sessionStorage.getItem("mintit_access_token") ? true : false,
    REFRESH_TOKEN: Cookies.get("mintit_refresh_token") ? Cookies.get("mintit_refresh_token") : null,
    NOTIFICATION_COUNT: 0
};

export default function Reducer(state = initialState, action) {
    switch (action.type) {
        case "PROFILE_DATA":
            return {
                ...state,
                PROFILE_DETAILS: action.payload
            };
        case "LOGIN_STATUS":
            return {
                ...state,
                IS_LOGGED_IN: action.payload
            };
        case "REFRESH_TOKEN":
            return {
                ...state,
                REFRESH_TOKEN: action.payload
            };
        case "SIGNUP_PROGRESS":
            return {
                ...state,
                SIGNUP_PROGRESS: action.payload
            };
        case "GENERAL_DATA":
            return {
                ...state,
                GENERAL_DATA: action.payload
            };
        case "NOTIFICATION_COUNT":
            return {
                ...state,
                NOTIFICATION_COUNT: action.payload
            };

        default:
            return state
    }
}