
/*
 *   File : confirm-invest.js
 *   Author URI : https://evoqins.com
 *   Description : Confirm invest modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// Custom components
import { Icon } from "../Icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { _generate2Fa } from "../../Helper/api";

// custom hooks
import { useCountdown } from "../../Helper/helper";
import { CustomPINInput } from "../FormElements";

// Custom styles
import style from "../../Styles/Components/email.module.scss";
import Color from '../../Styles/color.module.scss';


// type  1 ---- success
// else failed

const ConfirmInvest = (props) => {

    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number
    const { seconds, resetCountdown } = useCountdown(30);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("confirm-invest");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            setOtp("");
            setOtpError("")
        });
        //eslint-disable-next-line
    }, []);

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // handle submit otp validation
    async function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            try {
                const result = await props.verify(otp);
                if (result) {
                    document.getElementById("close-modal").dispatchEvent(new Event("click"));
                }
            } catch (error) {
                console.error("Error verifying OTP:", error);
            }
        }
    }

    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("Goal")
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };


    return (
        <Fragment>
            <div className={`modal fade ${style.e_email}`}
                id="confirm-invest"
                tabIndex="-1"
                aria-labelledby="select-career"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>

                    <div className={`modal-content pb-4 ${style.e_content} `}>
                        <div className="border-bottom-1px border-ash-gray px-4">
                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                    Enter OTP
                                </h6>
                                <Icon icon="close-btn"
                                    size={24}
                                    color={Color.eerie_black}
                                    id="close-modal"
                                    className="cursor-pointer"
                                    data-bs-dismiss="modal" />
                            </div>
                        </div>
                        <div className="px-4 padding-14px-top">
                            <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                An OTP has been sent to your PAN linked mobile
                                number.
                            </p>
                            <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">+91-  {MASKED_NUMBER}</h6>
                            <CustomPINInput id="otp"
                                autoFocus={true}
                                pinString={otp}
                                error={otpError}
                                postfix="*"
                                label="Enter OTP"
                                onSubmit={_submitOTP}
                                handleChange={_handleOTP} />
                            <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                    ${seconds === 0 ?
                                    'cursor-pointer'
                                    : 'disabled'}`}
                                onClick={_handleResend}>
                                {seconds === 0 ?
                                    'Resend OTP'
                                    : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                            </span>
                            </p>
                            <div className="row margin-32px-top">
                                <div className="col-6">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal"
                                        className="padding-12px-tb w-100 e-bg-magnolia" />
                                </div>
                                <div className="col-6">
                                    <PrimaryButton label="Verify"
                                        className="padding-12px-tb  w-100"
                                        disabled={props.loader}
                                        onPress={_submitOTP} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default memo(ConfirmInvest)