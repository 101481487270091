
/*
 *   File : check-kyc.js
 *   Author URI : https://evoqins.com
 *   Description : KYC  status check modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/kyc.module.scss";

const KYCStatusModal = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        var my_modal = document.getElementById("check-kyc");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="check-kyc"
                tabIndex="-1"
                aria-labelledby="check-kyc"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content text-center px-3 pb-4  ${style.e_content} `}>

                        <img src={require("../../Assets/Images/Modal/failed.png")}
                            alt="success"
                            draggable={false}
                            width={144}
                            height={144}
                            className="mx-auto" />
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black margin-12px-bottom margin-2px-top">
                            Update Your KYC
                        </h5>

                        <p className="e-montserrat-medium e-font-14 e-line-height-28 color-charcoal-gray mb-0 ">
                            You have previously submitted a KYC application that is currently in "modify" status. You need to update it in order to proceed further. Only then can you proceed with the investment account creation.
                        </p>

                        <div className="row">
                            <div className="col-6">
                                <SecondaryButton label="Cancel"
                                    cancel="modal"
                                    className="w-100 padding-12px-tb mt-4"
                                    onPress={() => navigate("/")} />
                            </div>
                            <div className="col-6">
                                <PrimaryButton label="Modify KYC"
                                    showLoader={false}
                                    className="w-100 padding-12px-tb mt-4"
                                    onPress={props.handleConfirm} />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

KYCStatusModal.defaultProps = {
    closeModal: () => { },
    handleConfirm: () => { },
}

export default memo(KYCStatusModal)