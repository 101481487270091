/*
 *   File : secondary.js
 *   Author URI : https://evoqins.com
 *   Description : Secondary button for site
 *   Integrations : null
 *   Version : 1
 */

import style from "../../Styles/Components/primary.module.scss";
// import { Icon } from "../Icon";

const SecondaryButton = (props) => {

    return (
        // primary button
        <button
            className={`e-montserrat-semi-bold e-font-14 e-line-height-24 letter-spacing-02em color-rebecca-purple border-radius-14px border-1px border-light-purple text-nowrap e-bg-magnolia ${style.e_height} ${props.className}
        ${props.disabled === false ?
                    `${style.e_secondary_btn}  cursor-pointer`
                    :
                    `${style.e_inactive_btn} `
                }`}

            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-loading={`${props.loading}`}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            onClick={props.onPress}
        >
            <span className="position-relative d-flex justify-content-center align-items-center gap-8px">
                {
                    props.image &&
                    <img src={props.image}
                        alt={props.lable}
                        width={props.width ? props.width : 24}
                        height={props.height ? props.height : 24} />
                }
                {props.label ? props.label : "Submit"}
            </span>
        </button>
    );
};

SecondaryButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
}

export default SecondaryButton;
