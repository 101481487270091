
/*
 *   File : nominee-confirmation.js
 *   Author URI : https://evoqins.com
 *   Description :Nominee Confirmation modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";


// type  1 ---- success
// else failed

const NomineeConfirmationModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("nominee-confirm");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    const _confirmNominee = async () => {
        try {
            const result = await props.confirmNominee();
            if (result === true) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.nomineeConfirmed();
            }
        } catch (error) {

        }
    }
    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="nominee-confirm"
                tabIndex="-1"
                aria-labelledby="nominee-confirm"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4  text-center px-3 ${style.e_content} `}>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer position-absolute right-16px top-16px d-none' />

                        <Fragment>
                            <img src={require("../../Assets/Images/KYC/investment.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2">
                                Investment account submission
                            </h5>

                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 ">
                                Once you confirm, we will submit the
                                account opening request to KRA.
                            </p>
                            <div className="row">
                                <div className="col-6">
                                    <SecondaryButton label="No, Cancel"
                                        cancel="modal"
                                        className="w-100 padding-12px-tb mt-4" />
                                </div>
                                <div className="col-6">
                                    <PrimaryButton label={"Confirm"}
                                        disabled={props.loader}
                                        className="w-100 padding-12px-tb mt-4"
                                        onPress={_confirmNominee} />
                                </div>
                            </div>

                        </Fragment>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

NomineeConfirmationModal.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default memo(NomineeConfirmationModal)