/*
 *   File : data-table.js
 *   Author : https://evoqins.com
 *   Description : Data table component
 *   Integrations : react-data-table-component
 *   Version : 1
 */

/*import packages*/
import React from 'react';
import DataTable from 'react-data-table-component';
import { Icon } from '../Icon';

export default function DataTableContainer(props) {

    return (
        <>
            <DataTable columns={props.columns}
                data={props.data}
                customStyles={props.customStyles}
                paginationPerPage={10}
                paginationDefaultPage={props.paginationDefaultPage}
                paginationTotalRows={props.paginationTotalRows}
                pagination={props.pagination}
                selectableRows={props.selectableRows}
                paginationIconPrevious={props.paginationIconPrevious}
                paginationIconNext={props.paginationIconNext}
                onRowClicked={(e) => props.rowClick(e)}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
                paginationComponentOptions={props.paginationComponentOptions}
                paginationIconLastPage={props.paginationIconLastPage}
                paginationIconFirstPage={props.paginationIconFirstPage}
                sortIcon={<Icon icon="sort-icon" size={16} />}
                defaultSortAsc={props.defaultSortAsc}
                defaultSortFieldId={props.defaultSortFieldId}
                onRowMouseEnter={props.onRowMouseEnter}
                onRowMouseLeave={props.onRowMouseLeave}
                fixedHeader={props.fixedHeader}
                fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}
                expandableRows={props.expandableRows}
                expandableRowsComponent={props.expandableRowsComponent} />
        </>
    );
}

DataTableContainer.defaultProps = {
    rowClick: () => { },
}


