/*
*   File : list-nominees.js
*   Author : https://evoqins.com
*   Description : UI page to show the added nominees to the investment account.
*   Version : 1.0.0
*/

import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Header } from "../../Components/Header";
import { Breadcrumb, Loader } from "../../Components/Other";
import { PrimaryButton } from "../../Components/Buttons";
import { Icon } from "../../Components/Icon";
import { RadioGroup } from "../../Components/FormElements";
import { ConfirmInvest, KYCConfirmationModal } from "../../Components/Modal";
import { toast } from "react-toastify";
import { _generate2Fa, _getSignupProgress } from "../../Helper/api";
import APIService from "../../Services/api-service";
import { Modal } from "bootstrap";


const NOMINEE_OPTIONS = [
    {
        id: 1,
        name: "Yes"
    },
    {
        id: 2,
        name: "No"
    },
]

const ListNominees = () => {

    const navigate = useNavigate();
    const NOMINEE_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);
    const [apiLoader, setApiLoader] = useState(true);
    const [activeNominee, setActiveNominee] = useState({});

    const [optIn, setOptIn] = useState(NOMINEE_OPTIONS[1].id);
    const [modalLoader, setModalLoader] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [addNomineeLoader, setAddNomineeLoader] = useState(false);

    useEffect(() => {
        if (Object.keys(NOMINEE_DATA).length > 0) {
            if (NOMINEE_DATA.kyc_data.nominee?.length !== 0) {
                setActiveNominee(NOMINEE_DATA.kyc_data?.nominee[0]);
            }

            setApiLoader(false);
        }
    }, [NOMINEE_DATA]);

    useEffect(() => {
        if (apiLoader === false && NOMINEE_DATA.kyc_data?.is_nominee_available === true) {
            setOptIn(1);
        }
        else {
            setAddNomineeLoader(true);
        }
    }, [NOMINEE_DATA, apiLoader]);

    useEffect(() => {
        if (showOtpModal === true) {
            const modal = new Modal(document.getElementById("confirm-invest"));
            modal.show();
        }
        else {
            if (apiLoader === false) {
                if (NOMINEE_DATA.kyc_data.is_nominee_available === true) {
                    setOptIn(1);
                }
                else {
                    setOptIn(2);
                }
            }
        }
        // eslint-disable-next-line
    }, [showOtpModal]);

    useEffect(() => {
        if (apiLoader === false) {
            if (optIn === 2) {
                setAddNomineeLoader(true);
                if (NOMINEE_DATA.kyc_data.is_nominee_available === true) {
                    _generate2FaApi();
                }
            }
            else {
                setAddNomineeLoader(false)
            }
        }
        // eslint-disable-next-line
    }, [optIn]);

    useEffect(() => {
        if (showSuccess === true) {
            const modal = new Modal(document.getElementById("confirm"));
            modal.show();
        }
    }, [showSuccess]);

    // API - otp
    const _generate2FaApi = () => {
        _generate2Fa("Nominee")
            .then((response) => {
                setShowOtpModal(true);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };

    function _handleNavigate() {
        navigate("/");
    }

    const _handleActiveNomiee = (item) => {
        setActiveNominee(item);
    }

    const _addNominee = () => {
        navigate("add-nominee")
    }

    // opting in or out
    function _handleNomineeType(id) {
        setOptIn(id);
    }

    // handle confirm investment
    function _confirmNominee(status) {
        setShowSuccess(status);
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/nominee");
    }

    function _handleCloseConfirm() {
        setShowOtpModal(false);
    }

    // API -save nominee
    const _saveNominee = async (otp) => {
        setModalLoader(true);
        toast.dismiss();

        const url = "/profile/upsert-nominee";

        const request = {
            "nominee": [],
            "is_nominee_available": false,
            "otp": otp
        }

        try {
            const response = await APIService(true, url, request);
            if (response.status_code === 200) {
                _getSignupProgress().then((response) => {
                    _confirmNominee(true);
                })
                return true;
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setModalLoader(false);
                return false;
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setModalLoader(false);
            return false;
        }
    };

    const InfoDetail = (props) => {
        return (
            <Fragment>
                <p className="e-montserrat-medium e-font-14 e-line-height-24 color-outer-space mb-1">{props.heading}</p>
                <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">{props.detail}</p>
            </Fragment>
        )
    }

    return (
        apiLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    {/* Header */}
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">

                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Manage Nominee"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <p className="e-montserrat-semi-bold e-font-18 e-line-height-38 color-dark-jungle-green margin-12px-bottom margin-30px-top">Manage Nominee</p>

                                    {NOMINEE_DATA.kyc_data?.is_nominee_available === true && <div className="pt-3 d-lg-none d-block">
                                        <div className="d-flex align-items-center gap-4px cursor-pointer e-bg-light-lavender border-radius-44px w-max-content padding-10px-tb ps-2 pe-3"
                                            onClick={_addNominee}>
                                            <Icon icon="add"
                                                size={24} />
                                            <span className="e-montserrat-medium e-font-14 e-line-height-18 color-rebecca-purple">Add another nominee</span>
                                        </div>

                                    </div>}
                                </div>
                                {(NOMINEE_DATA.kyc_data?.status === "INVESTED" || NOMINEE_DATA.kyc_data?.status === "ACCOUNT_CREATED") &&
                                    <Fragment>
                                        <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent`}>
                                            Do you want to add a nominee to your investment account?
                                            <span className='color-deep-carmine-pink'>
                                                *
                                            </span>
                                        </p>
                                        <RadioGroup data={NOMINEE_OPTIONS}
                                            selected={optIn}
                                            handleSelect={_handleNomineeType} />
                                    </Fragment>}
                                {
                                    NOMINEE_DATA.kyc_data?.is_nominee_available === false ?
                                        <Fragment>
                                            <div className="row d-flex justify-content-center margin-70px-top text-center">
                                                <div className="col-lg-4 col-md-6 col-sm-8">
                                                    <img src={require("../../Assets/Images/Profile/Manange-nominee.png")} alt="Manage nominee"
                                                        width={282}
                                                        height={210}
                                                    />
                                                    <p className="e-montserrat-medium e-font-16 e-line-height-26 color-outer-space margin-32px-bottom margin-12px-top">We recommend adding a nominee
                                                        to ensure seamless continuation of your investments</p>
                                                </div>
                                            </div>
                                            {
                                                (NOMINEE_DATA.kyc_data.status === "INVESTED" || NOMINEE_DATA.kyc_data.status === "ACCOUNT_CREATED") &&
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-sm-3 col-6">
                                                        <PrimaryButton className="padding-12px-tb w-100 border-radius-12px"
                                                            disabled={addNomineeLoader}
                                                            showLoader={false}
                                                            label="Add Nominee"
                                                            onPress={_addNominee} />
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>
                                        :
                                        <div className="row row-gap-16px">
                                            <div className="col-xl-4 col-lg-4">
                                                <div className="e-profile-border border-radius-24px d-lg-block d-none mt-3">
                                                    {
                                                        NOMINEE_DATA.kyc_data?.nominee.map((item, key) =>
                                                            <div className={`${activeNominee.nominee_id === item.nominee_id && "e-bg-light-lavender"} 
                                                        ${key === 0 && "border-radius-tlr-24px"} 
                                                        ${key !== NOMINEE_DATA.kyc_data.nominee.length - 1 && "border-bottom-1px border-chinese-white"}
                                                        px-4 pt-3 padding-12px-bottom cursor-pointer`}
                                                                key={key}
                                                                onClick={() => _handleActiveNomiee(item)}>
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-6px-bottom color-outer-space">Nominee-{key + 1}</p>
                                                                <div className="row d-flex align-items-center">
                                                                    <div className="col-8">
                                                                        <p className="e-montserrat-medium e-font-16 e-line-height-22 color-eerie-black mb-0">{item.name}</p>
                                                                        <p className="e-montserrat-medium e-font-16 e-line-height-22 color-dim-grey mb-0">{item.relation}</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p className="e-montserrat-semi-bold e-font-16 e-line-height-22 color-eerie-black mb-0">{item.allocation}%</p>
                                                                            <Icon icon="arrow-right"
                                                                                size={24} />
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }


                                                    <div className="px-4 py-3 border-top-1px border-chinese-white">
                                                        <div className="d-flex align-items-center gap-4px cursor-pointer e-bg-light-lavender border-radius-44px w-max-content padding-10px-tb ps-2 pe-3"
                                                            onClick={_addNominee}>
                                                            <Icon icon="add"
                                                                size={24} />
                                                            <span className="e-montserrat-medium e-font-14 e-line-height-18 color-rebecca-purple">Add another nominee</span>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="d-lg-none d-block  ">
                                                    <div className="overflow-x-auto d-flex  white-space-nowrap gap-8px">
                                                        {
                                                            NOMINEE_DATA.kyc_data?.nominee.map((item, key) =>
                                                                <div className={`${activeNominee.nominee_id === item.nominee_id ? "e-bg-light-lavender border color-rebecca-purple" : "color-eerie-black border"} 
                                                        border-radius-24px px-3 py-2 cursor-pointer color-eerie-black e-montserrat-medium e-font-12 e-line-height-14`}
                                                                    key={key}
                                                                    onClick={() => _handleActiveNomiee(item)}>
                                                                    {item.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-xl-8 col-lg-8">
                                                <div className="e-profile-border border-radius-24px">
                                                    <div className="p-4 d-flex align-items-center justify-content-between border-bottom-1px border-chinese-white">
                                                        <p className="e-montserrat-semi-bold e-font-18 e-line-height-30 color-eerie-black mb-0">Nominee-{NOMINEE_DATA.kyc_data?.nominee.findIndex(item => item.nominee_id === activeNominee.nominee_id) + 1}</p>
                                                        <span>
                                                            <Icon icon="edit-border"
                                                                size={40}
                                                                className="cursor-pointer"
                                                                onClick={_addNominee} />
                                                        </span>
                                                    </div>
                                                    <div className="px-4 pb-4 pt-3">
                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black">Nominee details</p>
                                                        <div className="row row-gap-16px mb-xl-4 mb-0">
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4">
                                                                <InfoDetail heading="Nominee name"
                                                                    detail={activeNominee.name} />
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4">
                                                                <InfoDetail heading="Relation"
                                                                    detail={activeNominee.relation} />
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4">
                                                                <InfoDetail heading="Guardian name"
                                                                    detail={activeNominee.guardian_name === null ? "-" : activeNominee.guardian_name} />
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4">
                                                                <InfoDetail heading="Guardian pan"
                                                                    detail={activeNominee.guardian_pan === null ? "-" : activeNominee.guardian_pan} />
                                                            </div>

                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4">
                                                                <InfoDetail heading="Allocation"
                                                                    detail={activeNominee.allocation + "%"} />
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4">
                                                                <InfoDetail heading="Guardian relationship"
                                                                    detail={activeNominee.guardian_relationship === null ? "-" : activeNominee.guardian_relationship} />
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4">
                                                                <InfoDetail heading="Date of birth"
                                                                    detail={activeNominee.dob_str} />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {showOtpModal === true &&
                    <ConfirmInvest closeModal={_handleCloseConfirm}
                        verify={_saveNominee}
                        loader={modalLoader} />
                }

                {
                    showSuccess &&
                    <KYCConfirmationModal title={"Opted out successfully"}
                        type={1}
                        show_button={true}
                        content="Torem ipsum dolor sit amet, consectetur adipiscing elit."
                        closeModal={() => _confirmNominee(false)}
                        handleNavigate={_handleCurrentNavigate} />
                }
            </Fragment>
    )
}


export default ListNominees