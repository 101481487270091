/*
 *   File : header.js
 *   Author : https://evoqins.com
 *   Description : Header component
 *   Version : 1.0.0
*/

// import packages
import { memo, Fragment } from "react";

// import components
import { Icon } from "../Icon";
import { useNavigate } from "react-router-dom/dist";


const CustomHeader = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="container">
                <div className="d-flex flex-wrap justify-content-between align-items-center padding-14px-tb px-sm-0 px-4 row-gap-4px">
                    <img src={require("../../Assets/Images/Global/logo.png")}
                        alt="logo"
                        height={48}
                        width={89}
                        draggable={false}
                        className="e-logo mb-2"
                        onClick={() => navigate("/")} />
                    <div className="d-flex flex-wrap justify-content-end align-items-center gap-8px">
                        <Icon icon="mail"
                            size={20} />
                        <a href="mailto:helpdesk@mintit.in" rel="noreferrer" className="e-link pt-1 mb-0 no-decoration color-charleston-green e-font-14 e-line-height-24 e-montserrat-semi-bold">
                            helpdesk@mintit.in
                        </a>
                        <Icon icon="phone"
                            size={20}
                            className="ms-2" />
                        <a href="tel:+918956915040" rel="noreferrer" className="e-link pt-1 mb-0 no-decoration color-charleston-green e-font-14 e-line-height-24 e-montserrat-semi-bold">
                            +91 8956915040
                        </a>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default memo(CustomHeader)