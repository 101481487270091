
/*
*   File : basket-order.js
*   Author : https://evoqins.com
*   Description :Basket Order detail
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb } from "../../Components/Other";
import { Icon } from "../../Components/Icon";

// import services
import APIService from "../../Services/api-service";
import { _getOrderStatus, _getTransactionStatus } from "../../Helper/helper";

//  transaction status data
const TRANSACTION_STATUS_DATA = ['Order Initiated', 'Payment Received from', 'Order Captured', 'Units Allocated'];
const STEP_DATA = ['order_initiated', 'payment_bank', 'order_captured', 'units_allocated'];
const TRANSACTION_STATUS = ['order_initiated_status', 'payment_received_status', 'order_captured_status', 'units_allocated_status'];

// withdraw status data
const WITHDRAW_STEP_DATA = ['order_initiated', 'order_captured', 'payment_received'];
const WITHDRAW_TRANSACTION_STATUS = ['order_initiated_status', 'order_captured_status', 'payment_received_status'];
const WITHDRAW_STATUS_DATA = ['Order Initiated', 'Order Captured', 'Amount Sent To Bank'];


const BasketOrders = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [screenLoader, setScreenLoader] = useState(true);
    const [funds, setFunds] = useState([]);
    const [orderDetail, setOrderDetail] = useState({});

    useEffect(() => {
        if (location.state !== null) {
            _getOrderDetails(location.state.id);
        }
        // eslint-disable-next-line
    }, [location])

    useEffect(() => {
        // Add the event listener
        window.addEventListener('popstate', _handleCurrentNavigate);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', _handleCurrentNavigate);
        };
        //eslint-disable-next-line
    }, []);

    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }
    const _handleFundDetail = (id) => {
        navigate("/explore/detail", {
            state: {
                data: {
                    mf_id: id
                },
            }
        })
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/orders", {
            state: {
                type: location.state.type,
                paymentTypes: location.state.paymentTypes,
                orderTypes: location.state.orderTypes,
                orderStatus: location.state.orderStatus,
                startDate: location.state.startDate,
                endDate: location.state.endDate,
                page: location.state.page
            }
        });
    }

    // API - order details
    const _getOrderDetails = (id) => {
        const url = "/order/detail";
        const request = {
            order_id: id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setOrderDetail(response.data.summary);
                setFunds(response.data.funds);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
                navigate("/orders")
            }
            setScreenLoader(false);
        });
    }



    return (
        screenLoader === true ?
            <div className="h-80vh d-flex align-items-center justify-content-center">
                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                    alt={"loader"}
                    width={60}
                    height={60}
                />
            </div>
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 padding-44px-bottom">
                        <section className="px-sm-4 px-3">
                            <Breadcrumb mainPage="Home"
                                currentPage="Orders"
                                type={2}
                                childPage={orderDetail.name}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate} />

                            <Icon icon="Arrow-Right"
                                size={32}
                                className="mb-3 margin-32px-top e-transform-left-arrow cursor-pointer"
                                onClick={_handleCurrentNavigate} />
                            <div className="row row-gap-16px">
                                <div className="col-xl-8 col-lg-10 order-lg-1 order-2">
                                    <div className="box-shadow-black border-radius-24px pt-sm-4 pt-3">
                                        <div className="d-flex align-items-start gap-8px pb-sm-4 pb-3 px-sm-4 px-3 border-bottom-1px border-chinese-white">
                                            <img src={orderDetail.icon}
                                                width={48}
                                                height={48}
                                                alt={orderDetail.scheme_name}
                                                draggable={false}
                                                className="border-radius-12px e-fund-detail-chart object-fit-contain d-sm-block d-none" />
                                            <img src={orderDetail.icon}
                                                width={38}
                                                height={38}
                                                alt={orderDetail.scheme_name}
                                                draggable={false}
                                                className="border-radius-12px e-fund-detail-chart object-fit-contain d-sm-none d-block" />
                                            <div className="d-flex gap-8px flex-column">
                                                <p className="color-eerie-black e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{orderDetail.name}</p>
                                                <div className="d-flex align-items-center color-outer-space e-font-12 e-line-height-16 e-montserrat-regular">
                                                    <span className="e-bg-lavender-frost padding-6px-tb px-2 color-rebecca-purple e-font-12 e-line-height-14 e-montserrat-medium border-radius-30px me-3">{orderDetail.order_no}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="pt-sm-3 pt-2 pb-sm-4 pb-2 mx-sm-4 mx-3 border-bottom-1px border-chinese-white">
                                            <h6 className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black mb-3">Order details</h6>
                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-3 color-outer-space">Order type <span className="e-montserrat-semi-bold color-eerie-black">({orderDetail.type})</span></p>

                                            <div className="row">
                                                <div className="col-sm-3 col-6 pb-md-4 pb-2">
                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Date</p>
                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{orderDetail.order_date}</p>
                                                </div>
                                                <div className="col-sm-3 col-6 pb-md-4 pb-2">
                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Amount</p>
                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{orderDetail.amount_text}</p>
                                                </div>
                                                <div className="col-sm-3 col-6 pb-md-4 pb-2">
                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Order Type</p>
                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{orderDetail.type}</p>
                                                </div>

                                                <div className="col-sm-3 col-6 pb-0 mb-1">
                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Payment Status</p>
                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${_getOrderStatus(orderDetail.payment_status).color}`}>
                                                        <img src={_getOrderStatus(orderDetail.payment_status).icon}
                                                            alt="Success"
                                                            draggable={false}
                                                            className="me-1" />
                                                        {orderDetail.payment_status}
                                                    </p>
                                                </div>
                                                <div className="col-sm-3 col-6 ">
                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 mb-1 color-outer-space">Order Status</p>
                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${_getOrderStatus(orderDetail.order_status).color}`}>
                                                        <img src={_getOrderStatus(orderDetail.order_status).icon}
                                                            alt="Success"
                                                            draggable={false}
                                                            className="me-1" />
                                                        {orderDetail.order_status}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="pt-sm-3 pt-2 mx-sm-4 mx-3">
                                            <h6 className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black mb-3">{orderDetail.total_funds > 1 ? "Funds" : "Fund"} ({orderDetail.total_funds})</h6>
                                            {
                                                funds.map((data, index) => {
                                                    return (
                                                        <div className={index > 0 ? 'border-top-1px border-chinese-white py-sm-4 py-3' : 'pb-sm-4 pb-3'}>
                                                            <div className="d-flex align-items-center gap-8px cursor-pointer"
                                                                onClick={() => _handleFundDetail(data.mf_id)}>
                                                                <img src={data.icon}
                                                                    width={48}
                                                                    height={48}
                                                                    alt={data.scheme_name}
                                                                    draggable={false}
                                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain d-sm-block d-none" />
                                                                <img src={data.icon}
                                                                    width={38}
                                                                    height={38}
                                                                    alt={data.scheme_name}
                                                                    draggable={false}
                                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain d-sm-none d-block" />
                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{data.fund_name}</p>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-4 col-6 pt-sm-3 pt-2">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">Amount</p>
                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 color-eerie-black`}>{data.amount_text}</p>
                                                                </div>
                                                                <div className="col-sm-4 col-6 pt-sm-3 pt-2">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">Units</p>
                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 color-eerie-black`}>{data.units ? data.units : 0}</p>
                                                                </div>
                                                                <div className="col-sm-4 col-6 pt-sm-3 pt-2">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">Transaction ID</p>
                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 color-eerie-black`}>{data.transaction_id}</p>
                                                                </div>
                                                                <div className="col-sm-4 col-6 pt-sm-4 pt-2">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">NAV</p>
                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 color-eerie-black`}>{data.nav ? data.nav : "-"}</p>
                                                                </div>
                                                                <div className="col-sm-4 col-6 pt-sm-4 pt-2">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-1 color-outer-space">Allocated Date</p>
                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 color-eerie-black`}>{data.units_allocated ? data.units_allocated : "-"}</p>
                                                                </div>
                                                            </div>
                                                            {
                                                                orderDetail.order_status !== "Pending" &&
                                                                <Fragment>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 mb-3 color-eerie-black pt-4">Transaction status</p>

                                                                    <div className="d-md-flex d-none">
                                                                        {
                                                                            (orderDetail.type !== 'Withdraw') ?
                                                                                TRANSACTION_STATUS_DATA.map((item, index) => {
                                                                                    let transaction_status_param = TRANSACTION_STATUS[index]; // key for the status
                                                                                    let status = data[transaction_status_param];
                                                                                    let step_data = _getTransactionStatus(status, index);
                                                                                    return (
                                                                                        <div className={`e-border gap-12px position-relative w-35 z-index-3 bg-white pe-1`}>
                                                                                            <div className={`e-progress-image d-flex align-items-center justify-content-start`}>
                                                                                                <img src={step_data.icon} alt={"progress"}
                                                                                                    draggable={false}
                                                                                                />
                                                                                            </div>
                                                                                            {
                                                                                                index === 1 ?
                                                                                                    <div className="d-block padding-12px-top pe-4">
                                                                                                        <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                                            Payment Received from  {data.payment_bank}
                                                                                                        </p>
                                                                                                        <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{data[STEP_DATA[index]]}</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="d-block padding-12px-top">
                                                                                                        <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                                            {item}
                                                                                                        </p>
                                                                                                        <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{data[STEP_DATA[index]]}</p>
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                :
                                                                                WITHDRAW_STATUS_DATA.map((item, index) => {
                                                                                    let transaction_status_param = WITHDRAW_TRANSACTION_STATUS[index]; // key for the status
                                                                                    let status = data[transaction_status_param];
                                                                                    let step_data = _getTransactionStatus(status, index);
                                                                                    return (
                                                                                        <div className={`e-border gap-12px position-relative w-35 z-index-3 bg-white pe-1`}>
                                                                                            <div className={`e-progress-image d-flex align-items-center justify-content-start`}>
                                                                                                <img src={step_data.icon} alt={"progress"}
                                                                                                    draggable={false}
                                                                                                />
                                                                                            </div>
                                                                                            {

                                                                                                <div className="d-block padding-12px-top">
                                                                                                    <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                                        {item}
                                                                                                    </p>
                                                                                                    <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{data[WITHDRAW_STEP_DATA[index]]}</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                        }

                                                                    </div>
                                                                    <div className="d-md-none d-block">

                                                                        {
                                                                            (orderDetail.type !== 'Withdraw') ?
                                                                                TRANSACTION_STATUS_DATA.map((item, index) => {
                                                                                    let transaction_status_param = TRANSACTION_STATUS[index]; // key for the status
                                                                                    let status = data[transaction_status_param];
                                                                                    let step_data = _getTransactionStatus(status, index);
                                                                                    return (
                                                                                        <div className={`e-vertical-border gap-12px  d-flex align-items-start z-index-3 bg-white ${index > 0 ? 'pt-5' : ''}`}>
                                                                                            <div className={`e-progress-image d-flex align-items-start justify-content-start position-relative`}>
                                                                                                <img src={step_data.icon} alt={"progress"}
                                                                                                    draggable={false}
                                                                                                    className="bg-white z-index-2"
                                                                                                />
                                                                                            </div>
                                                                                            {
                                                                                                index === 1 ?
                                                                                                    <div className="d-block">
                                                                                                        <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                                            Payment Received from  {data.payment_bank}
                                                                                                        </p>
                                                                                                        <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{data[STEP_DATA[index]]}</p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="d-block">
                                                                                                        <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                                            {item}
                                                                                                        </p>
                                                                                                        <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{data[STEP_DATA[index]]}</p>
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                :
                                                                                WITHDRAW_STATUS_DATA.map((item, index) => {
                                                                                    let transaction_status_param = WITHDRAW_TRANSACTION_STATUS[index]; // key for the status
                                                                                    let status = data[transaction_status_param];
                                                                                    let step_data = _getTransactionStatus(status, index);
                                                                                    return (
                                                                                        <div className={`e-vertical-border gap-12px  d-flex align-items-start z-index-3 bg-white ${index > 0 ? 'pt-5' : ''}`}>
                                                                                            <div className={`e-progress-image d-flex align-items-start justify-content-start position-relative`}>
                                                                                                <img src={step_data.icon} alt={"progress"}
                                                                                                    draggable={false}
                                                                                                    className="bg-white z-index-2"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="d-block">
                                                                                                <p className={`e-montserrat-medium e-font-14 e-line-height-20 mb-1 ${step_data.color}`}>
                                                                                                    {item}
                                                                                                </p>
                                                                                                <p className="color-dark-slate e-montserrat-medium e-font-12 e-line-height-20 mb-0">{data[WITHDRAW_STEP_DATA[index]]}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                        }
                                                                    </div>
                                                                </Fragment>
                                                            }

                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </section>
                    </div>

                </div >
            </div >
    )
}
export default BasketOrders