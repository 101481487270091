/*
 *   File : consent.js
 *   Author : https://evoqins.com
 *   Description : Consent card component
 *   Integrations : null
 *   Version : 1
*/

import { Fragment, useState } from "react"
import { LinkButton } from "../Buttons"
import { Icon } from "../Icon";


import Color from '../../Styles/color.module.scss'

const ConsentCard = (props) => {
    const [showMore, setShowMore] = useState(false);

    function _handleToggle() {
        setShowMore(!showMore)
    }
    return (
        <Fragment>
            <div className="d-flex align-items-start gap-8px mt-3 w-fit-content text-start p-1" style={props.error === true ? { border: `1px solid ${Color.deep_carmine_pink}` } : { border: `1px solid transparent` }}>
                <span onClick={() => props.setAcceptedTerms()}>
                    <Icon icon={props.acceptedTerms ? "checked" : "unchecked"}
                        size={18}
                        className="cursor-pointer" />
                </span>
                {
                    showMore ?
                        <p className="mb-0 e-montserrat-regular e-font-12 e-line-height-20 letter-spacing-007em ">
                            I/We have read and understood the <LinkButton className="e-font-12 e-line-height-20"
                                onPress={() => window.open("https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=3", "_blank")}
                                label="KIM" /> and <LinkButton className="e-font-12 e-line-height-20"
                                    onPress={() => window.open("https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=2", "_blank")}
                                    label="SID" /> and contents of the Offer Document(s), Load Structure(s) of the respective Scheme(s) and agree to abide by the terms, conditions, rules and regulations of the respective Scheme(s).
                            The ARN holder has disclosed to me/us all the commissions in the form of trail commission or any other mode), payable to him for the various Mutual Funds.
                            Disclaimer: The Registrar of the AMC will allot the number of units for the total investment amount. The number of Units allotted by the Registrar will reflect in your online account statement within 2 working days. <LinkButton className="e-font-12 e-line-height-20"
                                onPress={_handleToggle}
                                label="View less" />
                        </p>
                        :
                        <p className="mb-0 e-montserrat-regular e-font-12 e-line-height-20 letter-spacing-007em">
                            I/We have read and understood the <LinkButton className="e-font-12 e-line-height-20"
                                onPress={() => window.open("https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=3", "_blank")}
                                label="KIM" /> and <LinkButton className="e-font-12 e-line-height-20"
                                    onPress={() => window.open("https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=2", "_blank")}
                                    label="SID" /> and contents of the Offer Document(s), Load ... <LinkButton className="e-font-12 e-line-height-20 d-block"
                                        onPress={_handleToggle}
                                        label="View more" />

                        </p>
                }
            </div>

        </Fragment>
    )
}
export default ConsentCard
