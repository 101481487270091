/*
*   File : listing.js
*   Author : https://evoqins.com
*   Description : Screen to list all goals fro Investment
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader } from "../../Components/Other";
import { GoalCard } from "../../Components/Cards";

// import services
import { _getGoalsList } from "../../Helper/api";
import { LinkButton } from "../../Components/Buttons";
import { _forceNavigate } from "../../Helper/helper";


const GoalListing = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiLoader, setApiLoader] = useState(true);
    const [goalList, setGoalList] = useState([]);
    const [otherGoals, setOtherGoals] = useState([]);
    const [viewOtherGoals, setViewOtherGoals] = useState(false);

    _forceNavigate(() => {
        //eslint-disable-next-line
        if (location.pathname == "/milestones") {
            navigate(-2)
        }
    });

    function _handleNavigate() {
        navigate("/");
    }

    useEffect(() => {
        _getGoals();
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.other) {
                setViewOtherGoals(location.state.other)
            }
        }
        // eslint-disable-next-line
    }, [])

    function _handleOtherGoals() {
        setViewOtherGoals(!viewOtherGoals);
    }

    // API - get goals list
    const _getGoals = async () => {
        await _getGoalsList().then((response) => {
            setGoalList(response.goal);
            setOtherGoals(response.other_goal);
            setApiLoader(false);
        }).catch((error) => {
            setApiLoader(false);
        })
    }
    return (
        apiLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    {/* Header */}
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">



                            {/* Goal list */}
                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Milestones"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <h5 className="margin-30px-tb e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green">Milestones</h5>
                                <div className="row">
                                    {
                                        goalList.map((item, index) => {
                                            return (
                                                <div key={index} className="col-lg-2 col-md-4 col-6 pb-4">
                                                    <GoalCard data={item} />
                                                </div>
                                            )
                                        })
                                    }
                                    {otherGoals.length !== 0 && viewOtherGoals === false && <div className="col-12 text-center pb-4">
                                        <LinkButton label={"View other milestones"} className="e-font-14"
                                            onPress={_handleOtherGoals} />
                                    </div>}
                                    {
                                        viewOtherGoals === true &&
                                        <Fragment>
                                            <h5 className="mb-3 e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green">Other Milestones</h5>
                                            {
                                                otherGoals.map((item, index) => {
                                                    return (
                                                        <div key={index} className="col-lg-2 col-md-4 col-6 pb-4">
                                                            <GoalCard data={item}
                                                                other={true} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    }
                                    {viewOtherGoals === true && <div className="col-12 text-center ">
                                        <LinkButton label={"Hide other milestones"} className="e-font-14"
                                            onPress={_handleOtherGoals} />
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default GoalListing