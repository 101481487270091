/*
*   File : about-us.js
*   Author : https://evoqins.com
*   Description : About us
*   Version : 1.0.0
*/

import { useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header";
import { Breadcrumb } from "../../Components/Other";

const OUR_VALUES = [{
    title: "Transparent",
    description: "We provide clear, understandable information. about your investments and our fees, empowering you to make informed decisions."
},
{
    title: "Reliable",
    description: "Our platform is built on robust technology and backed by a dedicated support team, ensuring a smooth and consistent user experience."
},
{
    title: "User-Centric",
    description: "We prioritize your needs and milestones, tailoring our services to your unique financial situation."
},
{
    title: "Secure",
    description: "We employ state-of-the-art security measures to protect your personal and financial information, ensuring your peace of mind."
},
{
    title: "Tailored",
    description: "Your financial journey is unique, and we offer personalized investment solutions to meet your specific milestones."
}]

const MASTERS = [{
    image: require("../../Assets/Images/About-us/ca-neha-agrawal.png"),
    name: "CA Neha Agrawal",
    title: "Founder & CEO"
},
{
    image: require("../../Assets/Images/About-us/aniket-mane.png"),
    name: "Aniket Mane",
    title: "CMO & Founding Member "
},
{
    image: require("../../Assets/Images/About-us/ajay-patwari.png"),
    name: "Ajay Patwari",
    title: "Co Founder & Advisor"
},
{
    image: require("../../Assets/Images/About-us/ca-mahendra-kamath.png"),
    name: "CA Mahendra Kamath",
    title: "Co Founder & Advisor"
}]

const AboutUs = () => {

    const navigate = useNavigate();

    function _handleNavigate() {
        navigate("/");
    }



    return (
        <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
            {/* Header */}
            <Header type={2} />

            <div className="row ">
                <div className="col-lg-11 pe-lg-0 padding-144px-bottom">
                    <div className="px-4">
                        <Breadcrumb mainPage="Home"
                            currentPage="About us"
                            handleNavigate={_handleNavigate}
                            type={1} />
                        <div className="border-1px border-platinum-about border-radius-24px p-5 d-flex justify-content-center margin-44px-top">
                            <img src={require("../../Assets/Images/About-us/about-us.png")} alt="Logo"
                                width={296}
                                height={224}
                                draggable={false}
                                className="object-fit-contain" />

                        </div>

                        <h3 className="e-montserrat-semi-bold e-font-28 e-line-height-44 color-rebecca-purple margin-64px-top margin-20px-bottom">Simple, Personalised, Collaborative.</h3>
                        <p className="e-montserrat-regular e-font-18 e-line-height-30 color-outer-space margin-48px-bottom">A perfect trio, that makes Mintlt the most loved Mutual fund management app amongst its users and investors. Our user-friendly platform makes it easy for anyone, from beginners to seasoned investors, to build diversified portfolios, track performance, and access educational resources - all with the goal of making financial growth accessible and empowering. Our intuitive app allows users to explore, invest in, and manage a wide range of funds with ease, offering a seamless and secure experience at their finger-tips.</p>
                        <div className="row d-flex row-gap-32px">
                            <div className="col-md-6">
                                <p className="e-montserrat-medium e-font-20 e-line-height-30 color-dark-jungle-green mb-2">Our vision</p>
                                <p className="e-montserrat-regular e-font-16 e-line-height-26 mb-0">Our goal is to be recognized as the most Trusted Mutual Fund App and Solution Provider, ensuring and encouraging the achievable growth of our clients and investors at large.</p>
                            </div>
                            <div className="col-md-6">
                                <p className="e-montserrat-medium e-font-20 e-line-height-30 color-dark-jungle-green mb-2">Our mission</p>
                                <p className="e-montserrat-regular e-font-16 e-line-height-26 mb-0">Our mission is to empower investors at large to achieve financial well-being and independence through personalized guidance, innovative strategies, and unwavering integrity Also fostering a community of responsible and informed investors committed to achieving financial well-being and driving positive change in the world.</p>
                            </div>
                        </div>

                        <h3 className="e-montserrat-semi-bold e-font-28 e-line-height-44 color-rebecca-purple margin-64px-top margin-48px-bottom">Our Values</h3>
                        <div className="row d-flex row-gap-64px">

                            {
                                OUR_VALUES.map((item, key) =>
                                    <div className="col-md-4 col-sm-6 " key={key}>
                                        <p className="e-montserrat-medium e-font-20 e-line-height-30 color-dark-jungle-green mb-2">{item.title}</p>
                                        <p className="e-montserrat-regular e-font-16 e-line-height-26 mb-0">{item.description}</p>
                                    </div>
                                )
                            }
                        </div>

                        <h3 className="e-montserrat-semi-bold e-font-28 e-line-height-44 color-rebecca-purple margin-64px-top margin-32px-bottom">Meet the Minting Masters</h3>
                        <div className="row margin-64px-bottom d-flex row-gap-32px">
                            {
                                MASTERS.map((item, key) =>
                                    <div className="col-lg-3 col-sm-6 d-flex justify-content-center align-items-center flex-column" key={key}>
                                        <img src={item.image}
                                            alt={item.name}
                                            width={80}
                                            height={80}
                                            draggable={false} />
                                        <p className="e-montserrat-semi-bold e-font-18 e-line-height-28 color-eerie-black margin-20px-top mb-0">{item.name}</p>
                                        <p className="e-montserrat-regular e-font-16 e-line-height-24 color-rebecca-purple mb-0">{item.title}</p>
                                    </div>
                                )
                            }
                        </div>
                        <p className="text-center e-montserrat-regular e-font-20 e-line-height-36 color-eerie-black mb-0">Visit <a href="www.mintit.in" className="color-rebecca-purple">www.mintit.in</a> for more details or reach out to the <span
                            class="d-md-inline d-none"><br /></span>helpdesk at <a href="helpdesk@mintit.in"
                                className="color-rebecca-purple">helpdesk@mintit.in</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;