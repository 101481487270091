
/*
 *   File : pyramid-information.js
 *   Author URI : https://evoqins.com
 *   Description :Financial pyramid information modal
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment, useEffect } from "react";

// Custom components
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";
import Color from '../../Styles/color.module.scss';

const PYRAMID_DATA = [
    { 'label': 'Red', 'value': 'Incomplete', 'color': '#FF0000' },
    { 'label': 'Amber', 'value': ' Partially complete', 'color': '#FFBF00' },
    { 'label': 'Yellow', 'value': ' Almost complete', 'color': '#FFFF00' },
    { 'label': 'Green', 'value': ' Complete', 'color': '#00FF00' },
];

const PyramidInformation = React.memo((props) => {

    useEffect(() => {
        var my_modal = document.getElementById("pyramid-information");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="pyramid-information"
                tabIndex="-1"
                aria-labelledby="pyramid-information"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-36px-bottom  ${style.e_content} `}>
                        <div className="border-bottom-1px border-ash-gray px-4">
                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                    Financial Pyramid Information
                                </h5>
                                <Icon icon="close-btn"
                                    size={24}
                                    color={Color.eerie_black}
                                    id="close-modal"
                                    className="cursor-pointer"
                                    data-bs-dismiss="modal" />
                            </div>
                        </div>
                        <div className="px-4 mt-4">
                            {
                                PYRAMID_DATA.map((item) => {
                                    return (
                                        <p className="e-montserrat-medium e-font-16 e-line-height-28 mb-1" style={{ color: item.color }}>
                                            {item.label} <span className="color-charcoal-gray">- {item.value}</span>
                                        </p>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

export default PyramidInformation;