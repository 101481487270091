/*
 *   File : progress.js
 *   Author : https://evoqins.com
 *   Description :Component to show Progress of step 
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react"


const StepProgress = (props) => {
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const step_list = []
        for (let i = 1; i <= props.maxStep; i++) {
            step_list.push(i);
            setSteps(step_list)
        }
    }, [props.maxStep])

    return (
        <Fragment>

            {
                props.type === 2 ?
                    <Fragment>
                        <p className="mb-2 color-yale-blue e-font-14 e-line-height-20 e-montserrat-semi-bold">{props.stepNumber} of {props.maxStep} steps</p>
                        <div className="row w-100">
                            {
                                steps.map((item, index) => {
                                    return (
                                        <div key={index} className={index > 0 ? `col px-1` : `col pe-1`}>
                                            <div className={`h-6px w-100 border-radius-36px ${(item - 1 <= props.step) ? "e-bg-yale-blue" : "e-bg-light-periwinkle"}`} ></div>
                                            {/* onClick={() => (item.step - 1 <= props.step) && props.gotToStep(item.step, index)} */}
                                            {/* ${((item.step - 1 <= props.step)) && 'cursor-pointer'} */}
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <p className="mb-2 color-yale-blue e-font-14 e-line-height-20 e-montserrat-semi-bold">{props.stepCompleted} of 3 steps</p>
                        <div className="row ">
                            <div className="col-4 pe-1">
                                <div className={`h-6px w-100 border-radius-36px  e-bg-yale-blue cursor-pointer`}
                                    onClick={() => props.stepCompleted >= 1 && props.gotToStep(1)}></div>
                            </div>
                            <div className="col-4 px-1">
                                <div className={`h-6px w-100 border-radius-36px ${props.stepCompleted >= 2 ?
                                    "e-bg-yale-blue cursor-pointer" :
                                    "e-bg-light-periwinkle"}`}
                                    onClick={() => props.stepCompleted >= 2 && props.gotToStep(2)}
                                ></div>
                            </div>
                            <div className="col-4 ps-1">
                                <div className={`h-6px w-100 border-radius-36px
                                    ${props.stepCompleted >= 3 ?
                                        "e-bg-yale-blue cursor-pointer" :
                                        "e-bg-light-periwinkle"}`}
                                    onClick={() => props.stepCompleted >= 3 && props.gotToStep(3)}
                                >

                                </div>
                            </div>
                        </div>
                    </Fragment>
            }
        </Fragment>
    )
}

StepProgress.defaultProps = {
    gotToStep: () => { }
}


export default StepProgress