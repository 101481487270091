/*
 *   File : horizontal-stacked-bar.js.js
 *   Author : https://evoqins.com
 *   Description : Horizontal stack bar chart
 *   Integrations : null
 *   Version : 1
*/

// import packages
import { Fragment, memo } from "react"

// format
// {
//     name:
//     allocation:
//     color: 
// }

const HorizontalStackedBar = (props) => {

    const allocations = props.allocations.reduce((acc, curr) => {
        const previous_allocation = acc.length === 0 ? 0 : acc[acc.length - 1];
        const new_allocation = previous_allocation + curr.allocation;
        return [...acc, new_allocation];
    }, []);

    return (
        <Fragment>
            <div className="d-flex justify-content-between mb-2">
                <span className="e-font-14 e-line-height-14 e-montserrat-medium color-slate-gray">0</span>
                <span className="e-font-14 e-line-height-14 e-montserrat-medium color-slate-gray">100 %</span>
            </div>
            <div className={`w-100 d-flex overflow-hidden position-relative border-radius-12px ${props.height ? props.height : 'h-32px'} e-bg-lavender-blush`}>
                {
                    props.allocations.map((item, index) => {
                        return (
                            <div className={`h-100 position-absolute z-index-${props.allocations.length - index}`} style={{ width: `${allocations[index]}%`, background: item.color }}></div>

                        )
                    })
                }
            </div>
            <div className={props.height ? 'd-flex white-space-nowrap overflow-x-auto' : "d-flex white-space-nowrap overflow-x-auto mt-sm-3 mt-2"}>
                {
                    props.allocations.map((item, index) => {
                        return (
                            <div key={index} className="d-flex align-items-center my-2 gap-4px me-sm-4 me-2">
                                <div className="w-10px h-10px border-radius-100px " style={{ background: item.color }}></div>
                                <span className={props.height ? `color-eerie-black e-montserrat-medium e-line-height-14 e-font-12` : `color-eerie-black e-montserrat-medium e-font-14 e-line-height-14`}>{item.name} <span className="e-font-14 e-montserrat-bold">{item.allocation}%</span></span>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

export default memo(HorizontalStackedBar)