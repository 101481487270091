/*
 *   File : section-title.js
 *   Author : https://evoqins.com
 *   Description : Common component to create section title.
 *   Integrations : NA
 *   Version : 1.0.0
*/

const SectionTitle = (props) => {
    return (
        <h1 className={`e-montserrat-semi-bold e-font-18 e-line-height-24 color-eerie-black margin-0px-bottom ${props.className}`}>{props.label}</h1>
    )
}

export default SectionTitle

SectionTitle.defaultProps = {
    className: '',
    label: "",
}