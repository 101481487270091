/*
 *   File : icon.js
 *   Author : https://evoqins.com
 *   Description : Icon component
 *   Integrations : icomoon-react
 *   Version : 1.0.0
*/
// import packages
import { memo } from "react";

import IcomoonReact from "icomoon-react";
const iconSet = require("../../Assets/Fonts/selection.json");

const Icon = ({ ...props }) => <IcomoonReact iconSet={iconSet} {...props} />;

export default memo(Icon);