/*
 *   File : kyc-progress.js
 *   Author : https://evoqins.com
 *   Description : Component to show Kyc progress
 *   Integrations : NA
 *   Version : 1.0.0
*/
// import packages
import { Fragment } from "react";
import { useSelector } from "react-redux";

// import components

// import styles


const KYCProgressCard = (props) => {

    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);

    return (
        <Fragment>
            <div className=" border-1px border-light-purple border-radius-24px box-shadow-36 p-3 w-100">
                <h6 className="mb-0  px-4 color-eerie-black e-font-16 e-line-height-24 e-montserrat-semi-bold mb-3">Invest in {PROGRESS_DATA.step_detail.length} simple steps</h6>

                <div className="e-bg-light-lavender border-radius-24px p-4">
                    <h6 className="color-eerie-black e-font-20 e-line-height-32 e-montserrat-semi-bold mb-2">Things to keep in mind</h6>
                    <p className="color-gunmental e-font-16 e-line-height-32 e-montserrat-regular mb-3">
                        Below details are needed for a smooth account opening process
                    </p>
                    <ul className="ps-4 color-eerie-black e-font-16 e-line-height-32 e-montserrat-medium">
                        {
                            PROGRESS_DATA.check_list.map((item, index) => {
                                return (
                                    <li className="mb-2" key={index}>{item}</li>
                                )
                            })
                        }
                    </ul>
                </div>

            </div>
        </Fragment>
    )
}

export default KYCProgressCard;