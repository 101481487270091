/*
 *   File : slider.js
 *   Author : https://evoqins.com
 *   Description : Custom slider component
 *   Integrations : react-slider
 *   Version : 1.0.0
*/

// import packages
import { useEffect, useState } from "react";
import ReactSlider from "react-slider";

const CustomSlider = (props) => {

    const [sliderValue, setSliderValue] = useState(props.defaultValue);

    useEffect(() => {
        setSliderValue(props.defaultValue);
    }, [props.defaultValue]);

    // slider change function
    function _handleSliderChange(selected_value) {
        if (selected_value > props.max) {
            selected_value = props.max;
        }
        setSliderValue(selected_value);
        props.handleChange(selected_value);
    }

    // slider track click function
    function _handleSliderClick(value) {
        setSliderValue(value);
        props.handleChange(value);
    }

    // rendering custom track
    function Track(props) {
        return (
            <div {...props}></div>
        )
    }

    // rendering custom thumb
    function Thumb(props) {
        return (
            <div {...props}></div>
        )
    }

    return (
        <ReactSlider
            value={sliderValue}
            max={props.max}
            min={props.min}
            step={props.step}
            thumbClassName="h-24px w-24px border-radius-50px cursor-pointer border-1px border-purple bg-white no-outline top--8px"
            trackClassName="cursor-pointer border-radius-4px h-8px e-bg-light-periwinkle slider-track"
            renderTrack={Track}
            renderThumb={Thumb}
            onChange ={_handleSliderChange}
            onSliderClick={_handleSliderClick} />
    )
};

CustomSlider.defaultProps = {
    // _handleSliderChange: () => { }
}
export default CustomSlider