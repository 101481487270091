/*
 *   File : order-filter.js
 *   Author : https://evoqins.com
 *   Description : Component to show filters for Order list
 *   Integrations : NA
 *   Version : 1.0.0
*/

// Packages
import React, { Fragment, useState } from "react";

// Components
import { CheckBoxGroup, CustomDatePicker } from "../FormElements";
import { PrimaryButton, TertiaryButton } from "../Buttons";
import { Icon } from "../Icon";


const OrderFilter = React.memo(({
    startDate,
    endDate,
    selectedPaymentTypes,
    selectedOrderTypes,
    selectedOrderStatus,
    handlePaymentTypes,
    handleOrderStatus,
    handleOrderTypes,
    handleStart,
    handleEnd,
    paymentTypes,
    orderTypes,
    orderStatus,
    getOrderList,
    investment,
    modal
}) => {
    const today = new Date();
    const disabledDays = { after: today };

    const [showPaymentType, setShowPaymentType] = useState(true);
    const [showOrderType, setShowOrderType] = useState(false);
    const [showOrderStatus, setShowOrderStatus] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    // handle toggle payment
    function _showPaymentTypes() {
        setShowPaymentType(!showPaymentType);
    }

    // handle toggle order status
    function _showOrderStatus() {
        setShowOrderStatus(!showOrderStatus);
    }

    // handle toggle order types
    function _showOrderTypes() {
        setShowOrderType(!showOrderType)
    }

    // handle toggle date range
    function _showDateRange() {
        setShowDateRange(!showDateRange)
    }

    function _parseDate(dateString) {
        console.log(dateString)
        const [day, month, year] = dateString.split("-");
        return new Date(`${year}-${month}-${day}`);
    }
    return (
        <Fragment>
            <div className="e-filter border-radius-16px d-lg-block d-none">
                <div className="d-flex justify-content-between align-items-center px-3 padding-12px-tb border-bottom-1px border-platinum">
                    <span className="e-montserrat-medium e-font-16 e-line-height-24 color-eerie-black">Filter</span>

                    <span className={`e-montserrat-semi-bold e-font-14 e-line-height-24 color-rebecca-purple py-1 px-3 e-bg-magnolia border-radius-32px cursor-pointer 
                    ${((selectedPaymentTypes.length === 0 && selectedOrderStatus.length === 0 && selectedOrderTypes.length === 0 && (startDate === "DD-MM-YYYY" || endDate === "DD-MM-YYYY")) ||
                            ((startDate !== "DD-MM-YYYY" && endDate === "DD-MM-YYYY") || (startDate === "DD-MM-YYYY" && endDate !== "DD-MM-YYYY"))) && "disabled"}`}
                        onClick={() => {
                            getOrderList([], [], [], null, null, 1, 1);
                        }}>
                        Clear all
                    </span>
                </div>

                {/* payment type */}
                <div className="py-3 mx-3 border-bottom-1px border-platinum">
                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showPaymentType === true && "mb-3"}`}
                        onClick={_showPaymentTypes}>
                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Payment status</span>
                        <Icon icon="header-drop-down"
                            size={20}
                            className={`e-explore-filter ${showPaymentType === true && "e-explore-filter-left-arrow"}`} />
                    </div>
                    {
                        showPaymentType === true &&
                        <Fragment>
                            <div className="e-amc-scroll mt-3">
                                {
                                    paymentTypes.length === 0 ?
                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                        :
                                        <CheckBoxGroup data={paymentTypes}
                                            selectedList={selectedPaymentTypes}
                                            type={1}
                                            className="flex-column"
                                            checkBoxItemClassName="my-2"
                                            checkBoxHandler={(value) => handlePaymentTypes(value)} />
                                }
                            </div>
                        </Fragment>
                    }
                </div>

                {/* order status */}
                <div className="py-3 mx-3 border-bottom-1px border-platinum">
                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showOrderStatus === true && "mb-3"}`}
                        onClick={_showOrderStatus}>
                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Order status</span>
                        <Icon icon="header-drop-down"
                            size={20}
                            className={`e-explore-filter ${showOrderStatus === true && "e-explore-filter-left-arrow"}`} />
                    </div>
                    {
                        showOrderStatus === true &&
                        <Fragment>
                            <div className="e-amc-scroll mt-3">
                                {
                                    orderStatus.length === 0 ?
                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                        :
                                        <CheckBoxGroup data={orderStatus}
                                            selectedList={selectedOrderStatus}
                                            type={1}
                                            className="flex-column"
                                            checkBoxItemClassName="my-2"
                                            checkBoxHandler={(selected_list) => handleOrderStatus(selected_list)} />
                                }
                            </div>
                        </Fragment>
                    }
                </div>

                {/* order type */}
                <div className="py-3 mx-3 border-bottom-1px border-platinum">
                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showOrderType === true && "mb-3"}`}
                        onClick={_showOrderTypes}>
                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Order type</span>
                        <Icon icon="header-drop-down"
                            size={20}
                            className={`e-explore-filter ${showOrderType === true && "e-explore-filter-left-arrow"}`} />
                    </div>
                    {
                        showOrderType === true &&
                        <Fragment>
                            <div className="e-amc-scroll mt-3">
                                {
                                    orderTypes.length === 0 ?
                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                        :
                                        <CheckBoxGroup data={orderTypes}
                                            selectedList={selectedOrderTypes}
                                            type={1}
                                            className="flex-column"
                                            checkBoxItemClassName="my-2"
                                            checkBoxHandler={(value) => handleOrderTypes(value)} />
                                }
                            </div>
                        </Fragment>
                    }
                </div>

                {/* date range */}
                <div className="py-3 mx-3 border-bottom-1px border-platinum">
                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showDateRange === true && "mb-3"}`}
                        onClick={_showDateRange}>
                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Filter by date range</span>
                        <Icon icon="header-drop-down"
                            size={20}
                            className={`e-explore-filter ${showDateRange === true && "e-explore-filter-left-arrow"}`} />
                    </div>
                    {
                        showDateRange === true &&
                        <Fragment>
                            <div className="row row-gap-8px">
                                <div className="col-xxl-6 col-8 pe-xxl-0">
                                    <CustomDatePicker start={startDate}
                                        mode="single"
                                        disabledDays={disabledDays}
                                        toYear={new Date().getFullYear()}
                                        className="e-date"
                                        selectDate={handleStart} />
                                </div>
                                <div className="col-xxl-6 col-8 ps-xxl-1">
                                    <CustomDatePicker start={endDate}
                                        mode="single"
                                        disabledDays={{
                                            ...disabledDays,
                                            before: startDate !== "DD-MM-YYYY" ? _parseDate(startDate) : undefined,
                                        }}
                                        toYear={new Date().getFullYear()}
                                        className="e-date"
                                        selectDate={handleEnd} />
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>

                <div className="row py-3 px-3">
                    <div className="col-6">
                        <TertiaryButton className="padding-10px-tb border-radius-12px w-100"
                            label="Cancel"
                            showLoader={false}
                            disabled={(
                                (selectedPaymentTypes.length === 0 && selectedOrderStatus.length === 0 && selectedOrderTypes.length === 0 && (startDate === "DD-MM-YYYY" || endDate === "DD-MM-YYYY")) ||
                                ((startDate !== "DD-MM-YYYY" && endDate === "DD-MM-YYYY") || (startDate === "DD-MM-YYYY" && endDate !== "DD-MM-YYYY"))
                            )}
                            onPress={() => {
                                getOrderList([], [], [], null, null, 1, 1);
                            }} />
                    </div>
                    <div className="col-6">
                        <PrimaryButton className="padding-10px-tb border-radius-12px w-100"
                            label="Apply"
                            showLoader={false}
                            disabled={(
                                (selectedPaymentTypes.length === 0 && selectedOrderStatus.length === 0 && selectedOrderTypes.length === 0 && (startDate === "DD-MM-YYYY" || endDate === "DD-MM-YYYY")) ||
                                ((startDate !== "DD-MM-YYYY" && endDate === "DD-MM-YYYY") || (startDate === "DD-MM-YYYY" && endDate !== "DD-MM-YYYY"))
                            )}
                            onPress={() => {
                                getOrderList(selectedPaymentTypes, selectedOrderStatus, selectedOrderTypes, startDate, endDate, investment, 1);
                            }} />

                    </div>
                </div>
            </div>

            {/* mobile filter */}
            {
                modal === true &&
                <Fragment>
                    <div className="position-fixed bg-black opacity-5 w-100 h-100 z-index-4 top-0 start-0">
                    </div>
                    <div className="position-fixed z-index-5 e-explore-filter-modal d-lg-none d-block">
                        <div className="e-filter border-radius-16px bg-white">
                            <div className="d-flex justify-content-between align-items-center px-3 padding-12px-tb border-bottom-1px border-platinum">
                                <span className="e-montserrat-medium e-font-16 e-line-height-24 color-eerie-black">Order Filter</span>

                                <span className={`e-montserrat-semi-bold e-font-14 e-line-height-24 color-rebecca-purple py-1 px-3 e-bg-magnolia border-radius-32px cursor-pointer 
                                      ${((selectedPaymentTypes.length === 0 && selectedOrderStatus.length === 0 && selectedOrderTypes.length === 0 && (startDate === "DD-MM-YYYY" || endDate === "DD-MM-YYYY")) ||
                                        ((startDate !== "DD-MM-YYYY" && endDate === "DD-MM-YYYY") || (startDate === "DD-MM-YYYY" && endDate !== "DD-MM-YYYY"))) && "disabled"}`}
                                    onClick={() => {
                                        getOrderList([], [], [], null, null, 1, 1);
                                    }}>
                                    Clear all
                                </span>

                            </div>

                            <div className="max-h-400px e-order-filter">
                                {/* payment type */}
                                <div className="padding-12px-tb mx-3 border-bottom-1px border-platinum">
                                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showPaymentType === true && "mb-md-3 mb-1"}`}
                                        onClick={_showPaymentTypes}>
                                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Payment status</span>
                                        <Icon icon="header-drop-down"
                                            size={20}
                                            className={`e-explore-filter ${showPaymentType === true && "e-explore-filter-left-arrow"}`} />
                                    </div>
                                    {
                                        showPaymentType === true &&
                                        <Fragment>
                                            <div className="e-amc-scroll mt-md-3 mt-0">
                                                {
                                                    paymentTypes.length === 0 ?
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                                        :
                                                        <CheckBoxGroup data={paymentTypes}
                                                            selectedList={selectedPaymentTypes}
                                                            type={1}
                                                            className="flex-column"
                                                            // checkBoxItemClassName="my-2"
                                                            checkBoxHandler={(value) => handlePaymentTypes(value)} />
                                                }
                                            </div>
                                        </Fragment>
                                    }
                                </div>

                                {/* order status */}
                                <div className="padding-12px-tb mx-3 border-bottom-1px border-platinum">
                                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showOrderStatus === true && "mb-md-3 mb-1"}`}
                                        onClick={_showOrderStatus}>
                                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Order status</span>
                                        <Icon icon="header-drop-down"
                                            size={20}
                                            className={`e-explore-filter ${showOrderStatus === true && "e-explore-filter-left-arrow"}`} />
                                    </div>
                                    {
                                        showOrderStatus === true &&
                                        <Fragment>
                                            <div className="e-amc-scroll mt-md-3 mt-0">
                                                {
                                                    orderStatus.length === 0 ?
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                                        :
                                                        <CheckBoxGroup data={orderStatus}
                                                            selectedList={selectedOrderStatus}
                                                            type={1}
                                                            className="flex-column"
                                                            // checkBoxItemClassName="my-2"
                                                            checkBoxHandler={(selected_list) => handleOrderStatus(selected_list)} />
                                                }
                                            </div>
                                        </Fragment>
                                    }
                                </div>

                                {/* order type */}
                                <div className="padding-12px-tb mx-3 border-bottom-1px border-platinum">
                                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showOrderType === true && "mb-md-3 mb-1"}`}
                                        onClick={_showOrderTypes}>
                                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Order type</span>
                                        <Icon icon="header-drop-down"
                                            size={20}
                                            className={`e-explore-filter ${showOrderType === true && "e-explore-filter-left-arrow"}`} />
                                    </div>
                                    {
                                        showOrderType === true &&
                                        <Fragment>
                                            <div className="e-amc-scroll mt-md-3 mt-0">
                                                {
                                                    orderTypes.length === 0 ?
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-24 mb-2 ">No data found</p>
                                                        :
                                                        <CheckBoxGroup data={orderTypes}
                                                            selectedList={selectedOrderTypes}
                                                            type={1}
                                                            className="flex-column"
                                                            // checkBoxItemClassName="my-2"
                                                            checkBoxHandler={(value) => handleOrderTypes(value)} />
                                                }
                                            </div>
                                        </Fragment>
                                    }
                                </div>

                                {/* date range */}
                                <div className="padding-12px-tb mx-3 border-bottom-1px border-platinum">
                                    <div className={`d-flex justify-content-between align-items-center  cursor-pointer ${showDateRange === true && "mb-md-3 mb-1"}`}
                                        onClick={_showDateRange}>
                                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-24">Filter by date range</span>
                                        <Icon icon="header-drop-down"
                                            size={20}
                                            className={`e-explore-filter ${showDateRange === true && "e-explore-filter-left-arrow"}`} />
                                    </div>
                                    {
                                        showDateRange === true &&
                                        <Fragment>
                                            <div className="row">
                                                <div className="col-6 pe-0">
                                                    <CustomDatePicker start={startDate}
                                                        mode="single"
                                                        disabledDays={disabledDays}
                                                        toYear={new Date().getFullYear()}
                                                        className="e-date"
                                                        popupClass="e-date-popup"
                                                        selectDate={handleStart} />
                                                </div>
                                                <div className="col-6 ps-1">
                                                    <CustomDatePicker start={endDate}
                                                        mode="single"
                                                        disabledDays={{
                                                            ...disabledDays,
                                                            before: startDate !== "DD-MM-YYYY" ? _parseDate(startDate) : undefined,
                                                        }}
                                                        toYear={new Date().getFullYear()}
                                                        className="e-date"
                                                        popupClass="e-date-popup"
                                                        selectDate={handleEnd} />
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>

                            <div className="row py-3 px-3">
                                <div className="col-6">
                                    <TertiaryButton className="padding-10px-tb border-radius-12px w-100"
                                        label="Cancel"
                                        showLoader={false}
                                        // disabled={(selectedPaymentTypes.length === 0 && selectedOrderStatus.length === 0 && selectedOrderTypes.length === 0 && (startDate === "DD-MM-YYYY" || endDate === "DD-MM-YYYY")) ? true : false}
                                        onPress={() => {
                                            getOrderList([], [], [], null, null, 1, 1);
                                        }} />
                                </div>
                                <div className="col-6">
                                    <PrimaryButton className="padding-10px-tb border-radius-12px w-100"
                                        label="Apply"
                                        showLoader={false}
                                        disabled={(
                                            (selectedPaymentTypes.length === 0 && selectedOrderStatus.length === 0 && selectedOrderTypes.length === 0 && (startDate === "DD-MM-YYYY" || endDate === "DD-MM-YYYY")) ||
                                            ((startDate !== "DD-MM-YYYY" && endDate === "DD-MM-YYYY") || (startDate === "DD-MM-YYYY" && endDate !== "DD-MM-YYYY"))
                                        )}
                                        onPress={() => {
                                            getOrderList(selectedPaymentTypes, selectedOrderStatus, selectedOrderTypes, startDate, endDate, investment, 1);
                                        }} />

                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
})

export default OrderFilter
