
/*
 *   File : kra-submit.js
 *   Author URI : https://evoqins.com
 *   Description : KRA Submit modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";
import { PrimaryButton } from "../Buttons";


// type  1 ---- success
// else failed

const InvestmentSubmission = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("investment-submission");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="investment-submission"
                tabIndex="-1"
                aria-labelledby="investment-submission"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-36px-bottom  text-center px-4 ${style.e_content} `}>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer position-absolute right-16px top-16px' />

                        <Fragment>
                            <img src={require("../../Assets/Images/KYC/investment.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2">
                                Congratulations
                            </h5>

                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 ">
                                The account creation process has been
                                initiated and the process will take 1-3 business days time. We will notify overmail once it is completed.
                            </p>
                        </Fragment>
                        <div className="row margin-32px-top d-flex justify-content-center">
                            <div className="col-6" >
                                <PrimaryButton label="Dashboard"
                                    className="padding-12px-tb  padding-54px-lr"
                                    cancel="modal"
                                    onPress={props.submitInvestment} />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

InvestmentSubmission.defaultProps = {
    submitInvestment: () => { }
}
export default memo(InvestmentSubmission)