/*
 *   File : goal.js
 *   Author : https://evoqins.com
 *   Description : Goal card component
 *   Integrations : null
 *   Version : 1
*/

// import packages
import { Fragment, memo } from "react";
import { useNavigate } from "react-router-dom/dist";

// import styles 
import Style from '../../Styles/Components/goal.module.scss';
import { _getPathFromShortName } from "../../Helper/helper";


const GoalCard = ({ data, other }) => {
    const navigate = useNavigate();

    function _handleNavigate() {
        navigate("/milestone", {
            state: {
                other: other
            }
        })
        setTimeout(() => {
            navigate(`/milestone/${_getPathFromShortName(data.short_name)}`, {
                state: {
                    goal_id: data.goal_type_id,
                    step: 1,
                }
            });
        }, 0)

    }

    return (
        <Fragment>
            <div className={`${Style.e_goal_card} padding-12px-all border-radius-16px h-100`} onClick={_handleNavigate}>
                <img src={data.image} className="border-radius-16px img-fluid "
                    alt="Goal"
                />
                <h6 className="color-eerie-black mb-1 e-font-14 e-line-height-20 e-montserrat-semi-bold margin-12px-top truncate-first-line">{data.name}</h6>
                <p className="color-jet-gray e-font-12 e-line-height-16 e-montserrat-medium mb-0 truncate-second-line">{data.description}</p>
            </div>
        </Fragment>
    )
}

export default memo(GoalCard)