/*
*   File : address-detail.js
*   Author : https://evoqins.com
*   Description : Screen to fill the address details of User
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// import components
import { Icon } from "../../../Components/Icon";
import { PrimaryButton } from "../../../Components/Buttons";
import { StepProgress } from "../../../Components/Other";
import { KYCProgressCard } from "../../../Components/Card";
import { CustomDropdown } from "../../../Components/Dropdown";
import { CustomTextArea, CustomTextInput } from "../../../Components/FormElements";
import { KYCErrorCard } from "../../../Components/Cards";

// import services
import APIService from "../../../Services/api-service";
import { _getSignupProgress } from "../../../Helper/api";
import { _getOptions } from "../../../Helper/helper";


const AddressDetails = (props) => {

    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);

    const permanentAddressRef = useRef(null);
    const correspondingAddressRef = useRef(null);

    const [country, setCountry] = useState(null);
    // const [countryError, setCountryError] = useState(null);
    const [taxPayingCountryOptions, setTaxPayingCountryOptions] = useState([]);

    const [addressLineOne, setAddressLineOne] = useState("")
    const [addressLineOneError, setAddressLineOneError] = useState("");
    const [addressLineTwo, setAddressLineTwo] = useState("")
    const [addressLineTwoError, setAddressLineTwoError] = useState("");

    const [state, setState] = useState(null);
    const [stateError, setStateError] = useState("");

    const [city, setCity] = useState(null);
    const [cityError, setCityError] = useState("");

    const [pinCode, setPinCode] = useState("");
    const [pinCodeError, setPinCodeError] = useState("");

    const [correspondingCountry, setCorrespondenceCountry] = useState(null);
    const [correspondingCountryError, setCorrespondenceCountryError] = useState(null);

    const [correspondingAddressLineOne, setCorrespondingAddressLineOne] = useState("")
    const [correspondingAddressLineOneError, setCorrespondingAddressLineOneError] = useState("");

    const [correspondingAddressLineTwo, setCorrespondingAddressLineTwo] = useState("")
    const [correspondingAddressLineTwoError, setCorrespondingAddressLineTwoError] = useState("");

    const [correspondingState, setCorrespondingState] = useState(null);
    const [correspondingStateError, setCorrespondingStateError] = useState("");

    const [correspondingCity, setCorrespondingCity] = useState(null);
    const [correspondingCityError, setCorrespondingCityError] = useState("");

    const [correspondingPinCode, setCorrespondingPinCode] = useState("");
    const [correspondingPinCodeError, setCorrespondingPinCodeError] = useState("");

    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const [kycUser, setKycUser] = useState(false);
    const [indianTaxPayer, setTaxpayerStatus] = useState(false);

    const [apiLoader, setApiLoader] = useState(false);

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (kycUser === true) {
            _getCountries();
            _getState();
        }
    }, [kycUser]);

    useEffect(() => {
        if (kycUser === true) {
            if (state !== null) {
                _getCity(state.value, true);
            };
        }
    }, [state, kycUser]);

    useEffect(() => {
        const signup_progress = PROGRESS_DATA;
        setKycUser(signup_progress.kyc_data.is_kyc_compliant);
        setTaxpayerStatus(signup_progress.kyc_data.is_indian_tax_payer); // tax paying in other country

        if (signup_progress.step_detail[4].error === true) {
            setErrors(signup_progress.step_detail[4].message);
        }
        setCountry({ label: "India", value: 251 });
        if (signup_progress.kyc_data.is_kyc_compliant === true && signup_progress.current_step > 3) {
            setAddressLineOne(signup_progress.kyc_data.address.permanent_address.address_line1);
            setAddressLineTwo(signup_progress.kyc_data.address.permanent_address.address_line2);
            setState({ label: signup_progress.kyc_data.address.permanent_address.state, value: signup_progress.kyc_data.address.permanent_address.state_id });
            setCity({ label: signup_progress.kyc_data.address.permanent_address.city, value: signup_progress.kyc_data.address.permanent_address.city_id });
            setPinCode(signup_progress.kyc_data.address.permanent_address.pincode);

            //If user is indian Tax payer
            if (signup_progress.kyc_data.is_indian_tax_payer === false) {
                setCorrespondingAddressLineOne(signup_progress.kyc_data.address.corresponding_address.address_line1);
                setCorrespondenceCountry({ label: signup_progress.kyc_data.address.corresponding_address.country, value: signup_progress.kyc_data.address.corresponding_address.country_id });
                setCorrespondingAddressLineTwo(signup_progress.kyc_data.address.corresponding_address.address_line2);
                setCorrespondingState(signup_progress.kyc_data.address.corresponding_address.state);
                setCorrespondingCity(signup_progress.kyc_data.address.corresponding_address.city);
                setCorrespondingPinCode(signup_progress.kyc_data.address.corresponding_address.pincode);
            }
            return; // address for already KYC done user
        }

        if (signup_progress.current_step > 3) {
            setAddressLineOne(signup_progress.kyc_data.address.permanent_address.address_line1);
            setAddressLineTwo(signup_progress.kyc_data.address.permanent_address.address_line2);
            setState({ label: signup_progress.kyc_data.address.permanent_address.state, value: signup_progress.kyc_data.address.permanent_address.state_id });
            setCity({ label: signup_progress.kyc_data.address.permanent_address.city, value: signup_progress.kyc_data.address.permanent_address.city_id });
            setPinCode(signup_progress.kyc_data.address.permanent_address.pincode);
        }

    }, [PROGRESS_DATA]);

    // handle address line one
    function _handleAddressOne(input_value) {
        setAddressLineOne(input_value);
        setAddressLineOneError("");
    }


    // handle address line one
    function _handleAddressTwo(input_value) {
        setAddressLineTwo(input_value);
        setAddressLineTwoError("");
    }

    // handle address line one
    function _handleState(input_value) {
        setState(input_value);
        setStateError("");
    }


    // handle address line one
    function _handleCity(input_value) {
        setCity(input_value);
        setCityError("");
    }


    // handle address line one
    function _handlePinCode(input_value) {
        if (input_value.length <= 6) {
            setPinCode(input_value);
        }
        setPinCodeError("");
    }



    // handle tax paying country
    function _handleCorrespondingCountry(input_value) {
        setCorrespondenceCountry(input_value);
        setCorrespondenceCountryError("");
    }

    // handle address line one
    function _handleCorrespondingAddressOne(input_value) {
        setCorrespondingAddressLineOne(input_value);
        setCorrespondingAddressLineOneError("");
    }


    // handle address line one
    function _handleCorrespondingAddressTwo(input_value) {
        setCorrespondingAddressLineTwo(input_value);
        setCorrespondingAddressLineTwoError("");
    }

    // handle address line one
    function _handleCorrespondingState(input_value) {
        setCorrespondingState(input_value);
        setCorrespondingStateError("");
    }


    // handle address line one
    function _handleCorrespondingCity(input_value) {
        setCorrespondingCity(input_value);
        setCorrespondingCityError("");
    }


    // handle address line one
    function _handleCorrespondingPinCode(input_value) {
        setCorrespondingPinCode(input_value);
        setCorrespondingPinCodeError("");
    }



    // handle form validation
    function _submitAddressDetails() {
        let valid = true;
        if (indianTaxPayer === false && kycUser === true) {
            if (correspondingCountry === null) {
                correspondingAddressRef.current.scrollIntoView({ block: "start" });
                setCorrespondenceCountryError("Select country");
                valid = false;
            }

            if (correspondingAddressLineOne === "") {
                correspondingAddressRef.current.scrollIntoView({ block: "start" });
                setCorrespondingAddressLineOneError("Address cannot be empty");
                valid = false;
            }


            if (correspondingState === null) {
                correspondingAddressRef.current.scrollIntoView({ block: "start" });
                setCorrespondingStateError("Select state");
                valid = false;
            }


            if (correspondingCity === null) {
                correspondingAddressRef.current.scrollIntoView({ block: "start" });
                setCorrespondingCityError("Enter city name");
                valid = false;
            }


            if (correspondingPinCode === "") {
                correspondingAddressRef.current.scrollIntoView({ block: "start" });
                setCorrespondingPinCodeError("Pin code cannot be empty");
                valid = false;
            }
        }


        if (addressLineOne === "") {
            permanentAddressRef.current.scrollIntoView({ block: "start" });
            setAddressLineOneError("Address line cannot be empty");
            valid = false;
        }


        if (state === null) {
            permanentAddressRef.current.scrollIntoView({ block: "start" });
            setStateError("Select state");
            valid = false;
        }

        if (city === null) {
            permanentAddressRef.current.scrollIntoView({ block: "start" });
            setCityError("Enter city name");
            valid = false;
        }


        if (pinCode === "") {
            permanentAddressRef.current.scrollIntoView({ block: "start" });
            setPinCodeError("Pin code cannot be empty");
            valid = false;
        }

        if (pinCode !== "" && pinCode.length !== 6) {
            permanentAddressRef.current.scrollIntoView({ block: "start" });
            setPinCodeError("Invalid pin code");
            valid = false;
        }

        if (valid === true) {
            _saveAddress();
        }
    }

    // API - get countries
    const _getCountries = () => {
        const url = "/general/countries";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const tax_paying_countries = [];


                response.data.countries_for_address.map((item) => {
                    return (
                        tax_paying_countries.push({
                            label: item.name,
                            value: item.id
                        })
                    );
                });
                setTaxPayingCountryOptions(tax_paying_countries);
            }
        });
    }

    // API - save address
    const _saveAddress = () => {
        setApiLoader(true);
        const url = "/kyc/save-address";

        let corresponding_address = {};
        if (indianTaxPayer === false && kycUser === true) {
            corresponding_address = {
                "address_line1": correspondingAddressLineOne,
                "address_line2": correspondingAddressLineTwo,
                "pincode": correspondingPinCode,
                "country_id": correspondingCountry.value,
                "city": correspondingCity,
                "city_id": null,
                "state_name": correspondingState,
                "state_id": null
            };
        }
        const request = {
            "address": {
                "permanent_address": {
                    "address_line1": addressLineOne,
                    "address_line2": addressLineTwo,
                    "pincode": pinCode,
                    "country_id": country.value,
                    "city": city.label,
                    "city_id": city.value,
                    "state_name": state && state.label,
                    "state_id": state && state.value,
                },
                "corresponding_address": corresponding_address
            },
            "is_same_corresponding_address": kycUser === true ? indianTaxPayer : true
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                _getSignupProgress().then((response) => {
                    props.handleNavigate();
                })
                toast.success("Address details added successfully", {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    //API- Get state
    const _getState = () => {
        let is_post = false;
        let url = '/general/states';
        let data = null;

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                const state_options = _getOptions(response.data);
                setStateData(state_options);
            } else {
                setStateData([]);
            }
        });
    }

    //API- Post City
    const _getCity = (state_id, is_permanent) => {
        let is_post = true;
        let url = '/general/cities';
        let data = {
            "state_id": state_id,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                const city_options = _getOptions(response.data);
                setCityData(city_options);

            } else {
                setCityData([]);
            }
        });
    }

    return (
        <Fragment>
            <Icon icon="Arrow-Right"
                size={32}
                className="margin-12px-tb rotate-left cursor-pointer"
                onClick={props.navigateBack} />
            <div className="row pt-lg-3 pt-0">
                <div className="col-lg-7">

                    {/* step  */}
                    <StepProgress type={2}
                        step={props.completed}
                        maxStep={kycUser ? 6 : 8}
                        stepNumber={kycUser ? 4 : 5} />


                    <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-0 mt-4">{kycUser === true ? 'Permanent address' : 'Permanent address details (as per Aadhaar)'}</h6>

                    {
                        errors.length > 0 &&
                        <KYCErrorCard errors={errors} />
                    }
                    {
                        kycUser === false ?
                            <div className='col mb-2 mt-3' ref={permanentAddressRef}>

                                <div className="e-font-14 color-dark-charcoal e-montserrat-medium e-line-height-22 border-1px border-silver-sand border-radius-8px p-3 ">
                                    {addressLineOne}{", "}
                                    {city !== null && city.label}{", "}
                                    {state !== null && state.label}{", "}
                                    {pinCode}.
                                </div>
                            </div>
                            :
                            <Fragment>
                                <div className="row padding-20px-top" ref={permanentAddressRef}>

                                    <div className="col-lg-10 pb-4">
                                        <CustomTextArea label="Address line 1"
                                            postfix="*"
                                            value={addressLineOne}
                                            error={addressLineOneError}
                                            placeholder="Address line 1"
                                            handleChange={_handleAddressOne} />
                                    </div>
                                    <div className="col-lg-10 pb-4">
                                        <CustomTextArea label="Address line 2"
                                            value={addressLineTwo}
                                            error={addressLineTwoError}
                                            placeholder="Address line 2"
                                            handleChange={_handleAddressTwo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5 col-md-6 col-sm-6 ">
                                        <CustomDropdown label="State"
                                            postfix="*"
                                            value={state}
                                            error={stateError}
                                            options={stateData}
                                            placeholder="State"
                                            isSearchable={true}
                                            message="No state found"
                                            onSelectChange={_handleState} />
                                    </div>
                                    <div className="col-lg-5 col-md-6 col-sm-6 pt-sm-0 pt-4">
                                        <CustomDropdown label="City"
                                            postfix="*"
                                            value={city}
                                            error={cityError}
                                            options={cityData}
                                            placeholder="City"
                                            isSearchable={true}
                                            message="No city found"
                                            isDisabled={state !== null ? false : true}
                                            onSelectChange={_handleCity} />
                                    </div>
                                    <div className="col-lg-5 col-md-6 col-sm-6 pt-4">
                                        <CustomTextInput label="Pin code"
                                            postfix="*"
                                            value={pinCode}
                                            type="number"
                                            error={pinCodeError}
                                            inputClass="e-kyc-input"
                                            placeholder="Pin code"
                                            handleChange={_handlePinCode} />
                                    </div>

                                </div>


                                {/* is_indian_tax_payer is false then below address is manadatory ==== pincode to zip code */}
                                {
                                    indianTaxPayer === false &&
                                    <Fragment>
                                        <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mt-4 mb-0">Overseas Address</h6>
                                        <div className="row pt-4" ref={correspondingAddressRef}>
                                            <div className="col-lg-5 pb-4">
                                                <CustomDropdown options={taxPayingCountryOptions}
                                                    placeholder="Select country"
                                                    error={correspondingCountryError}
                                                    value={correspondingCountry}
                                                    label="Select country"
                                                    isSearchable={true}
                                                    message="No country found"
                                                    postfix="*"
                                                    onSelectChange={_handleCorrespondingCountry} />
                                            </div>
                                            <div className="col-lg-10 pb-4">
                                                <CustomTextArea label="Address line 1"
                                                    postfix="*"
                                                    value={correspondingAddressLineOne}
                                                    error={correspondingAddressLineOneError}
                                                    placeholder="Address line 1"
                                                    handleChange={_handleCorrespondingAddressOne} />
                                            </div>
                                            <div className="col-lg-10 pb-4">
                                                <CustomTextArea label="Address line 2"
                                                    value={correspondingAddressLineTwo}
                                                    error={correspondingAddressLineTwoError}
                                                    placeholder="Address line 2"
                                                    handleChange={_handleCorrespondingAddressTwo} />
                                            </div>
                                        </div>
                                        <div className="row pb-2">
                                            {/* change both to input */}
                                            <div className="col-lg-5 col-md-6 col-sm-6 ">
                                                <CustomTextInput label="State"
                                                    postfix="*"
                                                    value={correspondingState}
                                                    error={correspondingStateError}
                                                    placeholder="State"
                                                    isSearchable={true}
                                                    message="No state found"
                                                    handleChange={_handleCorrespondingState} />
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 pt-sm-0 pt-4">
                                                {/* change both to input */}
                                                <CustomTextInput label="City"
                                                    postfix="*"
                                                    value={correspondingCity}
                                                    error={correspondingCityError}
                                                    placeholder="City"
                                                    handleChange={_handleCorrespondingCity} />
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 pt-4">
                                                {/* change both to zip code */}
                                                <CustomTextInput label="Zip code"
                                                    postfix="*"
                                                    value={correspondingPinCode}
                                                    error={correspondingPinCodeError}
                                                    inputClass="e-kyc-input"
                                                    placeholder="Zip code"
                                                    handleChange={_handleCorrespondingPinCode} />
                                            </div>

                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                    }

                    <div className="border-top-1px border-bright-gray my-4" />
                    <div className="row">
                        <div className="col-xl-3 col-lg-5 d-lg-block d-none">
                            <PrimaryButton label="Save & Continue"
                                className="padding-12px-tb w-100"
                                disabled={apiLoader}
                                showLoader={apiLoader}
                                onPress={_submitAddressDetails} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-5">
                    <KYCProgressCard steps={props.investment_steps}
                        completedStep={props.completedStep} />

                    <div className="col-md-6 col-sm-6  d-lg-none d-flex justify-content-center pt-3">
                        <PrimaryButton label="Save & Continue"
                            className="padding-12px-tb padding-54px-lr"
                            disabled={apiLoader}
                            showLoader={apiLoader}
                            onPress={_submitAddressDetails} />
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default AddressDetails