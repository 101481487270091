/*
*   File : regular-income.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Investment for regular income
*   Version : 1.0.0
*/

// import packages
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { CustomAmountInput, CustomSelectBox, RadioGroup } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { GoalInformation, GoalInvest } from "./";

import { _getGoal } from "../../Helper/api";
import { toast } from "react-toastify";
import APIService from "../../Services/api-service";
import { Icon } from "../../Components/Icon";

const RegularIncome = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const monthlyIncomeRef = useRef(null);

    const [goalDetail, setGoalDetail] = useState({});

    const [monthlyIncome, setMonthlyIncome] = useState("");
    const [monthlyIncomeError, setMonthlyIncomeError] = useState("");

    const [configurationStep, setConfigurationStep] = useState(1);
    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);

    const [dependentsOptions, setDependentsOptions] = useState([]);
    const [selectedDependents, setSelectedDependents] = useState(null);
    const [selectedDependentsError, setSelectedDependentsError] = useState("");

    const [incomeFrequencyOptions, setIncomeFrequencyOptions] = useState([]);
    const [selectedIncomeFrequency, setSelectedIncomeFrequency] = useState(null);

    const [investmenetsInOptions, setInvestmenetsInOptions] = useState([]);
    const [selectedInvestmentIn, setSelectedInvestmentIn] = useState(null);

    const [incomeStartOptions, setIncomeStartOptions] = useState([]);
    const [selectedIncomeStart, setSelectedIncomeStart] = useState(null);

    useEffect(() => {
        if (location.state !== null) {
            _goalDetail(location.state.goal_id);
            setConfigurationStep(location.state.step);
        }
    }, [location]);

    // API - get goal detail
    const _goalDetail = (id) => {
        _getGoal(id)
            .then((response) => {
                // For other options with 'id' and 'name'
                const transformToIdName = (data) => {
                    return data.map(item => ({
                        id: parseInt(item.id),
                        name: item.label
                    }));
                };

                const { meta_data } = response.admin_configured;
                const { regular_income_frequency, investments_be_in, income_start_date } = meta_data;

                setIncomeFrequencyOptions(transformToIdName(regular_income_frequency));
                setInvestmenetsInOptions(transformToIdName(investments_be_in));
                setIncomeStartOptions(transformToIdName(income_start_date));

                const transformed_dependents = response.admin_configured.relationships.map((item) => ({
                    value: item.id,
                    label: item.label
                }))
                if (response.is_configured === false) {
                    setSelectedDependents(transformed_dependents[0]);
                    setSelectedIncomeFrequency(transformToIdName(regular_income_frequency)[0].id);
                    setSelectedInvestmentIn(transformToIdName(investments_be_in)[0].id);
                    setSelectedIncomeStart(transformToIdName(income_start_date)[0].id);
                } else {
                    setSelectedDependents(transformed_dependents.find(item => item.value === response.user_configured.additional_data.relation_id));
                    if (response.user_configured.additional_data.target_value) {
                        setMonthlyIncome(response.user_configured.additional_data.target_value);
                    }
                    setSelectedIncomeFrequency(response.user_configured.additional_data.receiving_type_id);
                    setSelectedInvestmentIn(response.user_configured.additional_data.investment_type_id)
                    setSelectedIncomeStart(response.user_configured.tenure);
                }
                setGoalDetail(response);

                setDependentsOptions(transformed_dependents);
                setScreenLoader(false);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }

    const _handleSelectBox = (value) => {
        setSelectedDependents(value);
        setSelectedDependentsError("")
    }

    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/milestone");
    }

    // handle monthlyIncome
    function _handleMonthlyIncome(value) {
        setMonthlyIncome(value);
        setMonthlyIncomeError("");
    }

    function _handleFrequency(value) {
        setSelectedIncomeFrequency(value);
    }

    function _handleInvestmentsIn(value) {
        setSelectedInvestmentIn(value);
    }

    function _handleIncomeStartDate(value) {
        setSelectedIncomeStart(value);
    }

    // handle submit
    function _submitInvestment() {
        let valid = true;
        let firstErrorRef = null;
        if (selectedDependents === null) {
            setSelectedDependentsError("Select dependents");
            valid = false;
        }
        if (monthlyIncome === "" || monthlyIncome === 0) {
            setMonthlyIncomeError("Enter the required amount");
            if (!firstErrorRef) {
                firstErrorRef = monthlyIncomeRef;
            }
            valid = false;
        }

        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            firstErrorRef.current.querySelector('input').focus();
        }

        if (valid === true) {
            setApiLoader(true);
            _setRetirement();
        }
    }

    const _setRetirement = () => {
        const url = "/goal/set";
        const request = {
            goal_id: location.state.goal_id,
            tenure: selectedIncomeStart,
            target_value: monthlyIncome,
            additional_data: {
                relation_id: selectedDependents.value,
                receiving_type_id: selectedIncomeFrequency,
                investment_type_id: selectedInvestmentIn,
                target_value: monthlyIncome,
            }
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setConfigurationStep(2);
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 2,
                        request_data: response.data
                    }
                });

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }

            setApiLoader(false);
        })
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                goal_id: location.state.goal_id,
                step: step,
                request_data: location.state.request_data
            }
        })
    }

    return (

        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <div className="row ">
                    <div className="col-12 padding-144px-bottom">
                        <Header type={2} />
                        <div className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Milestones"
                                childPage={goalDetail.admin_configured.name + ' Configuration'}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate}
                                type={2} />


                            <div className="row pt-4 d-flex justify-content-center">
                                <div className="col-lg-6">
                                    {/* Step progress */}
                                    <StepProgress stepCompleted={configurationStep}
                                        gotToStep={_handleConfigurationStep} />

                                </div>

                            </div>
                            {
                                configurationStep === 1 ?
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="mt-4 e-goal-bg border-radius-16px d-flex align-items-center p-3">
                                                <img src={goalDetail.admin_configured.image}
                                                    alt="goals"
                                                    width={65}
                                                    height={54} />
                                                <div className="padding-12px-left">
                                                    <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-semi-bold">{goalDetail.admin_configured.name}</h6>
                                                    <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.admin_configured.description}</p>
                                                </div>
                                            </div>

                                            <CustomSelectBox placeholder=""
                                                label="Who are the dependents?"
                                                postFix={true}
                                                error={selectedDependentsError}
                                                className="w-100 mt-4"
                                                value={selectedDependents}
                                                options={dependentsOptions}
                                                onSelectChange={_handleSelectBox} />

                                            <div ref={monthlyIncomeRef}>
                                                <CustomAmountInput
                                                    value={monthlyIncome}
                                                    error={monthlyIncomeError}
                                                    postfix="*"
                                                    className="mt-4"
                                                    label="What amount you would want them to receive per month?"
                                                    placeholder="5,00,000"
                                                    handleChange={_handleMonthlyIncome} />
                                            </div>

                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-4`}>
                                                How should they receive it?
                                                <span className='color-deep-carmine-pink'>*</span>
                                            </p>
                                            <RadioGroup data={incomeFrequencyOptions}
                                                selected={selectedIncomeFrequency}
                                                handleSelect={_handleFrequency} />

                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-3`}>
                                                Whose name should the investments be in?
                                                <span className='color-deep-carmine-pink'>*</span>
                                            </p>
                                            <RadioGroup data={investmenetsInOptions}
                                                selected={selectedInvestmentIn}
                                                handleSelect={_handleInvestmentsIn} />

                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-3`}>
                                                When do you want this investment to start?
                                                <span className='color-deep-carmine-pink'>*</span>
                                            </p>
                                            <RadioGroup data={incomeStartOptions}
                                                selected={selectedIncomeStart}
                                                handleSelect={_handleIncomeStartDate} />
                                            {
                                                selectedInvestmentIn === 2 &&
                                                <div className="d-flex align-items-start padding-12px-all mt-2 e-bg-light-blue border-radius-12px mt-4 color-ocean-blue e-font-14 e-line-height-20 gap-8px e-montserrat-medium">
                                                    <Icon icon="info-circle"
                                                        size={20} />
                                                    If they're dependents, they'll need to register and complete KYC to invest from their account.
                                                </div>
                                            }
                                            <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>
                                            <div className="d-flex justify-content-center">
                                                <div className="col-lg-6 col-md-4 col-6">
                                                    <PrimaryButton label="Calculate" className="padding-10px-tb w-100"
                                                        showLoader={apiLoader}
                                                        disabled={selectedInvestmentIn !== 1 ? true : apiLoader}
                                                        onPress={_submitInvestment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    configurationStep === 2 ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-6">
                                                <GoalInformation short_description={"Recommended Corpus:"}
                                                    text1="To create a"
                                                    text2="a reality, you'll need a corpus of" />
                                            </div>
                                        </div>
                                        :
                                        <div className="row ">
                                            <div className="col-xl-10 col-lg-11">
                                                <GoalInvest />
                                            </div>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

    )
}

export default RegularIncome;