/*
 *   File : resource.js
 *   Author : https://evoqins.com
 *   Description : Card component used in the application in places where we listed "Resources"
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { Icon } from "../Icon";
import style from "../../Styles/Components/resource.module.scss"
import { Fragment } from "react";

const ResourceCard = (props) => {

    return (
        <Fragment>
            <div className={`cursor-pointer border-radius-32px overflow-hidden h-100 d-md-flex d-none flex-column ${style.e_card} bg-white`}>
                <div className="overflow-hidden border-radius-tlr-24px">
                    <img src={props.data.thumbnail ? props.data.thumbnail : props.data.banner}
                        alt={props.data.title}
                        draggable={false}
                        className="w-100 object-fit-cover h-240px" />
                </div>
                <div className="py-4 px-3 border-radius-blr-24px d-flex flex-column">
                    <p className="e-montserrat-medium e-font-14 e-line-height-20 margin-12px-bottom color-rebecca-purple"><span className={`${style.e_date} margin-6px-right pe-1`}>{props.data.uploaded_on}</span> {props.data.duration_text} read</p>
                    <p className="e-montserrat-semi-bold e-font-18 e-line-height-32 mb-4">{props.data.title}</p>
                    <div className="d-flex justify-content-between h-auto mt-auto">
                        <span className="e-montserrat-medium e-font-14 e-line-height-20 padding-2px-tb padding-10px-lr border-radius-16px color-palatinate-blue e-bg-lavender-blue"
                            style={{ backgroundColor: props.data.backgroundColor, color: props.data.color }}>{props.data.category}</span>
                        <Icon icon="Arrow-Right"
                            size={24} />
                    </div>
                </div>

            </div>
            <div className={`${style.e_card} d-md-none d-block border-radius-12px bg-white`}>
                <div className="row d-flex align-items-center">
                    <div className="col-4">
                        <div className="h-100 w-100">
                            <img src={props.data.thumbnail ? props.data.thumbnail : props.data.banner}
                                alt={props.data.title}
                                draggable={false}
                                className="w-100 h-100 border-radius-tl-12px border-radius-bl-12px object-fit-cover" />
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="pe-3 py-3">
                            <p className="e-montserrat-semi-bold e-font-12 e-line-height-20 margin-14px-bottom">{props.data.title}</p>
                            <div className="d-flex justify-content-between align-items-center ">
                                <p className="e-montserrat-medium e-font-12 e-line-height-16 mb-0 color-rebecca-purple"><span className={`${style.e_date} margin-6px-right pe-1`}>{props.data.uploaded_on}</span> {props.data.duration_text} read</p>
                                <Icon icon="Arrow-Right"
                                    size={20} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default ResourceCard;