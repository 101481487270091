/*
*   File : personal-risk-review.js
*   Author : https://evoqins.com
*   Description : Personal risk reviewAQ    
*   Version : 1.0.0
*/

import { Fragment, memo } from "react"
import Slider from "rc-slider";

import { PrimaryButton } from "../../../Components/Buttons";
import 'rc-slider/assets/index.css';

const RISK_LEVEL = [
    {
        value: 1,
        label: "Conservative",
        image: require("../../../Assets/Images/risk-assessment/conservative.png"),
        description: "I prioritize protecting my capital and prefer investments with low volatility and stable returns.",
    },
    {
        value: 2,
        label: "Moderate",
        image: require("../../../Assets/Images/risk-assessment/moderate.png"),
        description: "I am comfortable with some level of risk and seek a balance between capital preservation and potential for higher returns.",
    },
    {
        value: 3,
        label: "Aggressive",
        image: require("../../../Assets/Images/risk-assessment/aggressive.png"),
        description: "I am willing to take on higher risks in pursuit of potentially higher returns and can tolerate market fluctuations.",
    },
    {
        value: 4,
        label: "Very Aggressive",
        image: require("../../../Assets/Images/risk-assessment/very-aggressive.png"),
        description: "I have a high risk appetite and am seeking maximum growth potential, even if it means greater volatility and potential for losses.",
    },
]

const PersonalRiskReview = memo((props) => {


    const _handleChange = (value, question_id) => {
        const risk = RISK_LEVEL.findIndex((item) => item.value === value)
        props.handleChange(value, question_id, RISK_LEVEL[risk].label);
    }

    return (
        <Fragment>
            <div className="border-bottom-1px border-bright-gray padding-48px-bottom">
                <p className="e-montserrat-semi-bold e-font-16 e-line-height-28 color-dark-jungle-green mb-1 mt-3">{props.orderTwo.name}</p>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-blue-gray">{props.orderTwo.description}</p>
                {
                    props.orderTwo.questions.map((item, key) => (

                        <div className="e-risk-assessment-card p-4 border-radius-24px"
                            key={key}>

                            <div className="mb-4 d-flex align-items-center justify-content-between gap-8px ">
                                <p className="e-montserrat-medium e-font-14 e-line-height-24 mb-0">{item.question}</p>
                                <span className={`e-montserrat-semi-bold e-font-14 e-line-height-20 e-bg-lavender-mist padding-10px-all color-rebecca-purple w-max-content border-radius-10px`}>{item.answer_label || RISK_LEVEL[item.answer ? item.answer - 1 : item.config.default_answer - 1].label}</span>
                            </div>

                            {
                                //eslint-disable-next-line
                                RISK_LEVEL.map((risk, index) => {
                                    if (risk.value === (item.answer || item.config.default_answer)) {
                                        return (
                                            <div key={index} className="d-flex gap-20px align-items-center mb-4">
                                                <img src={risk.image}
                                                    alt="Risk score"
                                                    width={64}
                                                    height={64}
                                                    className="e-risk-image" />
                                                <div>
                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-charcoal-blue mb-0">{item.answer_label || RISK_LEVEL[item.answer ? item.answer - 1 : item.config.default_answer - 1].label}</p>
                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 color-blue-gray mb-0">{risk.description}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                })

                            }

                            <Slider className="e-break-point-slider mb-4"
                                dots min={1}
                                max={4}
                                // marks={item.config.investment_style}
                                step={1}
                                onChange={(value) => _handleChange(value, item.question_id)} defaultValue={item.answer ? item.answer : item.config.default_answer} />



                        </div>

                    )
                    )
                }
            </div>
            <div className="row justify-content-center">
                <div className="col-sm-4 col-6">
                    <PrimaryButton label="Evaluate Now"
                        className=" padding-12px-tb px-4 mt-4 w-100"
                        onPress={() => props.submit(3)} />
                </div>
            </div>
        </Fragment>
    )
})

export default PersonalRiskReview