/*
 *   File : tertiary.js
 *   Author URI : https://evoqins.com
 *   Description : Tertiary button for site
 *   Integrations : null
 *   Version : 1
 */

import { Fragment } from "react";
import style from "../../Styles/Components/primary.module.scss";
// import { Icon } from "../Icon";

const TertiaryButton = (props) => {

    return (
        // primary button
        <button
            className={`e-montserrat-semi-bold e-font-14 e-line-height-24 letter-spacing-02em e-bg-magnolia color-rebecca-purple border-radius-14px border-1px border-light-purple text-nowrap ${style.e_height} ${props.className}
        ${props.disabled === false ?
                    `${style.e_active_btn}  cursor-pointer`
                    :
                    `${style.e_inactive_btn} `
                }`}

            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-loading={`${props.loading}`}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            onClick={props.onPress}
        >
            <span>

                {
                    props.disabled &&
                        props.showLoader === true ?
                        <img src={require("../../Assets/Images/Loader/btn-loader.gif")}
                            alt={"loader"}
                            height={24}
                        />
                        :
                        <Fragment>
                            {props.image &&
                                (<img src={props.image}
                                    alt={props.label}
                                    width={props.width ? props.width : 24}
                                    height={props.height ? props.height : 24}
                                    className="me-2" />)}

                            {props.label ? props.label : "Submit"}

                        </Fragment>
                }
            </span>
        </button>
    );
};

TertiaryButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
    showLoader: true
}

export default TertiaryButton;
