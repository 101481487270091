/*
 *   File : detail.js
 *   Author : https://evoqins.com
 *   Description : Container file to show the Resource detail
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import components
import { Header } from "../../Components/Header"
import { Breadcrumb, Loader } from "../../Components/Other"
import { Icon } from "../../Components/Icon";
import { ResourceCard } from "../../Components/Card";

// import services
import APIService from "../../Services/api-service";

// import style
import Color from '../../Styles/color.module.scss';

const ResourceDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [screenLoader, setScreenLoader] = useState(true);

    const [resourceDetail, setResourceDetail] = useState({});

    useEffect(() => {
        if (location.state !== null) {
            _getResourceDetail(location.state.id);
        } else {
            navigate("/resource");
        }
        // eslint-disable-next-line
    }, [location.state]);

    // navigate to home
    function _handleNavigate() {
        navigate("/");
    }

    // navigate back
    function _handleCurrentNavigate() {

        navigate("/resource", {
            state: {
                tab_index: location.state.tab_index,
                page: location.state.page
            }
        }, { replace: true });
    }

    // API - Resource detail
    const _getResourceDetail = (id) => {
        const url = "/resource/detail";

        const request = {
            "resource_id": id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setResourceDetail(response.data);
            }
            setScreenLoader(false);
        });
    }

    // API -manage like-dislike
    const _manageLikeDislike = (type) => {
        const url = "/resource/manage-like";

        const request = {
            "type": type,
            "resource_id": resourceDetail.resource_id
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                const resource_detail = { ...resourceDetail }
                resource_detail.likes = response.data.likes;
                resource_detail.dislikes = response.data.dislikes;
                resource_detail.like_status = resource_detail.like_status === null ?
                    type === 1 ? "LIKE" : "DISLIKE" :
                    resource_detail.like_status === "LIKE" ? type === 1 ? null : "DISLIKE" :
                        resource_detail.like_status === "DISLIKE" ?
                            type === 2 ? null : "LIKE" : null
                setResourceDetail(resource_detail);
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
        })
    }

    return (
        <Fragment>
            {
                screenLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 padding-144px-bottom">

                                {/* Header */}

                                {/* Goal list */}
                                <section className="px-4 ">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Educate"
                                        childPage={resourceDetail.title}
                                        type={2}
                                        handleParentNavigate={_handleNavigate}
                                        handleCurrentNavigate={_handleCurrentNavigate} />
                                    <Icon icon="Arrow-Right"
                                        size={32}
                                        className="mb-3 margin-32px-top e-transform-left-arrow cursor-pointer"
                                        onClick={_handleCurrentNavigate} />

                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <div className="d-flex gap-8px align-items-center w-max-content border-radius-34px color-medium-persian-blue e-bg-lavender-blue py-1 ps-1 padding-12px-right">
                                                <span className="bg-white border-1px border-light-sky-blue padding-12px-lr py-2 border-radius-16px e-montserrat-medium e-font-14 e-line-height-18">
                                                    {resourceDetail.category}
                                                </span>
                                                <span className="e-montserrat-medium e-font-14 e-line-height-18 color-medium-persian-blue">
                                                    {resourceDetail.duration_text} read
                                                </span>
                                            </div>
                                            <h2 className="e-font-30 e-line-height-48 e-montserrat-semi-bold color-eerie-black mt-3 margin-32px-bottom p-0">{resourceDetail.title}</h2>
                                            <img src={resourceDetail.banner} alt={resourceDetail.title}
                                                className="w-100 border-radius-24px object-fit-cover"
                                                height={350} />
                                        </div>
                                        <div className="col-lg-8 col-md-10 col-12 padding-32px-tb border-bottom-1px border-bright-gray">
                                            <div className="e-content" dangerouslySetInnerHTML={{ __html: resourceDetail.content }} />
                                        </div>

                                        <div className="d-flex gap-24px margin-40px-top e-font-18 e-line-height-24 letter-spacing-007em  e-montserrat-semi-bold">
                                            <div className={`${resourceDetail.like_status === "LIKE" ? "border-1px border-purple e-bg-lavender-light color-rebecca-purple" : "e-add-btn color-eerie-black"}  py-2 px-3 border-radius-40px box-shadow-36-charleston d-flex gap-4px cursor-pointer`}
                                                onClick={() => _manageLikeDislike(1)}>
                                                <Icon icon="like"
                                                    size={24}
                                                    color={resourceDetail.like_status === "LIKE" ? Color.rebecca_purple : Color.eerie_black} />
                                                {resourceDetail.likes}
                                            </div>
                                            <div className={`${resourceDetail.like_status === "DISLIKE" ? "border-1px border-purple e-bg-lavender-light color-rebecca-purple" : "e-add-btn color-eerie-black"}  py-2 px-3 border-radius-40px box-shadow-36-charleston d-flex gap-4px cursor-pointer`}
                                                onClick={() => _manageLikeDislike(2)}>
                                                <Icon icon="dislike"
                                                    size={24}
                                                    color={resourceDetail.like_status === "DISLIKE" ? Color.rebecca_purple : Color.eerie_black} />
                                                {resourceDetail.dislikes}
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        resourceDetail.related.length > 0 && <div className="row padding-88px-top">
                                            <h6 className="e-font-18 e-line-height-28 e-montserrat-semi-bold color-eerie-black mb-4">
                                                Related Topics
                                            </h6>
                                            {
                                                resourceDetail.related.map((item, index) => {
                                                    return (
                                                        <div key={index} className="col-md-4 mb-3" onClick={() => {
                                                            window.scrollTo(0, 0)
                                                            _getResourceDetail(item.resource_id)
                                                        }}>
                                                            <ResourceCard data={item} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </section>
                            </div>
                        </div>
                    </div>
            }

        </Fragment>
    )
}

export default ResourceDetail;